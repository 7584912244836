import { Button, Stack, useMediaQuery } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";
import { DateRangeControl } from "../../components/dates";
import { InputControl } from "../../components/form";
import { useApi } from "../../components/hooks";
import Table from "../../components/table/Table";

const EstimateList = () => {
    const { t } = useTranslation();
    const isLargeScreen = useMediaQuery("(min-width:700px)");
    const columns = [
        {
            field: "code",
            headerName: t("code"),
            flex: isLargeScreen && 1,
        },
        {
            field: "username",
            headerName: t("doctor"),
            flex: isLargeScreen && 1,
        },
        {
            field: "fullName",
            headerName: t("patient"),
            valueGetter: (params) => `${params.row.patient_name || ""} ${params.row.patient_lastname || ""}`,
            flex: isLargeScreen && 1,
        },
        {
            field: "total",
            headerName: t("total_price"),
            flex: isLargeScreen && 1,
        },
    ];
    const [estimates, setEstimates] = useState([]);
    const [dates, setDates] = useState({
        startDate: null,
        endDate: null,
    });
    const [searchCode, setSearchCode] = useState(null);
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "lastEstimates",
            data: { number: 100 },
            responseData: "_id total patient_name patient_lastname username created_at code",
        });
        if (response?.lastEstimates) setEstimates(response.lastEstimates);
    };

    const loadDataByDates = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "estimates",
            data: dates,
            responseData: "_id total patient_name patient_lastname username created_at code",
        });
        if (response?.estimates) setEstimates(response.estimates);
    };

    const loadDataByCode = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "estimateByCode",
            data: { code: parseInt(searchCode) },
            responseData: "_id total patient_name patient_lastname username created_at code",
        });
        if (response?.estimateByCode) setEstimates([response.estimateByCode]);
        else setEstimates([]);
    };

    const onDeleteHandler = async (rows = []) => {
        if (rows.length === 0) return;
        const data = {};
        if (rows.length > 1) data._ids = rows;
        else data._id = rows[0];
        const response = await fetch({
            operation: "mutation",
            endpoint: rows.length > 1 ? "deleteEstimates" : "deleteEstimate",
            data,
            responseData: "_id",
        });
        if (response?.deleteEstimates || response?.deleteEstimate?._id) {
            setEstimates(estimates.filter((estimate) => !rows.some((row) => row === estimate._id)));
            enqueueSnackbar(t("deleted_with_success"), { variant: "info" });
        }
    };

    useEffect(() => {
        loadData();
    }, []);
    return (
        <>
            <Stack direction="row" gap={2} flexWrap="wrap" alignItems={"center"} marginBottom={2}>
                <DateRangeControl
                    startLabel={t("start_date")}
                    endLabel={t("end_date")}
                    values={dates}
                    onChange={setDates}
                />
                <Button disabled={!dates.startDate || !dates.endDate} variant="contained" onClick={loadDataByDates}>
                    {t("load")}
                </Button>
                <Button variant="outlined" onClick={loadData}>
                    {t("last_100")}
                </Button>
            </Stack>
            <Stack direction="row" gap={2} flexWrap="wrap" alignItems={"center"} marginBottom={2}>
                <InputControl
                    value={searchCode}
                    onChange={(e) => setSearchCode(e.target.value)}
                    type="number"
                    label={t("search_by_code")}
                    fullWidth={false}
                />
                <Button disabled={isNaN(parseFloat(searchCode))} variant="contained" onClick={loadDataByCode}>
                    {t("load")}
                </Button>
            </Stack>
            <Table
                rows={estimates}
                columns={columns}
                onRowClick={(row) => navigate(`${row?._id?.split("estimates_")[1]}`)}
                onCreate={() => navigate("create")}
                onRowsDelete={onDeleteHandler}
                titleLabel={t("estimates")}
                loading={loading}
            />
        </>
    );
};

export default EstimateList;

import React from "react";
import { Tooltip } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ImplantImage from "./implant.png";

const ToothComponentWrapper = ({ toothInformation, isSelected, children, number, onClick, isImplant = false }) => {
    return (
        <Tooltip
            slotProps={{ tooltip: { sx: { fontSize: 16 } } }}
            title={toothInformation}
            followCursor
            arrow
            placement="top"
        >
            <div style={{ cursor: "pointer", position: "relative" }} onClick={() => onClick && onClick()}>
                <div style={{ textAlign: "center", color: "gray" }}>{number}</div>
                {isSelected && (
                    <CheckCircleOutlineIcon
                        color="success"
                        sx={{ position: "absolute", zIndex: 10, top: 0, right: -15 }}
                    />
                )}
                {children}
                {isImplant && (
                    <img
                        style={{
                            position: "absolute",
                            zIndex: 10,
                            top: number < 30 ? 50 : 120,
                            left: 5,
                            transform: number < 30 && "rotate(180deg)",
                        }}
                        width={30}
                        height={50}
                        src={ImplantImage}
                    />
                )}
            </div>
        </Tooltip>
    );
};

export default ToothComponentWrapper;

import { Breadcrumbs, Grid, LinearProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Link } from "../../components/common";
import Table from "../../components/table/Table";
import { useApi } from "../../components/hooks";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

const RoleList = () => {
    const { t } = useTranslation();

    const columns = [
        {
            field: "name",
            headerName: t("name"),
            flex: 1,
        },
        {
            field: "is_active",
            headerName: t("active"),
            flex: 1,
        },
    ];
    const [roles, setRoles] = useState([]);
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "roles",
            responseData: "_id name is_active",
        });
        if (response?.roles) setRoles(response.roles?.filter((role) => role.name !== "Superadmin"));
    };

    const onDeleteHandler = async (rows = []) => {
        if (rows.length === 0) return;
        const data = {};
        if (rows.length > 1) data._ids = rows;
        else data._id = rows[0];
        const response = await fetch({
            operation: "mutation",
            endpoint: rows.length > 1 ? "deleteRoles" : "deleteRole",
            data,
            responseData: "_id",
        });
        if (response?.deleteRoles || response?.deleteRole?._id) {
            setRoles(roles.filter((role) => !rows.some((row) => row === role._id)));
            enqueueSnackbar(t("deleted_with_success"), { variant: "info" });
        }
    };

    const addRole = (roleData) => {
        if (roles?.some((r) => r._id === roleData._id)) {
            setRoles(
                roles?.map((r) => {
                    if (r._id === roleData?._id) return roleData;
                    return r;
                })
            );
            return;
        }
        setRoles([...roles, roleData]);
    };

    return (
        <Grid container alignItems="center">
            <Breadcrumbs sx={{ marginLeft: "auto", marginBottom: "15px" }}>
                <Link to="/">home</Link>
                <Link to="/accounts">accounts</Link>
                <Link to="/accounts/roles">roles</Link>
            </Breadcrumbs>
            <Table
                rows={roles}
                columns={columns}
                onRowClick={(row) => navigate(`${row?._id?.split("roles_")[1]}`)}
                onCreate={() => navigate("create")}
                onRowsDelete={onDeleteHandler}
                titleLabel={t("roles")}
                loading={loading}
            />
            <Outlet context={{ addRole }} />
        </Grid>
    );
};

export default RoleList;

import { Card, CardContent, CardHeader, ListItemText, Skeleton } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { toCurrency } from "../../../utils/toCurrency";

const TotalTreatmentsCard = ({ total = 0.0, number = 0, loading }) => {
    const { t } = useTranslation();
    return (
        <Card sx={{ borderRadius: 3 }}>
            <CardHeader
                title={t("treatments_gross")}
                titleTypographyProps={{ variant: "h6", textTransform: "capitalize" }}
            />
            <CardContent>
                {loading ? (
                    <>
                        <Skeleton height={30} />
                        <Skeleton height={30} />
                    </>
                ) : (
                    <ListItemText
                        primary={`${t("gross")}: ${toCurrency(total?.toFixed(2))}`}
                        secondary={`${t("treatments")}: ${number}`}
                    />
                )}
            </CardContent>
        </Card>
    );
};

export default TotalTreatmentsCard;

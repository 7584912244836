import { Chip, useMediaQuery } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";
import { useApi } from "../../components/hooks";
import Table from "../../components/table/Table";

const ServiceList = () => {
    const { t } = useTranslation();
    const isLargeScreen = useMediaQuery("(min-width:800px)");
    const columns = [
        {
            field: "name",
            headerName: t("name"),
            flex: isLargeScreen && 1,
        },
        {
            field: "service_category_name",
            headerName: t("service_category"),
            flex: isLargeScreen && 1,
        },
        {
            field: "price",
            headerName: t("price"),
            flex: isLargeScreen && 1,
        },
        {
            field: "color",
            renderCell: ({ value }) => <Chip sx={{ backgroundColor: value }} />,
            headerName: t("color"),
            flex: isLargeScreen && 1,
        },
        {
            field: "is_active",
            headerName: t("active"),
            flex: isLargeScreen && 1,
        },
    ];

    const [services, setServices] = useState([]);
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "services",
            responseData: "_id name price color note is_active service_category_name",
        });
        if (response?.services) setServices(response.services);
    };

    const onDeleteHandler = async (rows = []) => {
        if (rows.length === 0) return;
        const data = {};
        if (rows.length > 1) data._ids = rows;
        else data._id = rows[0];
        const response = await fetch({
            operation: "mutation",
            endpoint: rows.length > 1 ? "deleteServices" : "deleteService",
            data,
            responseData: "_id",
        });
        if (response?.deleteServices || response?.deleteService?._id) {
            setServices(services.filter((service) => !rows.some((row) => row === service._id)));
            enqueueSnackbar(t("deleted_with_success"), { variant: "info" });
        }
    };

    useEffect(() => {
        loadData();
    }, []);
    const addService = (serviceData) => {
        if (services?.some((s) => s._id === serviceData._id)) {
            setServices(
                services?.map((s) => {
                    if (s._id === serviceData?._id) return serviceData;
                    return s;
                })
            );
            return;
        }
        setServices([...services, serviceData]);
    };

    return (
        <>
            <Table
                rows={services}
                columns={columns}
                onRowClick={(row) => navigate(`${row?._id?.split("services_")[1]}`)}
                onCreate={() => navigate("create")}
                onRowsDelete={onDeleteHandler}
                titleLabel={t("services")}
                loading={loading}
            />
            <Outlet context={{ addService }} />
        </>
    );
};

export default ServiceList;

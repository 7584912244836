import HomeIcon from "@mui/icons-material/Home";
import GroupsIcon from "@mui/icons-material/Groups";
import EventIcon from "@mui/icons-material/Event";
import PaymentIcon from "@mui/icons-material/Payment";
import InsightsIcon from "@mui/icons-material/Insights";
import SettingsIcon from "@mui/icons-material/Settings";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

const items = [
    {
        name: "HOME",
        label: "home",
        icon: <HomeIcon color="secondary" />,
        href: "/",
        restrict: {},
    },
    {
        name: "PATIENTS",
        label: "patients",
        icon: <GroupsIcon color="secondary" />,
        href: "/patients",
        restrict: {},
    },
    {
        name: "APPOINTMENTS",
        label: "appointments",
        icon: <EventIcon color="secondary" />,
        href: "/appointments",
        restrict: {},
    },
    {
        name: "FINANCE",
        label: "finance",
        icon: <PaymentIcon color="secondary" />,
        href: "/invoices",
        restrict: {},
    },
    {
        name: "RAPORTS",
        label: "raports",
        icon: <InsightsIcon color="secondary" />,
        href: "/raports",
        restrict: {},
    },
    {
        name: "SETTINGS",
        label: "settings",
        icon: <SettingsIcon color="secondary" />,
        href: "/settings",
        restrict: {},
    },
    {
        name: "ACCOUNTS",
        label: "accounts",
        icon: <ManageAccountsIcon color="secondary" />,
        href: "/accounts",
        restrict: {},
    },
];

export default items;

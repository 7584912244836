import React from "react";
import { SimpleTable } from "../../../components/table";

const TreatmentsOrthoTable = ({ data }) => {
    return (
        <div>
            <SimpleTable
                variant="outlined"
                color="secondary"
                columns={[{ header: "date" }, { header: "treatment" }, { header: "next_treatment" }]}
                data={data}
            />
        </div>
    );
};

export default TreatmentsOrthoTable;

import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const SimpleTable = ({ color = "primary", columns = [], data = [], cellMinWidth }) => {
    const { t } = useTranslation();
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow
                        sx={{ backgroundColor: `${color}Light.main` }}
                        style={{ borderRadius: "15px !important" }}
                    >
                        {columns.map((col, index) => (
                            <TableCell
                                align={index !== columns?.length - 1 ? "left" : "right"}
                                sx={{
                                    color: `${color}.main`,
                                    textTransform: "capitalize",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    paddingY: "5px",
                                    minWidth: cellMinWidth && cellMinWidth,
                                    border: "none",
                                    borderTopLeftRadius: index === 0 ? "10px" : 0,
                                    borderBottomLeftRadius: index === 0 ? "10px" : 0,
                                    borderTopRightRadius: index === columns?.length - 1 ? "10px" : 0,
                                    borderBottomRightRadius: index === columns?.length - 1 ? "10px" : 0,
                                }}
                            >
                                {t(col.header)}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row) => (
                        <TableRow sx={{ border: "none" }}>
                            {Object.entries(row).map(([col, value], index) => (
                                <TableCell
                                    sx={{
                                        paddingY: "5px",
                                        fontSize: 14,
                                        border: "none",
                                    }}
                                    align={index !== columns?.length - 1 ? "left" : "right"}
                                >
                                    {value}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {data.length === 0 && (
                <Typography paddingY={1} textAlign="center" sx={{ fontStyle: "italic" }}>
                    {t("no_data")}
                </Typography>
            )}
        </TableContainer>
    );
};

export default SimpleTable;

import { Button, LinearProgress, Toolbar, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React from "react";
import { DeleteButtonWithConfirm } from "../../common/";

const TableListToolbar = ({ titleLabel, onRowsDelete, onCreate, loading }) => {
    return (
        <>
            <Toolbar>
                <Typography textTransform="capitalize" color="primary" variant="h6">
                    {titleLabel}
                </Typography>
                <Button onClick={onCreate} sx={{ marginLeft: 2 }} variant="contained" color="primaryLight">
                    <AddIcon />
                </Button>
                <DeleteButtonWithConfirm onClick={onRowsDelete} />
            </Toolbar>
            {loading && <LinearProgress color="primary" />}
        </>
    );
};

export default TableListToolbar;

import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { InputControl, SelectControl } from "../../components/form";
import { EditableTable } from "../../components/table";
import { Modal } from "../../components/modal";
import { useApi } from "../../components/hooks";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

const rules = { name: "string|required" };

const DrugForm = () => {
    const cols = [
        {
            headerName: "amount",
            cell: ({ row, rowIndex }) => (
                <InputControl
                    value={row?.amount}
                    type="number"
                    onChange={(e) =>
                        setValues({
                            ...values,
                            amount_variants: values?.amount_variants?.map((el, i) => {
                                if (i === rowIndex) el.amount = e.target.value;
                                return el;
                            }),
                        })
                    }
                />
            ),
        },
        {
            headerName: "unit",
            cell: ({ row, rowIndex }) => (
                <SelectControl
                    options={[
                        { value: "mg", label: "mg" },
                        { value: "g", label: "g" },
                        { value: "l", label: "l" },
                        { value: "ml", label: "ml" },
                        { value: "kg", label: "kg" },
                        { value: "%", label: "%" },
                    ]}
                    value={row?.unit || ""}
                    onChange={(e) =>
                        setValues({
                            ...values,
                            amount_variants: values?.amount_variants?.map((el, i) => {
                                if (i === rowIndex) el.unit = e.target.value;
                                return el;
                            }),
                        })
                    }
                />
            ),
        },
    ];
    const [values, setValues] = useState({
        amount_variants: [{}],
    });
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const params = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { addDrug } = useOutletContext() || {};

    useEffect(() => {
        if (params?.id !== "create") loadData();
    }, []);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "drug",
            data: {
                _id: "drugs_" + params?.id,
            },
            responseData: "_id name amount_variants{amount unit}",
        });
        if (response?.drug) setValues(response.drug);
    };
    const saveHandler = async () => {
        const { name, amount_variants, _id } = values;
        const data = {
            name,
            amount_variants: amount_variants
                ?.filter((variant) => !!variant.amount)
                ?.map((variant) => ({ amount: parseFloat(variant.amount), unit: variant.unit })),
        };
        if (_id) data._id = _id;
        const response = await fetch({
            operation: "mutation",
            endpoint: _id ? "updateDrug" : "createDrug",
            data,
            responseData: "_id name amount_variants{amount unit}",
        });
        if (response?.createDrug?._id || response?.updateDrug?._id) {
            enqueueSnackbar(t("drug_saved"), { variant: "default" });
            addDrug && addDrug(_id ? response.updateDrug : response.createDrug);

            navigate(-1);
        }
    };

    return (
        <Modal
            loading={loading}
            titlelabel={t("drug_form")}
            maxWidth="xs"
            open={true}
            onClose={() => navigate(-1)}
            FormProps={{
                values,
                onValuesChange: setValues,
                onSubmit: saveHandler,
                rules,
            }}
            permissions={{ drugs: values?._id ? "update" : "create" }}
        >
            <InputControl name="name" label={t("name")} sx={{ marginBottom: 2 }} />
            <EditableTable
                variant="outlined"
                columns={cols}
                data={values?.amount_variants || []}
                onAddRow={() => setValues({ ...values, amount_variants: [...(values.amount_variants || []), {}] })}
                onRowDelete={(index) =>
                    setValues({ ...values, amount_variants: values.amount_variants?.filter((el, i) => i !== index) })
                }
            />
        </Modal>
    );
};

export default DrugForm;

import {
    Avatar,
    Button,
    Chip,
    Grid,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Paper,
    Stack,
    Typography,
    useMediaQuery,
} from "@mui/material";
import React, { useContext, useMemo, useState } from "react";
import AddNewTreatmentCard from "./AddNewTreatmentCard";
import TreatmentsTable from "./TreatmentsTable";
import CloseIcon from "@mui/icons-material/Close";
import ToothsChart from "../../../components/common/ToothsChart";
import { CheckboxControl, Form, InputControl, SelectControl } from "../../../components/form";
import { useTranslation } from "react-i18next";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PaymentsIcon from "@mui/icons-material/Payments";
import CancelIcon from "@mui/icons-material/Cancel";
import { PatientDataContext } from "./PatientDataContext";
import { DateTime } from "luxon";
import { useApi } from "../../../components/hooks";
import { useSnackbar } from "notistack";
import { Modal } from "../../../components/modal";
import { toCurrency } from "../../../utils/toCurrency";
import { Outlet, useNavigate } from "react-router-dom";
import { ProtectedAccessAction } from "../../../components/common";

const treatmentStatusDetails = {
    0: {
        label: "⦿ cancelled",
        color: "error",
    },
    1: {
        label: "⦿ planned",
        color: "orange",
    },
    2: {
        label: "⦿ completed",
        color: "success.main",
    },
};

const rules = {
    teeth: "required",
    date: "required",
    user_id: "required",
    service_id: "required",
    price: "required",
};

const PatientsTreatmentsPanel = () => {
    const isLargeScreen = useMediaQuery("(min-width:800px)");
    const [values, setValues] = useState({});
    const [activeFilter, setActiveFilter] = useState("all_treatments");
    const { patientData, setPatientData } = useContext(PatientDataContext);
    const [selectedTreatments, setSelectedTreatments] = useState(null);

    const { t } = useTranslation();
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const selectTooth = (tooth) => {
        if (Boolean(values.service_id)) return;
        if (!values.teeth) {
            setValues({ ...values, teeth: [tooth] });
            return;
        }
        if (values.teeth.includes(tooth)) {
            setValues({ ...values, teeth: values.teeth.filter((t) => t !== tooth) });
            return;
        }
        setValues({ ...values, teeth: [...values.teeth, tooth] });
    };

    const filterOptions = useMemo(() => {
        return [
            {
                label: "all_treatments",
                treatments: patientData?.treatments,
            },
            {
                label: "planned",
                treatments: patientData?.treatments?.filter(({ status }) => {
                    return status === 1;
                }),
            },
            {
                label: "today",
                treatments: patientData?.treatments?.filter(({ status, date }) => {
                    return status !== 0 && date === DateTime.now().toFormat("yyyy-LL-dd");
                }),
            },
            {
                label: "completed",
                treatments: patientData?.treatments?.filter(({ status }) => {
                    return status === 2;
                }),
            },
            {
                label: "cancelled",
                treatments: patientData?.treatments?.filter(({ status }) => {
                    return status === 0;
                }),
            },
        ];
    }, [patientData?.treatments]);

    const treatmentsChartData = useMemo(() => {
        const { treatments = [] } = filterOptions.find((option) => option.label === activeFilter);
        return treatments?.reduce((acc, { teeth = [], service_name, color, crown, root, implant, bridge }) => {
            teeth.forEach((tooth) => {
                acc[tooth] = {
                    crownFillColor: crown && color,
                    rootFillColor: root && color,
                    bridgeFillColor: bridge && color,
                    toothInformation: service_name,
                    isImplant: Boolean(implant),
                };
            });
            return acc;
        }, {});
    }, [filterOptions.find((option) => option.label === activeFilter)]);

    const treatmentsData = useMemo(() => {
        const { treatments = [] } = filterOptions.find((option) => option.label === activeFilter);
        return treatments
            ?.filter((treatment) => {
                if (!values.teeth || values.teeth?.length === 0) return true;
                return treatment.teeth.some((t) => values.teeth?.includes(t));
            })
            .map((treatment) => {
                const treatmentPayments = patientData?.payments?.filter(
                    (payment) => payment.treatment_id === treatment._id
                );
                const paidAmount = treatmentPayments?.reduce((acc, payment) => acc + payment.amount, 0.0) || 0.0;
                const unpaidAmount = treatment.price - paidAmount;

                const treatmentData = {
                    date: treatment.date,
                    tooth: treatment.teeth?.map((tooth) => t(tooth))?.join(" , "),
                    treatment: treatment.service_name,
                    doctor: treatment.username,
                    price: <Typography fontSize={14}>{toCurrency(treatment.price?.toFixed(2))}</Typography>,
                    paid: (
                        <Typography fontSize={14} color="success.main">
                            {toCurrency(paidAmount?.toFixed(2))}
                        </Typography>
                    ),
                    unpaid: (
                        <Typography fontSize={14} color="error">
                            {toCurrency(unpaidAmount >= 0 ? unpaidAmount?.toFixed(2) : (0.0).toFixed(2))}
                        </Typography>
                    ),
                    status: (
                        <Typography
                            fontWeight={600}
                            fontSize={14}
                            color={treatmentStatusDetails[treatment.status]?.color}
                        >
                            {treatmentStatusDetails[treatment.status]?.label}
                        </Typography>
                    ),
                    extra: (
                        <ActionsMenu
                            onAddPayment={({ amount, payment_method_id, note }) =>
                                addTreamentPayment({
                                    amount,
                                    payment_method_id,
                                    note,
                                    user_id: treatment.user_id,
                                    treatment_id: treatment._id,
                                })
                            }
                            onCancelTreatment={() => cancelTreatment(treatment._id)}
                            onCompleteTreatment={() => completeTreatment(treatment._id)}
                            disabledPayment={treatment.status === 0}
                            disabledCancel={treatmentPayments?.length > 0}
                            paid={paidAmount}
                            unpaid={unpaidAmount}
                        />
                    ),
                };
                if (selectedTreatments !== null)
                    treatmentData.select = (
                        <CheckboxControl
                            disabled={patientData?.invoices?.some((invoice) =>
                                invoice?.table_data?.some((t) => t.treatment === treatment._id)
                            )}
                            value={selectedTreatments?.includes(treatment._id)}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setSelectedTreatments([...selectedTreatments, treatment._id]);
                                    return;
                                }
                                setSelectedTreatments(selectedTreatments.filter((t) => t !== treatment._id));
                            }}
                        />
                    );
                return treatmentData;
            });
    }, [
        filterOptions.find((option) => option.label === activeFilter),
        values.teeth,
        patientData.payments,
        selectedTreatments,
    ]);

    const saveTreatment = async () => {
        const data = {
            teeth: values.teeth,
            date: values.date,
            user_id: values.user_id,
            diagnose_id: values.diagnose_id,
            service_id: values.service_id,
            price: parseFloat(values.price),
            note: values.note,
            patient_id: patientData._id,
        };

        const response = await fetch({
            operation: "mutation",
            endpoint: "createTreatment",
            data,
            responseData: `_id teeth date user_id user_id diagnose_id diagnose_name price status
            service_id service_name color crown root implant bridge price note username`,
        });
        if (response?.createTreatment?._id) {
            if (!values._id) {
                enqueueSnackbar(t("treatment_saved"), { variant: "default" });
                setPatientData({
                    ...patientData,
                    treatments: [...(patientData?.treatments || []), response?.createTreatment],
                });
                setValues({});
                return;
            }
        }
    };

    const addTreamentPayment = async ({ user_id, payment_method_id, note, amount, treatment_id }) => {
        const data = {
            user_id,
            payment_method_id,
            note,
            amount: parseFloat(amount),
            treatment_id,
            patient_id: patientData._id,
        };
        const response = await fetch({
            operation: "mutation",
            endpoint: "createPayment",
            data,
            responseData: `_id paymentmethod_name amount created_at treatment_id`,
        });
        if (response?.createPayment?._id) {
            enqueueSnackbar(t("payment_saved"), { variant: "default" });
            setPatientData({
                ...patientData,
                payments: [...(patientData?.payments || []), response.createPayment],
            });
        }
    };

    const cancelTreatment = async (treatment_id) => {
        const response = await fetch({
            operation: "mutation",
            endpoint: "cancelTreatment",
            data: { _id: treatment_id },
            responseData: `_id teeth date user_id user_id diagnose_id diagnose_name price status
            service_id service_name color crown root implant bridge price note username`,
        });
        if (response?.cancelTreatment?._id) {
            enqueueSnackbar(t("treatment_cancelled"), { variant: "default" });
            setPatientData({
                ...patientData,
                treatments: patientData.treatments.map((t) => {
                    if (t._id === response.cancelTreatment._id) return response.cancelTreatment;
                    return t;
                }),
            });
        }
    };
    const completeTreatment = async (treatment_id) => {
        const response = await fetch({
            operation: "mutation",
            endpoint: "completeTreatment",
            data: { _id: treatment_id },
            responseData: `_id teeth date user_id user_id diagnose_id diagnose_name price status
            service_id service_name color crown root implant bridge price note username`,
        });
        if (response?.completeTreatment?._id) {
            enqueueSnackbar(t("treatment_completed"), { variant: "success" });
            setPatientData({
                ...patientData,
                treatments: patientData.treatments.map((t) => {
                    if (t._id === response.completeTreatment._id) return response.completeTreatment;
                    return t;
                }),
            });
        }
    };

    const addInvoice = async (invoice) => {
        setPatientData({ ...patientData, invoices: [...(patientData?.invoices || []), invoice] });
        setSelectedTreatments(null);
    };
    return (
        <Paper sx={{ margin: 0, padding: 2, borderRadius: "15px" }}>
            <Grid container spacing={2} alignItems="start">
                <Grid container item xs={12} md={selectedTreatments !== null ? 12 : 9}>
                    <Grid item xs={12}>
                        <Stack direction="row" gap={2} marginBottom={4} alignItems="center" flexWrap="wrap">
                            {filterOptions.map((option) => {
                                const isActive = activeFilter === option.label;
                                return (
                                    <Chip
                                        onClick={() => setActiveFilter(option.label)}
                                        color={isActive ? "secondary" : "default"}
                                        variant="outlined"
                                        avatar={<Avatar>{option.treatments?.length || 0}</Avatar>}
                                        label={t(option.label)}
                                        sx={{
                                            backgroundColor: isActive ? "secondaryLight.main" : "default",
                                            borderColor: isActive ? "secondary" : "#dfdfdf",
                                            textTransform: "capitalize",
                                        }}
                                    />
                                );
                            })}
                            <Chip
                                onClick={() => {
                                    if (selectedTreatments !== null) {
                                        navigate("createInvoices/create");
                                        return;
                                    }
                                    setSelectedTreatments([]);
                                }}
                                color={"secondary"}
                                variant="outlined"
                                label={selectedTreatments !== null ? t("generate_invoice") : t("add_to_invoice")}
                                avatar={
                                    selectedTreatments !== null && (
                                        <IconButton
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                setSelectedTreatments(null);
                                            }}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    )
                                }
                                sx={{ textTransform: "capitalize" }}
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} display={selectedTreatments !== null && "none"} sx={{ overflowX: "scroll" }}>
                        <ToothsChart selected={values.teeth || []} onSelect={selectTooth} {...treatmentsChartData} />
                    </Grid>
                    <Grid item xs={12} marginTop={2}>
                        <TreatmentsTable data={treatmentsData} />
                    </Grid>
                </Grid>
                <Grid item xs={12} md={3} position="sticky" top={70} display={selectedTreatments !== null && "none"}>
                    <Form values={values} onValuesChange={setValues} onSubmit={saveTreatment} rules={rules}>
                        {AddNewTreatmentCard({
                            teethNumber: values?.teeth?.length,
                            onChange: (data = {}) => setValues({ ...values, ...data }),
                            disableTeethSelection: Boolean(!!values.service_id),
                            disableServiceSelection: !(values?.teeth?.length > 0),
                        })}
                    </Form>
                </Grid>
            </Grid>
            <Outlet context={{ patientId: patientData?._id, selectedTreatments, onSaveEffect: addInvoice }} />
        </Paper>
    );
};

const ActionsMenu = ({
    onAddPayment,
    onCancelTreatment,
    onCompleteTreatment,
    disabledPayment,
    disabledCancel,
    paid,
    unpaid,
}) => {
    const { t } = useTranslation();
    const [anchorMenu, setAnchorMenu] = useState(null);
    const [openPaymentModal, setOpenPaymentModal] = useState(false);
    const [values, setValues] = useState({});
    const { extraData } = useContext(PatientDataContext);

    const handleMoreMenuClick = (event) => {
        setAnchorMenu(event.currentTarget);
    };
    const handleMoreMenuClose = () => {
        setAnchorMenu(null);
    };
    return (
        <>
            <Button onClick={handleMoreMenuClick} aria-owns={anchorMenu ? "more-menu" : null}>
                <MoreVertIcon />
            </Button>
            <Menu
                PaperProps={{ variant: "elevation", elevation: 1 }}
                sx={{ marginTop: 2 }}
                id="more-menu"
                anchorEl={anchorMenu}
                open={Boolean(anchorMenu)}
                onClose={handleMoreMenuClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <MenuItem
                    sx={{ width: 200 }}
                    onClick={() => {
                        setOpenPaymentModal(true);
                        handleMoreMenuClose();
                    }}
                    disabled={disabledPayment}
                >
                    <ListItemIcon>
                        <PaymentsIcon />
                    </ListItemIcon>
                    <ListItemText>{t("pay")}</ListItemText>
                </MenuItem>
                <ProtectedAccessAction permissions={{ treatments: "delete" }}>
                    <MenuItem
                        onClick={() => {
                            onCancelTreatment && onCancelTreatment();
                            handleMoreMenuClose();
                        }}
                        disabled={disabledCancel || disabledPayment}
                    >
                        <ListItemIcon>
                            <CancelIcon />
                        </ListItemIcon>
                        <ListItemText>{t("cancel")}</ListItemText>
                    </MenuItem>
                </ProtectedAccessAction>
                <ProtectedAccessAction permissions={{ treatments: "update" }}>
                    <MenuItem
                        onClick={() => {
                            onCompleteTreatment && onCompleteTreatment();
                            handleMoreMenuClose();
                        }}
                        disabled={disabledPayment}
                    >
                        <ListItemIcon>
                            <CancelIcon />
                        </ListItemIcon>
                        <ListItemText>{t("mark_complete")}</ListItemText>
                    </MenuItem>
                </ProtectedAccessAction>
            </Menu>
            <Modal
                open={openPaymentModal}
                onClose={() => {
                    setOpenPaymentModal(false);
                    setValues({});
                }}
                FormProps={{
                    values,
                    onValuesChange: setValues,
                    onSubmit: () => {
                        setOpenPaymentModal(false);
                        onAddPayment && onAddPayment(values);
                        setValues({});
                    },
                    rules: {
                        amount: "required",
                        payment_method_id: "required",
                    },
                }}
                yesText={t("confirm_payment")}
                permissions={{ payments: "create" }}
            >
                <Typography color="success.main">
                    {t("paid")}: {(paid || 0.0)?.toFixed(2)}
                </Typography>
                <Typography color="error">
                    {t("unpaid")}: {((unpaid >= 0 ? unpaid : 0.0) || 0.0)?.toFixed(2)}
                </Typography>
                <InputControl name="amount" label={t("amount")} type="number" />
                <SelectControl options={extraData?.paymentmethods || []} name="payment_method_id" label={t("method")} />
                <InputControl name="note" label={t("note")} multiline minRows={3} />
            </Modal>
        </>
    );
};

export default PatientsTreatmentsPanel;

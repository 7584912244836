import { InputAdornment, TextField } from "@mui/material";
import React from "react";

const InputControl = ({ size = "small", margin = "dense", fullWidth = true, isCurrencyInput, ...rest }) => {
    const currencyCode = localStorage.getItem("currency");
    return (
        <TextField
            size={size}
            margin={margin}
            fullWidth={fullWidth}
            InputProps={{
                startAdornment: isCurrencyInput && <InputAdornment position="start">{currencyCode}</InputAdornment>,
            }}
            {...rest}
        />
    );
};

export default InputControl;

import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";
import { useApi } from "../../components/hooks";
import Table from "../../components/table/Table";

const DiagnoseList = () => {
    const { t } = useTranslation();

    const columns = [
        {
            field: "name",
            headerName: t("name"),
            flex: 1,
        },
        {
            field: "code",
            headerName: t("code"),
            flex: 1,
        },
        {
            field: "is_active",
            headerName: t("active"),
            flex: 1,
        },
    ];
    const [diagnoses, setDiagnoses] = useState([]);
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "diagnoses",
            responseData: "_id name code is_active",
        });
        if (response?.diagnoses) setDiagnoses(response.diagnoses);
    };

    const onDeleteHandler = async (rows = []) => {
        if (rows.length === 0) return;
        const data = {};
        if (rows.length > 1) data._ids = rows;
        else data._id = rows[0];
        const response = await fetch({
            operation: "mutation",
            endpoint: rows.length > 1 ? "deleteDiagnoses" : "deleteDiagnose",
            data,
            responseData: "_id",
        });
        if (response?.deleteDiagnoses || response?.deleteDiagnose?._id) {
            setDiagnoses(diagnoses.filter((diagnose) => !rows.some((row) => row === diagnose._id)));
            enqueueSnackbar(t("deleted_with_success"), { variant: "info" });
        }
    };

    useEffect(() => {
        loadData();
    }, []);
    const addDiagnose = (diagnoseData) => {
        if (diagnoses?.some((d) => d._id === diagnoseData._id)) {
            setDiagnoses(
                diagnoses?.map((d) => {
                    if (d._id === diagnoseData?._id) return diagnoseData;
                    return d;
                })
            );
            return;
        }
        setDiagnoses([...diagnoses, diagnoseData]);
    };

    return (
        <>
            <Table
                rows={diagnoses}
                columns={columns}
                onRowClick={(row) => navigate(`${row?._id?.split("diagnoses_")[1]}`)}
                onCreate={() => navigate("create")}
                onRowsDelete={onDeleteHandler}
                titleLabel={t("diagnoses")}
                loading={loading}
            />
            <Outlet context={{ addDiagnose }} />
        </>
    );
};

export default DiagnoseList;

import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    CardMedia,
    Grid,
    IconButton,
    LinearProgress,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Typography,
} from "@mui/material";
import { useApi } from "../../components/hooks";
import { useSnackbar } from "notistack";
import { Form, InputControl, SelectControl } from "../../components/form";
import { EditableTable } from "../../components/table";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LanguageIcon from "@mui/icons-material/Language";
import { useTranslation } from "react-i18next";
import CancelIcon from "@mui/icons-material/Cancel";
import UploadComponent from "../../components/form/UploadComponent";
import { ProtectedAccessAction } from "../../components/common";
import { uploadToAws } from "../../utils/uploadToAWS";

const rules = {
    name: "string|required",
    email: "email",
};

const SettingsForm = () => {
    const { t } = useTranslation();

    const cols = [
        {
            headerName: t("network"),
            cell: ({ row, rowIndex }) => (
                <SelectControl
                    options={[
                        {
                            value: "facebook",
                            label: (
                                <ListItem dense sx={{ paddingY: 0 }}>
                                    <ListItemIcon>
                                        <FacebookIcon sx={{ color: "black" }} />
                                    </ListItemIcon>
                                    <ListItemText primary="Facebook" />
                                </ListItem>
                            ),
                        },
                        {
                            value: "instagram",
                            label: (
                                <ListItem dense sx={{ paddingY: 0 }}>
                                    <ListItemIcon>
                                        <InstagramIcon sx={{ color: "black" }} />
                                    </ListItemIcon>
                                    <ListItemText primary="Instagram" />
                                </ListItem>
                            ),
                        },
                    ]}
                    value={row?.network || ""}
                    onChange={(e) =>
                        setValues({
                            ...values,
                            social_accounts: values?.social_accounts?.map((el, i) => {
                                if (i === rowIndex) el.network = e.target.value;
                                return el;
                            }),
                        })
                    }
                />
            ),
        },
        {
            headerName: t("account"),
            cell: ({ row, rowIndex }) => (
                <InputControl
                    value={row?.account}
                    onChange={(e) =>
                        setValues({
                            ...values,
                            social_accounts: values?.social_accounts?.map((el, i) => {
                                if (i === rowIndex) el.account = e.target.value;
                                return el;
                            }),
                        })
                    }
                    style={{ height: "100%" }}
                />
            ),
        },
    ];
    const [values, setValues] = useState({
        social_accounts: [{}],
    });
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "settings",
            responseData: `_id name phone email social_accounts{network account} logo address`,
        });
        if (response?.settings) setValues(response.settings);
    };
    const saveHandler = async () => {
        const { name, phone, email, social_accounts, address, _id, file } = values;
        const data = { name, phone, email, social_accounts, address };
        if (!!file) {
            data.logo = await uploadToAws(file, fetch);
        }
        const response = await fetch({
            operation: "mutation",
            endpoint: _id ? "updateSettings" : "createSettings",
            data,
            responseData: "name",
        });
        if (response?.createSettings?.name || response?.updateSettings?.name)
            enqueueSnackbar(t("settings_saved"), { variant: "default" });
    };
    return (
        <Form values={values} onValuesChange={setValues} rules={rules} onSubmit={saveHandler}>
            {loading && <LinearProgress color="primary" />}
            <Paper sx={{ marginBottom: 2, padding: 2 }}>
                <Typography marginBottom={1} variant="h5">
                    {t("clinic_data")}
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <InputControl name="name" label={t("name")} />
                    </Grid>
                    <Grid item xs={6}>
                        <InputControl name="phone" label={t("phone")} />
                    </Grid>
                    <Grid item xs={6}>
                        <InputControl type="email" name="email" label={t("email")} />
                    </Grid>
                    <Grid item xs={6}>
                        <InputControl name="address" label={t("address")} />
                    </Grid>
                    <Grid item xs={12}>
                        {!!values?.filePreview ? (
                            <Card sx={{ width: 300, position: "relative", marginBottom: 1 }}>
                                <CardMedia
                                    sx={{ width: "100%", height: 300, objectFit: "cover" }}
                                    image={values.filePreview}
                                />
                                <IconButton
                                    onClick={() => setValues({ ...values, file: null, filePreview: null })}
                                    sx={{ position: "absolute", top: 0, right: 0 }}
                                >
                                    <CancelIcon color="error" />
                                </IconButton>
                            </Card>
                        ) : values?.logo ? (
                            <Card sx={{ width: 300, position: "relative", marginBottom: 1 }}>
                                <CardMedia
                                    sx={{ width: "100%", height: 300, objectFit: "cover" }}
                                    image={values.logo}
                                />
                                <IconButton
                                    onClick={() => setValues({ ...values, logo: null })}
                                    sx={{ position: "absolute", top: 0, right: 0 }}
                                >
                                    <CancelIcon color="error" />
                                </IconButton>
                            </Card>
                        ) : null}
                        <UploadComponent
                            onFileChange={(e) => {
                                setValues({
                                    ...values,
                                    file: e.target?.files?.[0],
                                    filePreview: URL.createObjectURL(e.target.files[0]),
                                });
                            }}
                        />
                    </Grid>
                </Grid>
            </Paper>
            <EditableTable
                variant="outlined"
                columns={cols}
                data={values?.social_accounts || []}
                onAddRow={() => setValues({ ...values, social_accounts: [...values.social_accounts, {}] })}
                onRowDelete={(index) =>
                    setValues({
                        ...values,
                        social_accounts: values.social_accounts.filter((el, i) => i !== index),
                    })
                }
            />
            <Grid container xs={12} justifyContent="end">
                <ProtectedAccessAction permissions={{ settings: values._id ? "update" : "create" }}>
                    <Button size="large" sx={{ marginTop: 2 }} variant="contained" type="submit">
                        {t("save")}
                    </Button>
                </ProtectedAccessAction>
            </Grid>
        </Form>
    );
};

export default SettingsForm;

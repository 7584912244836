import { CardHeader, IconButton, List, ListItem, ListItemIcon, ListItemText, useMediaQuery } from "@mui/material";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import LogoutIcon from "@mui/icons-material/Logout";
import React from "react";
import items from "./menuItems";
import { GlassEffectDrawer, LanguageMenu } from "../common";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const drawerWidth = 240;

const Sidebar = ({ open, toggleOpen }) => {
    const { t } = useTranslation();
    const isLargeScreen = useMediaQuery("(min-width:600px)");
    return (
        <GlassEffectDrawer
            variant="permanent"
            open={open}
            PaperProps={{
                sx: {
                    width: open ? drawerWidth : isLargeScreen ? 60 : 0,
                    overflowX: "hidden",
                    transition: "all .3s ease-out",
                    backgroundColor: !open ? "transparent" : "rgba(255,255,255,0.85)",
                    boxShadow: "none",
                    border: !open && "none",
                },
            }}
        >
            <CardHeader
                action={
                    <IconButton onClick={() => toggleOpen && toggleOpen()}>
                        <MenuOpenIcon color="secondary" />
                    </IconButton>
                }
            />
            <List>
                {items.map((menu) => {
                    return (
                        <Link style={{ textDecoration: "none" }} to={menu.href}>
                            <ListItem
                                sx={{
                                    marginY: 2,
                                    cursor: "pointer",
                                    ":hover": {
                                        backgroundColor: "secondaryLight.main",
                                    },
                                }}
                            >
                                <ListItemIcon color="secondary">{menu.icon}</ListItemIcon>
                                <ListItemText
                                    primary={t(menu.label)}
                                    primaryTypographyProps={{
                                        color: "secondary",
                                        textTransform: "capitalize",
                                        sx: {
                                            opacity: open ? "100%" : "0",
                                            transition: "all .3s",
                                        },
                                    }}
                                />
                            </ListItem>
                        </Link>
                    );
                })}
                {!isLargeScreen && (
                    <Link style={{ textDecoration: "none" }} to={"login"}>
                        <ListItem
                            sx={{
                                marginY: 2,
                                cursor: "pointer",
                                ":hover": {
                                    backgroundColor: "secondaryLight.main",
                                },
                            }}
                        >
                            <ListItemIcon color="secondary">{<LogoutIcon color="secondary" />}</ListItemIcon>
                            <ListItemText
                                primary={t("logout")}
                                primaryTypographyProps={{
                                    color: "secondary",
                                    textTransform: "capitalize",
                                    sx: {
                                        opacity: open ? "100%" : "0",
                                        transition: "all .3s",
                                    },
                                }}
                            />
                        </ListItem>
                    </Link>
                )}
                <LanguageMenu />
            </List>
        </GlassEffectDrawer>
    );
};

export default Sidebar;

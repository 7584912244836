import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { CheckboxControl, InputControl, SelectControl, SwitchControl } from "../../components/form";
import { Modal } from "../../components/modal";
import { useApi } from "../../components/hooks";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

const rules = {
    name: "string|required",
};
const ServiceForm = () => {
    const [values, setValues] = useState({});
    const [serviceCategories, setServiceCategories] = useState([]);
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const params = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { addService } = useOutletContext() || {};

    useEffect(() => {
        if (params?.id !== "create") loadData();
    }, []);
    useEffect(() => {
        loadExtraData();
    }, []);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "service",
            data: {
                _id: "services_" + params?.id,
            },
            responseData:
                "_id name price color note is_active crown root implant bridge service_category_id is_tooth_priced is_clinic_service",
        });
        if (response?.service) setValues(response.service);
    };
    const loadExtraData = async () => {
        const response = await fetch({
            operation: "query",
            multipleEndpoints: [{ endpoint: "servicecategories", responseData: "_id name" }],
        });
        if (!!response?.servicecategories)
            setServiceCategories(
                response.servicecategories.map((serviceCategory) => ({
                    value: serviceCategory._id,
                    label: serviceCategory.name,
                }))
            );
    };
    const saveHandler = async () => {
        const {
            name,
            price,
            color,
            note,
            is_active,
            crown,
            root,
            implant,
            bridge,
            service_category_id,
            is_clinic_service,
            is_tooth_priced,
            _id,
        } = values;
        const data = {
            name,
            price: !!price ? parseFloat(price) : null,
            color,
            note,
            is_active,
            crown,
            root,
            implant,
            bridge,
            service_category_id,
            is_clinic_service,
            is_tooth_priced,
        };
        if (_id) data._id = _id;
        const response = await fetch({
            operation: "mutation",
            endpoint: _id ? "updateService" : "createService",
            data,
            responseData: "_id name price color note is_active service_category_name",
        });
        if (response?.createService?._id || response?.updateService?._id) {
            enqueueSnackbar(t("service_saved"), { variant: "default" });
            addService && addService(_id ? response.updateService : response.createService);

            navigate(-1);
        }
    };
    return (
        <Modal
            titlelabel={t("service_form")}
            maxWidth="xs"
            open={true}
            onClose={() => navigate(-1)}
            FormProps={{
                values,
                onValuesChange: setValues,
                onSubmit: saveHandler,
                rules,
            }}
            loading={loading}
            permissions={{ services: values?._id ? "update" : "create" }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <InputControl name="name" label={t("name")} />
                </Grid>
                <Grid item xs={12}>
                    <SelectControl
                        name="service_category_id"
                        label={t("service_category")}
                        options={serviceCategories}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputControl isCurrencyInput type="number" name="price" label={t("price")} />
                </Grid>
                <Grid item xs={12}>
                    <InputControl type="color" name="color" label={t("color")} />
                </Grid>
                <Grid item xs={12}>
                    <InputControl name="note" label={t("note")} multiline minRows={2} />
                </Grid>
                <Grid item xs={12}>
                    <CheckboxControl name={"crown"} label={t("crown")} />
                    <CheckboxControl name={"root"} label={t("root")} />
                    <CheckboxControl name={"implant"} label={t("implant")} />
                    <CheckboxControl name={"bridge"} label={t("bridge")} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <SwitchControl name="is_active" label={t("active")} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <SwitchControl name="is_clinic_service" label={t("clinic_service")} />
                </Grid>
                <Grid item xs={12}>
                    <SwitchControl name="is_tooth_priced" label={t("priced_per_tooth")} />
                </Grid>
            </Grid>
        </Modal>
    );
};

export default ServiceForm;

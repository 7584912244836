import { Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { InputControl } from "../../components/form";
import { useApi } from "../../components/hooks";
import { Modal } from "../../components/modal";

const rules = {
    name: "string|required",
};
const ServiceCategoryForm = () => {
    const [values, setValues] = useState({});
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const params = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { addServiceCategory } = useOutletContext() || {};

    useEffect(() => {
        if (params?.id !== "create") loadData();
    }, []);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "servicecategory",
            data: {
                _id: "servicecategories_" + params?.id,
            },
            responseData: "_id name",
        });
        if (response?.servicecategory) setValues(response.servicecategory);
    };
    const saveHandler = async () => {
        const { name, _id } = values;
        const data = {
            name,
        };
        if (_id) data._id = _id;
        const response = await fetch({
            operation: "mutation",
            endpoint: _id ? "updateServiceCategory" : "createServiceCategory",
            data,
            responseData: "_id name",
        });
        if (response?.createServiceCategory?._id || response?.updateServiceCategory?._id) {
            enqueueSnackbar(t("service_category_saved"), { variant: "default" });
            addServiceCategory &&
                addServiceCategory(_id ? response.updateServiceCategory : response.createServiceCategory);

            navigate(-1);
        }
    };
    return (
        <Modal
            loading={loading}
            titlelabel={t("service_category_form")}
            maxWidth="xs"
            open={true}
            onClose={() => navigate(-1)}
            FormProps={{
                values,
                onValuesChange: setValues,
                onSubmit: saveHandler,
                rules,
            }}
            permissions={{ servicecategories: values?._id ? "update" : "create" }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <InputControl name="name" label={t("name")} />
                </Grid>
            </Grid>
        </Modal>
    );
};

export default ServiceCategoryForm;

import { IconButton, InputBase, Paper } from "@mui/material";
import DateRangeIcon from "@mui/icons-material/DateRange";
import EastIcon from "@mui/icons-material/East";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import { BOTH_DATES, START_DATE, END_DATE } from "./Constants";

const CalendarInput = ({
    startDate,
    endDate,
    openCalendarView,
    onClear,
    startLabel = "start",
    endLabel = "end",
    fullWidth = false,
}) => {
    return (
        <Paper
            variant="outlined"
            sx={{
                maxWidth: fullWidth ? "100%" : "400px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                backgroundColor: "transparent",
            }}
        >
            <InputBase
                startAdornment={
                    <IconButton
                        color="primary"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            openCalendarView(BOTH_DATES);
                        }}
                    >
                        <DateRangeIcon />
                    </IconButton>
                }
                onClick={() => openCalendarView(Boolean(startDate) ? START_DATE : BOTH_DATES)}
                sx={{ flexGrow: 1 }}
                placeholder={startLabel}
                value={startDate ? startDate.toFormat("yyyy-LL-dd") : ""}
                componentsProps={{ input: { style: { textAlign: "center" } } }}
            />
            <EastIcon />
            <InputBase
                endAdornment={
                    <IconButton
                        color="primary"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onClear && onClear();
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                }
                onClick={() => {
                    openCalendarView(Boolean(endDate) ? END_DATE : BOTH_DATES);
                }}
                sx={{ flexGrow: 1 }}
                placeholder={endLabel}
                value={endDate ? endDate.toFormat("yyyy-LL-dd") : ""}
                componentsProps={{ input: { style: { textAlign: "center" } } }}
            />
        </Paper>
    );
};

export default CalendarInput;

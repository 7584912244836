import { Grid, Typography } from "@mui/material";
import React from "react";
import { CheckboxControl, InputControl, RadioGroupControl } from "../../../components/form";

const Anamnese = ({ anamneseData, value, onChange }) => {
    const renderInput = () => {
        if (anamneseData.is_yes_or_no && anamneseData.has_subquestion) {
            return (
                <>
                    <RadioGroupControl
                        options={[
                            { value: "yes", label: "yes" },
                            { value: "no", label: "no" },
                        ]}
                        value={value?.split("#")?.[0]}
                        onChange={(e) => onChange(e.target.value)}
                    />
                    {value?.split("#")?.[0] === "yes" && (
                        <InputControl
                            margin="none"
                            variant="standard"
                            value={value?.split("#")?.[1]}
                            onChange={(e) => onChange("yes#" + e.target.value)}
                        />
                    )}
                </>
            );
        }
        if (anamneseData.is_yes_or_no)
            return (
                <RadioGroupControl
                    options={[
                        { value: "yes", label: "yes" },
                        { value: "no", label: "no" },
                    ]}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                />
            );
        if (anamneseData.has_options) {
            const valueAsArray = !value ? [] : typeof value === "string" ? value.split(" , ") : value;
            return (
                <>
                    {anamneseData.options?.map((option) => {
                        return (
                            <CheckboxControl
                                key={option}
                                value={value?.includes(option)}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        onChange([...valueAsArray, option]);
                                        return;
                                    }
                                    onChange(valueAsArray.filter((o) => o !== option));
                                }}
                                label={option}
                            />
                        );
                    })}
                </>
            );
        }
        return <InputControl variant="standard" value={value} onChange={(e) => onChange(e.target.value)} />;
    };

    return (
        <Grid item container xs={12}>
            <Grid item xs={12}>
                <Typography>
                    {anamneseData?.name} {anamneseData?.has_subquestion && anamneseData?.subquestion}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                {renderInput()}
            </Grid>
        </Grid>
    );
};

export default Anamnese;

import {
    Card,
    CardContent,
    CardHeader,
    Box,
    Grid,
    Divider,
    Typography,
    Chip,
    useMediaQuery,
    Skeleton,
} from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AutoCompleteControl } from "../../../components/form";
import { useNavigate } from "react-router-dom";

const AppointmentsCard = ({ appointments = [], doctorOptions = [], loading }) => {
    const isLargeScreen = useMediaQuery("(min-width:600px)");
    const [selectedDoctor, setSelectedDoctor] = useState(null);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const filteredAppointments = useMemo(() => {
        if (!!selectedDoctor) return appointments?.filter((appointment) => appointment.user_id === selectedDoctor);
        return appointments;
    }, [appointments, selectedDoctor]);

    if (loading) return <CardTemplate t={t} />;

    return (
        <Card sx={{ height: "350px", overflowY: "scroll" }} variant="elevation">
            <CardHeader
                avatar={<EventIcon />}
                title={t("appointments")}
                titleTypographyProps={{ variant: "h6", fontWeight: 600, textTransform: "capitalize" }}
                action={
                    <Box width={isLargeScreen ? 200 : 130}>
                        <AutoCompleteControl
                            value={selectedDoctor}
                            onChange={(e) => setSelectedDoctor(e.target.value)}
                            options={doctorOptions}
                            label={t("filter_by_doctor")}
                        />
                    </Box>
                }
            />
            {filteredAppointments?.length ? (
                <CardContent>
                    {filteredAppointments?.map((appointment) => (
                        <>
                            <AppointmentsRow
                                startTime={appointment.start_time}
                                endTime={appointment.end_time}
                                doctor={appointment.username}
                                doctorColor={appointment.user_color}
                                name={appointment.name}
                                onClick={() =>
                                    navigate(`/appointment/${appointment?._id?.split("appointments_")?.[1]}`)
                                }
                            />
                            <Divider sx={{ marginY: 2 }} />
                        </>
                    ))}
                </CardContent>
            ) : (
                <Typography marginTop={"100px"} textAlign="center">
                    {t("no_appointments_for_today")}
                </Typography>
            )}
        </Card>
    );
};

const AppointmentsRow = ({ startTime, endTime, doctor, doctorColor, name, onClick }) => {
    return (
        <Grid sx={{ cursor: "pointer" }} container onClick={onClick}>
            <Grid item xs={4}>
                {startTime} - {endTime}
            </Grid>
            <Grid item xs={4}>
                <Chip sx={{ borderColor: doctorColor, color: doctorColor }} variant="outlined" label={doctor} />
            </Grid>
            <Grid item xs={4} textAlign="end">
                {name}
            </Grid>
        </Grid>
    );
};

const CardTemplate = ({ t }) => {
    return (
        <Card sx={{ height: "350px", overflowY: "scroll" }} variant="elevation">
            <CardHeader
                avatar={<EventIcon />}
                title={t("appointments")}
                titleTypographyProps={{ variant: "h6", fontWeight: 600, textTransform: "capitalize" }}
            />
            <CardContent>
                <Skeleton height={70} />
                <Skeleton height={70} />
                <Skeleton height={70} />
            </CardContent>
        </Card>
    );
};

export default AppointmentsCard;

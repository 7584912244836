import React from "react";
import { useTranslation } from "react-i18next";
import { AutoCompleteControl, InputControl } from "../../../components/form";
import { EditableTable } from "../../../components/table";
import { teethOptions } from "../../../utils/teethOptions";

const TableData = ({ data = [], treatmentOptions = [], onChange }) => {
    const { t } = useTranslation();
    const columns = [
        {
            headerName: "tooth",
            cell: ({ row, rowIndex }) => (
                <AutoCompleteControl
                    value={row?.tooth}
                    onChange={(e) => {
                        onChange(
                            data.map((el, i) => {
                                if (i === rowIndex) el.tooth = e.target.value;
                                return el;
                            })
                        );
                    }}
                    options={teethOptions}
                    label={t("tooth")}
                />
            ),
        },
        {
            headerName: "treatment",
            cell: ({ row, rowIndex }) => (
                <AutoCompleteControl
                    value={row?.treatment}
                    options={treatmentOptions}
                    onChange={(e) => {
                        onChange(
                            data.map((el, i) => {
                                if (i === rowIndex) {
                                    el.treatment = e.target.value;
                                    const { price } = treatmentOptions.find((t) => t.value === e.target.value) || {};
                                    if (price) el.price = price;
                                }
                                return el;
                            })
                        );
                    }}
                    label={t("treatment")}
                />
            ),
        },
        {
            headerName: "price",
            cell: ({ row, rowIndex }) => (
                <InputControl
                    value={row?.price || ""}
                    onChange={(e) => {
                        onChange(
                            data.map((el, i) => {
                                if (i === rowIndex) el.price = e.target.value;
                                return el;
                            })
                        );
                    }}
                    type="number"
                    label={t("price")}
                />
            ),
        },
        {
            headerName: "description",
            cell: ({ row, rowIndex }) => (
                <InputControl
                    value={row?.description || ""}
                    onChange={(e) => {
                        onChange(
                            data.map((el, i) => {
                                if (i === rowIndex) el.description = e.target.value;
                                return el;
                            })
                        );
                    }}
                    label={t("description")}
                />
            ),
        },
    ];
    return (
        <EditableTable
            columns={columns}
            data={data}
            onAddRow={() => onChange([...data, {}])}
            onRowDelete={(index) => onChange(data.filter((e, i) => i !== index))}
            variant="outlined"
        />
    );
};

export default TableData;

import React from "react";
import { Link } from "react-router-dom";

const CustomLink = ({ children, ...rest }) => {
    return (
        <Link style={{ textDecoration: "none", color: "inherit", fontSize: 14 }} {...rest}>
            {children}
        </Link>
    );
};

export default CustomLink;

import { Autocomplete, TextField } from "@mui/material";
import React from "react";

const AutoCompleteControl = ({
    label,
    options = [],
    fullWidth = true,
    size = "small",
    margin = "dense",
    onChange,
    value,
    error,
    helperText,
    TextFieldProps = {},
    ...otherProps
}) => {
    const getSelectedOption = () => {
        if (otherProps.freeSolo) return value;
        if (!value) return otherProps.multiple ? [] : null;

        if (otherProps.multiple)
            return options.filter((option) => {
                if (typeof option === "object") return value.includes(option.value);
                return value.includes(option);
            });

        return (
            options.find((option) => {
                if (typeof option === "object") return option.value === value;
                return option === value;
            }) || null
        );
    };

    const toEventFormatValue = (result) => {
        return { target: { value: result } };
    };

    const handleChange = (e, newValue) => {
        if (newValue === null || typeof newValue !== "object") {
            onChange && onChange(toEventFormatValue(newValue));
            return;
        }

        if (!otherProps.multiple) {
            onChange && onChange(toEventFormatValue(newValue.value));
            return;
        }

        onChange &&
            onChange(
                toEventFormatValue(
                    newValue.map((v) => {
                        if (typeof v === "object") return v.value;
                        return v;
                    })
                )
            );
        return;
    };

    const { InputProps = {}, ...otherInputProps } = TextFieldProps || {};

    return (
        <Autocomplete
            size={size}
            fullWidth={fullWidth}
            options={options}
            value={getSelectedOption()}
            onChange={handleChange}
            renderInput={(params) => (
                <TextField
                    margin={margin}
                    error={error}
                    helperText={helperText}
                    {...params}
                    {...otherInputProps}
                    InputProps={{ ...params.InputProps, ...InputProps }}
                    label={label}
                />
            )}
            {...otherProps}
        />
    );
};

export default AutoCompleteControl;

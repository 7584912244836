import { Button, Card, CardActions, CardContent, Grid, Paper, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AutoCompleteControl, InputControl } from "../../../components/form";
import { useApi } from "../../../components/hooks";
import { EditableTable } from "../../../components/table";
import { PatientDataContext } from "./PatientDataContext";
import TreatmentsOrthoTable from "./TreatmentsOrthoTable";

const PatientOrthoTreatmentPanel = () => {
    const { t } = useTranslation();
    const { patientData, setPatientData, extraData } = useContext(PatientDataContext);
    const [values, setValues] = useState({
        tableData: [
            { field: "FMIA", field2: "67° ± 3" },
            { field: "FMA", field2: "25° ± 3" },
            { field: "IMPA", field2: "88° ± 3" },
            { field: "SNA", field2: "82°" },
            { field: "SNB", field2: "80°" },
            { field: "ANB", field2: "2° ± 2" },
            { field: "Ao Bo (MM)", field2: "2mm ± 2" },
            { field: "Occlusal Plane", field2: "10°" },
            { field: "Z Angle", field2: "75° ± 5" },
            { field: "Upper Lim (mm)", field2: "/" },
            { field: "Total Chin (mm)", field2: "/" },
            { field: "PFH", field2: "45mm" },
            { field: "AFH", field2: "65mm" },
            { field: "INDEX POST/ANT", field2: "0,69" },
        ],
    });

    useEffect(() => {
        const { tableData, doctor, diagnose, treatment } = patientData.treatment_ortho || {};
        setValues({ tableData: tableData || values.tableData, doctor, diagnose, treatment });
    }, [patientData?.treatment_ortho]);

    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (!patientData?.treatmentsOrthoRecords) {
            loadTreatmentsOrtho();
        }
    }, []);

    const loadTreatmentsOrtho = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "patientTreatmentsOrtho",
            data: {
                patient_id: patientData._id,
            },
            responseData: "_id treatment next_treatment created_at",
        });
        setPatientData({ ...patientData, treatmentsOrthoRecords: response?.patientTreatmentsOrtho || [] });
    };

    const saveTreatment = async () => {
        const { tableData, doctor, diagnose, treatment } = values;
        const response = await fetch({
            operation: "mutation",
            endpoint: "updatePatient",
            data: {
                _id: patientData._id,
                treatment_ortho: { tableData, doctor, diagnose, treatment },
            },
            responseData: "_id",
        });
        if (response?.updatePatient?._id) {
            enqueueSnackbar(t("treatment_ortho_saved"), { variant: "default" });
            setPatientData({ ...patientData, treatment_ortho: { tableData, doctor, diagnose, treatment } });
        }
    };

    const cols = [
        {
            headerName: "",
            cell: ({ row, rowIndex }) => <Typography>{row.field}</Typography>,
        },
        {
            headerName: "",
            cell: ({ row, rowIndex }) => <Typography>{row.field2}</Typography>,
        },
        {
            headerName: "",
            cell: ({ row, rowIndex }) => (
                <InputControl
                    value={row?.value1}
                    onChange={(e) =>
                        setValues({
                            ...values,
                            tableData: values?.tableData?.map((el, i) => {
                                if (i === rowIndex) el.value1 = e.target.value;
                                return el;
                            }),
                        })
                    }
                />
            ),
        },
        {
            headerName: "",
            cell: ({ row, rowIndex }) => (
                <InputControl
                    value={row?.value2}
                    onChange={(e) =>
                        setValues({
                            ...values,
                            tableData: values?.tableData?.map((el, i) => {
                                if (i === rowIndex) el.value2 = e.target.value;
                                return el;
                            }),
                        })
                    }
                />
            ),
        },
        {
            headerName: "",
            cell: ({ row, rowIndex }) => (
                <InputControl
                    value={row?.value3}
                    onChange={(e) =>
                        setValues({
                            ...values,
                            tableData: values?.tableData?.map((el, i) => {
                                if (i === rowIndex) el.value3 = e.target.value;
                                return el;
                            }),
                        })
                    }
                />
            ),
        },
    ];

    const [newTreatment, setNewTreatment] = useState({});

    const saveTreatmentOrtho = async () => {
        const data = {
            treatment: newTreatment?.treatment,
            next_treatment: newTreatment?.next_treatment,
            patient_id: patientData._id,
        };

        const response = await fetch({
            operation: "mutation",
            endpoint: "createTreatmentOrtho",
            data,
            responseData: `_id treatment next_treatment created_at`,
        });
        if (response?.createTreatmentOrtho?._id) {
            enqueueSnackbar(t("treatment_saved"), { variant: "default" });
            setPatientData({
                ...patientData,
                treatmentsOrthoRecords: [
                    ...(patientData?.treatmentsOrthoRecords || []),
                    response?.createTreatmentOrtho,
                ],
            });
            setNewTreatment({ treatment: null, next_treatment: null });
            return;
        }
    };

    const treatmentsOrthoRecords = useMemo(() => {
        return patientData?.treatmentsOrthoRecords?.map((treatment) => {
            return {
                date: DateTime.fromISO(treatment.created_at).toFormat("yyyy LLL dd"),
                treatment: treatment.treatment,
                next_treatment: treatment.next_treatment,
            };
        });
    }, [patientData?.treatmentsOrthoRecords]);

    return (
        <Grid container spacing={2} alignItems="start">
            <Grid item xs={12} md={6}>
                <EditableTable variant="outlined" columns={cols} data={values.tableData || []} />
            </Grid>
            <Grid container item xs={12} md={6} spacing={4}>
                <Grid item xs={6} md={12}>
                    <Paper sx={{ padding: 3 }}>
                        <AutoCompleteControl
                            value={values?.doctor}
                            onChange={(e) => setValues({ ...values, doctor: e.target.value })}
                            options={extraData?.users || []}
                            margin="none"
                            label={t("doctor")}
                        />
                        <InputControl
                            value={values?.diagnose}
                            label={t("diagnose")}
                            onChange={(e) => setValues({ ...values, diagnose: e.target.value })}
                            multiline
                            minRows={3}
                            sx={{ marginY: 2 }}
                        />
                        <InputControl
                            value={values?.treatment}
                            label={t("treatment")}
                            multiline
                            onChange={(e) => setValues({ ...values, treatment: e.target.value })}
                            minRows={3}
                        />
                        <Button
                            onClick={saveTreatment}
                            variant="contained"
                            color="secondary"
                            fullWidth
                            sx={{ marginTop: 2 }}
                        >
                            {t("save")}
                        </Button>
                    </Paper>
                </Grid>
                <Grid container item xs={6} md={12}>
                    <Card sx={{ width: "100%" }}>
                        <CardContent>
                            <InputControl
                                value={newTreatment?.treatment || ""}
                                onChange={(e) => setNewTreatment({ ...newTreatment, treatment: e.target.value })}
                                label={t("treatment")}
                                multiline
                                minRows={3}
                            />
                            <InputControl
                                value={newTreatment?.next_treatment || ""}
                                onChange={(e) => setNewTreatment({ ...newTreatment, next_treatment: e.target.value })}
                                label={t("next_treatment")}
                                multiline
                                minRows={3}
                            />
                        </CardContent>
                        <CardActions>
                            <Button
                                onClick={() => saveTreatmentOrtho()}
                                variant="contained"
                                color="secondary"
                                fullWidth
                            >
                                {t("add")}
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>

            <Grid item xs={12} marginTop={2}>
                <TreatmentsOrthoTable data={treatmentsOrthoRecords || []} />
            </Grid>
        </Grid>
    );
};

export default PatientOrthoTreatmentPanel;

import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useState } from "react";
import { TableListNoRowsOverlay, TableListToolbar } from "./components";

const Table = ({ onRowClick, onRowsDelete, onCreate, rows = [], columns = [], titleLabel, loading }) => {
    const [selectionModel, setSelectionModel] = useState([]);
    return (
        <Box
            sx={{
                height: "calc(100vh - 100px)",
                width: "100%",
                "& .row-even": {
                    backgroundColor: "rgb(238, 238, 238)",
                },
                "& .MuiDataGrid-columnHeader": {
                    textTransform: "capitalize",
                    fontSize: 16,
                },
            }}
        >
            <DataGrid
                rows={rows}
                columns={columns}
                checkboxSelection
                disableSelectionOnClick
                components={{
                    NoRowsOverlay: TableListNoRowsOverlay,
                    Toolbar: () =>
                        TableListToolbar({
                            titleLabel,
                            onRowsDelete: () => onRowsDelete && onRowsDelete(selectionModel),
                            onCreate,
                            loading,
                        }),
                }}
                selectionModel={selectionModel}
                onSelectionModelChange={setSelectionModel}
                onRowClick={(rowData) => onRowClick && onRowClick(rowData?.row)}
                sx={{
                    borderRadius: 3,
                }}
                getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "row-even" : "row-odd")}
                getRowId={(row) => row._id || row.id}
            />
        </Box>
    );
};

export default Table;

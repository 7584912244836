import { PickersDay } from "@mui/x-date-pickers";
import React from "react";

const CalendarDay = ({ day, props = {}, startDate = null, endDate = null, setHoverdDay, hoverDay }) => {
    const areValidDays = Boolean(startDate && endDate);
    const isBetween = areValidDays && day > startDate && day < endDate;
    const isStartDay = Boolean(startDate) && day.hasSame(startDate, "day");
    const isEndDay = areValidDays && day.hasSame(endDate, "day");

    const isInsideHover = hoverDay && Boolean(startDate) && !Boolean(endDate) && day > startDate && day <= hoverDay;

    return (
        <PickersDay
            onMouseOver={() => setHoverdDay(day)}
            style={{
                backgroundColor:
                    isStartDay || isEndDay ? "#2196F3" : isBetween ? "rgb(33, 150, 243,0.7)" : "transparent",
                ...((isStartDay || isEndDay || isBetween) && { color: "white", fontWeight: "bold" }),
                ...(isInsideHover && { backgroundColor: "rgb(33, 150, 243,0.7)" }),
                borderRadius: 0,
                fontSize: 14,
            }}
            {...props}
        />
    );
};

export default CalendarDay;

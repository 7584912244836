import { Accordion, AccordionDetails, AccordionSummary, CardHeader, Grid, Typography } from "@mui/material";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useContext } from "react";
import PatientDataCard from "./PatientDataCard";
import { PatientDataContext } from "./PatientDataContext";
import { useTranslation } from "react-i18next";

const PatientDataPanel = () => {
    const { patientData, extraData } = useContext(PatientDataContext);
    const { t } = useTranslation();
    return (
        <div>
            <PatientDataCard />
            <Accordion sx={{ backgroundColor: "transparent" }} defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <CardHeader
                        sx={{ margin: 0, padding: 0 }}
                        avatar={<FolderSharedIcon />}
                        title={"Anamneses"}
                        titleTypographyProps={{ variant: "body" }}
                    />
                </AccordionSummary>
                <AccordionDetails>
                    {patientData?.anamneses?.map((question, i) => {
                        if (question?.answer?.includes("#")) {
                            const answer1 = question.answer.split("#")?.[0];
                            const answer2 = question.answer.split("#")?.[1];
                            question.answer = t(answer1) + ". " + answer2;
                        }
                        console.log(extraData?.anamneses);
                        const { name } = extraData?.anamneses?.find((a) => a._id === question?.question) || {};
                        return (
                            <>
                                <Typography marginTop={2}>
                                    {i + 1}. {name}
                                </Typography>
                                <Typography color="primary">{question.answer}</Typography>
                            </>
                        );
                    })}
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export default PatientDataPanel;

import { Checkbox, FormControlLabel } from "@mui/material";
import React from "react";

const CheckboxControl = ({ value, label, color, name, size = "medium", labelPlacement = "end", ...otherProps }) => {
    return (
        <FormControlLabel
            label={label}
            labelPlacement={labelPlacement}
            control={<Checkbox color={color} checked={value} size={size} {...otherProps} />}
        />
    );
};

export default CheckboxControl;

import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { AutoCompleteControl, InputControl, SwitchControl } from "../../components/form";
import { Modal } from "../../components/modal";
import { useApi } from "../../components/hooks";
import { useSnackbar } from "notistack";
import currency from "../../assets/currency";
import { useTranslation } from "react-i18next";

const currenciesOptions = currency.map((option) => option.CtryNm);
const rules = {
    name: "string|required",
    code: "string|required",
    rate: "required",
};

const CurrencyForm = () => {
    const [values, setValues] = useState({});
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const params = useParams();
    const navigate = useNavigate();

    const { t } = useTranslation();
    const { addCurrency } = useOutletContext() || {};

    useEffect(() => {
        if (params?.id !== "create") loadData();
    }, []);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "currency",
            data: {
                _id: "currencies_" + params?.id,
            },
            responseData: "_id name rate code is_default is_active country",
        });
        if (response?.currency) setValues(response.currency);
    };
    const saveHandler = async () => {
        const { name, rate, code, is_default, is_active, country, _id } = values;
        const data = {
            name,
            rate: parseFloat(rate),
            code,
            is_default,
            is_active,
            country,
        };
        if (_id) data._id = _id;
        const response = await fetch({
            operation: "mutation",
            endpoint: _id ? "updateCurrency" : "createCurrency",
            data,
            responseData: "_id name rate code is_default is_active",
        });
        if (response?.createCurrency?._id || response?.updateCurrency?._id) {
            enqueueSnackbar(t("currency_saved"), { variant: "default" });
            is_default && localStorage.setItem("currency", code);

            addCurrency && addCurrency(_id ? response.updateCurrency : response.createCurrency);

            navigate(-1);
        }
    };
    const handleCountryChange = (event) => {
        const country = event?.target?.value;
        const countryData = currency.find((c) => c.CtryNm === country);
        const { CcyNm, Ccy, CtryNm } = countryData || {};
        setValues({
            ...values,
            country: CtryNm,
            name: CcyNm,
            code: Ccy,
        });
    };

    return (
        <Modal
            loading={loading}
            titlelabel={t("currency_form")}
            maxWidth="xs"
            open={true}
            onClose={() => navigate(-1)}
            FormProps={{
                values,
                onValuesChange: setValues,
                onSubmit: saveHandler,
                rules,
            }}
            permissions={{ currencies: values?._id ? "update" : "create" }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <AutoCompleteControl
                        name="country"
                        label={t("country")}
                        options={currenciesOptions}
                        onChange={handleCountryChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputControl name="code" label={t("code")} />
                </Grid>
                <Grid item xs={12}>
                    <InputControl name="name" label={t("name")} />
                </Grid>
                <Grid item xs={12}>
                    <InputControl name="rate" label={t("rate")} type="number" />
                </Grid>
                <Grid item xs={6}>
                    <SwitchControl name="is_active" label={t("active")} />
                </Grid>
                <Grid item xs={6}>
                    <SwitchControl name="is_default" label={t("is_default")} />
                </Grid>
            </Grid>
        </Modal>
    );
};

export default CurrencyForm;

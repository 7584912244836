import { Drawer } from "@mui/material";
import React from "react";

const GlassEffectDrawer = ({ open, onClose, children, PaperProps: { sx = {}, ...otherPaperProps }, ...rest }) => {
    return (
        <Drawer
            PaperProps={{
                sx: {
                    backgroundColor: "rgba(255,255,255,0.85)",
                    backdropFilter: "blur(5px)",
                    WebkitBackdropFilter: "blur(5px)",
                    ...sx,
                },
                variant: "elevation",
                elevation: 4,
                ...otherPaperProps,
            }}
            slotProps={{
                backdrop: {
                    sx: {
                        backgroundColor: "transparent",
                    },
                },
            }}
            anchor="left"
            open={open}
            onClose={onClose}
            {...rest}
        >
            {children}
        </Drawer>
    );
};

export default GlassEffectDrawer;

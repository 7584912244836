import { Button, Divider, Grid, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Modal } from "../../components/modal";
import { InputControl, SelectControl, SwitchControl } from "../../components/form";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useApi } from "../../components/hooks";
import { useTranslation } from "react-i18next";
import { ProtectedAccessAction } from "../../components/common";

const rulesForCreate = {
    name: "string|required",
    username: "string|required",
    password: "string|required",
    passwordConfirm: "string|required",
    role_id: "string|required",
};
const rulesForUpdate = {
    name: "string|required",
    username: "string|required",
    role_id: "string|required",
};
const AccountForm = () => {
    const [values, setValues] = useState({});
    const [roles, setRoles] = useState([]);
    const [activeTab, setActiveTab] = useState(0);

    const navigate = useNavigate();
    const params = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const { loading, fetch } = useApi();
    const { t } = useTranslation();
    const { addUser } = useOutletContext() || {};

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        const endpoints = [
            {
                operation: "query",
                endpoint: "user",
                data: {
                    _id: "users_" + params?.id,
                },
                responseData: "_id name is_active title username role_id email color",
            },
            {
                endpoint: "roles",
                responseData: "_id name",
            },
        ];
        const response = await fetch({
            operation: "query",
            multipleEndpoints: endpoints,
        });
        if (response?.roles) setRoles(response.roles.map((role) => ({ value: role._id, label: role.name })));
        if (response?.user) setValues(response.user);
    };

    const saveHandler = async () => {
        const { password, passwordConfirm, _id, name, title, username, role_id, color, email, is_active } = values;
        if (password !== passwordConfirm) {
            enqueueSnackbar(t("passwords_not_matching"), { variant: "warning" });
            return;
        }
        const data = { name, title, username, role_id, color, email, is_active };
        if (_id) data._id = _id;
        if (!_id) data.password = password;

        const response = await fetch({
            operation: "mutation",
            endpoint: _id ? "updateUser" : "createUser",
            data,
            responseData: "_id name title username color email role_name",
        });
        if (response?.createUser?._id || response?.updateUser?._id) {
            enqueueSnackbar(t("user_saved"), { variant: "default" });

            addUser && addUser(_id ? response.updateUser : response.createUser);

            navigate(-1);
        }
    };

    const updatePassword = async () => {
        const { oldPassword, newPassword, confirmPassword, username } = values;
        if (!oldPassword || !newPassword || !confirmPassword) {
            enqueueSnackbar(t("password_inputs_missing"), { variant: "warning" });
            return;
        }
        if (newPassword !== confirmPassword) {
            enqueueSnackbar(t("passwords_must_match"), { variant: "warning" });
            return;
        }
        const response = await fetch({
            operation: "mutation",
            endpoint: "changePassword",
            data: { oldPassword, newPassword, username },
            responseData: "_id",
        });
        if (response?.changePassword?._id) {
            enqueueSnackbar(t("password_changed"), { variant: "default" });
            navigate(-1);
        }
    };

    return (
        <Modal
            open
            maxWidth="md"
            titlelabel={t("user")}
            onClose={() => navigate(-1)}
            FormProps={{
                values,
                onValuesChange: setValues,
                onSubmit: saveHandler,
                rules: values?._id ? rulesForUpdate : rulesForCreate,
            }}
            loading={loading}
            permissions={{ users: values?._id ? "update" : "create" }}
            allowMe
            userId={values?._id}
        >
            {values?._id && (
                <>
                    <Tabs
                        value={activeTab}
                        onChange={(e, newTab) => setActiveTab(newTab)}
                        indicatorColor="primary"
                        textColor="primary"
                    >
                        <Tab label={t("user")} />
                        <Tab label={t("security")} />
                    </Tabs>
                    <Divider sx={{ marginBottom: 1 }} />
                </>
            )}

            {activeTab === 0 ? (
                <Grid container spacing={2}>
                    <Grid item xs={8} sm={6}>
                        <InputControl name="name" label={t("name")} />
                    </Grid>
                    <Grid item xs={4} sm={6}>
                        <InputControl name="title" label={t("title")} />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                        <InputControl name="username" label={t("username")} />
                    </Grid>
                    {!values?._id && (
                        <>
                            <Grid item xs={6} sm={4}>
                                <InputControl name="password" label={t("password")} />
                            </Grid>
                            <Grid item xs={6} sm={4}>
                                <InputControl name="passwordConfirm" label={t("password_confirm")} />
                            </Grid>
                        </>
                    )}
                    <ProtectedAccessAction permissions={{ users: values?._id ? "update" : "create" }}>
                        <Grid item xs={6} sm={4}>
                            <SelectControl options={roles} name="role_id" label={t("role")} />
                        </Grid>
                    </ProtectedAccessAction>
                    <Grid item xs={6} sm={4}>
                        <InputControl name="color" type="color" label={t("color")} />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                        <InputControl name="email" label={t("email")} />
                    </Grid>
                    <ProtectedAccessAction permissions={{ users: values?._id ? "update" : "create" }}>
                        <Grid item xs={12}>
                            <SwitchControl name="is_active" label={t("active")} />
                        </Grid>
                    </ProtectedAccessAction>
                </Grid>
            ) : (
                <Grid item container spacing={2} xs={12} sm={6}>
                    <Grid item xs={12}>
                        <InputControl name={t("oldPassword")} label={t("old_password")} />
                    </Grid>
                    <Grid item xs={12}>
                        <InputControl name={t("newPassword")} label={t("new_password")} />
                    </Grid>
                    <Grid item xs={12}>
                        <InputControl name={t("confirmPassword")} label={t("confirm_new_password")} />
                    </Grid>
                    <Grid item xs={12}>
                        <Button onClick={updatePassword} variant="contained">
                            {t("change_password")}
                        </Button>
                    </Grid>
                </Grid>
            )}
        </Modal>
    );
};

export default AccountForm;

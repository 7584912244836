import { Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { InputControl, SwitchControl } from "../../components/form";
import { useApi } from "../../components/hooks";
import { Modal } from "../../components/modal";

const rules = {
    name: "string|required",
};
const DiagnoseForm = () => {
    const [values, setValues] = useState({});
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const params = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { addDiagnose } = useOutletContext() || {};

    useEffect(() => {
        if (params?.id !== "create") loadData();
    }, []);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "diagnose",
            data: {
                _id: "diagnoses_" + params?.id,
            },
            responseData: "_id name code note is_active",
        });
        if (response?.diagnose) setValues(response.diagnose);
    };
    const saveHandler = async () => {
        const { name, code, note, is_active, _id } = values;
        const data = {
            name,
            code,
            note,
            is_active,
        };
        if (_id) data._id = _id;
        const response = await fetch({
            operation: "mutation",
            endpoint: _id ? "updateDiagnose" : "createDiagnose",
            data,
            responseData: "_id name code is_active",
        });
        if (response?.createDiagnose?._id || response?.updateDiagnose?._id) {
            enqueueSnackbar(t("diagnose_saved"), { variant: "default" });
            addDiagnose && addDiagnose(_id ? response.updateDiagnose : response.createDiagnose);

            navigate(-1);
        }
    };
    return (
        <Modal
            loading={loading}
            titlelabel={t("diagnose_form")}
            maxWidth="xs"
            open={true}
            onClose={() => navigate(-1)}
            FormProps={{
                values,
                onValuesChange: setValues,
                onSubmit: saveHandler,
                rules,
            }}
            permissions={{ diagnoses: values?._id ? "update" : "create" }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <InputControl name="code" label={t("code")} />
                </Grid>
                <Grid item xs={12}>
                    <InputControl name="name" label={t("name")} />
                </Grid>

                <Grid item xs={12}>
                    <InputControl name="note" label={t("note")} multiline minRows={2} />
                </Grid>
                <Grid item xs={12}>
                    <SwitchControl name="is_active" label={t("active")} />
                </Grid>
            </Grid>
        </Modal>
    );
};

export default DiagnoseForm;

import { Switch, FormControlLabel } from "@mui/material";
import React from "react";

const SwitchControl = ({ value, label, name, labelPlacement = "end", color, formProps = {}, ...otherProps }) => {
    return (
        <FormControlLabel
            label={label}
            labelPlacement={labelPlacement}
            control={<Switch color={color} checked={value} size={"medium"} {...otherProps} />}
            {...formProps}
        />
    );
};

export default SwitchControl;

import { ListItem, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const languageDetails = {
    en: {
        flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/Flag_of_the_United_Kingdom_%283-5%29.svg/350px-Flag_of_the_United_Kingdom_%283-5%29.svg.png",
        label: "english",
    },
    sq: {
        flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/Flag_of_Albania.svg/476px-Flag_of_Albania.svg.png",
        label: "albanian",
    },
};

const LanguageMenu = ({ containerStyle }) => {
    const [anchorMenu, setAnchorMenu] = useState(null);
    const { t, i18n } = useTranslation();

    const handleLangMenuClose = () => {
        setAnchorMenu(null);
    };
    const onLanguageClick = (lang) => {
        i18n.changeLanguage(lang);
    };
    return (
        <>
            <ListItem
                onClick={(e) => setAnchorMenu(e.currentTarget)}
                aria-owns={anchorMenu ? "lang-menu" : null}
                style={containerStyle}
            >
                <ListItemIcon>
                    <img src={languageDetails[i18n.language].flag} alt="flag-image" width={30} />
                </ListItemIcon>
                <ListItemText sx={{ color: "secondary.main" }}>{t(languageDetails[i18n.language].label)}</ListItemText>
            </ListItem>
            <Menu id="lang-menu" anchorEl={anchorMenu} open={Boolean(anchorMenu)} onClose={handleLangMenuClose}>
                {Object.entries(languageDetails).map(([languageId, details]) => (
                    <MenuItem
                        onClick={() => {
                            onLanguageClick(languageId);
                            handleLangMenuClose();
                        }}
                    >
                        <ListItemIcon>
                            <img src={details.flag} alt="flag-image" width={30} />
                        </ListItemIcon>
                        <ListItemText>{t(details.label)}</ListItemText>
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

export default LanguageMenu;

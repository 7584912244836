import { Backdrop, Button, Card, CardActions, CardHeader } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import LogoutIcon from "@mui/icons-material/Logout";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NoAccessBackdrop = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    return (
        <Backdrop open sx={{ backdropFilter: "blur(5px)", backgroundColor: "rgb(255,255,255,0.5)", zIndex: 9999 }}>
            <Card sx={{ width: 400, padding: 2 }}>
                <CardHeader
                    title={t("access_denied")}
                    titleTypographyProps={{
                        variant: "h5",
                        color: "error",
                        textAlign: "center",
                        textTransform: "capitalize",
                    }}
                />
                <CardActions>
                    <Button
                        onClick={() => navigate(-1)}
                        size="large"
                        fullWidth
                        variant="contained"
                        startIcon={<KeyboardBackspaceIcon />}
                    >
                        {t("go_back")}
                    </Button>
                    <Button
                        onClick={() => navigate("/login")}
                        size="large"
                        fullWidth
                        variant="outlined"
                        endIcon={<LogoutIcon />}
                    >
                        {t("logout")}
                    </Button>
                </CardActions>
            </Card>
        </Backdrop>
    );
};

export default NoAccessBackdrop;

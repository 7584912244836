import {
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Button,
    IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";
import { t } from "i18next";

const EditableTable = ({ columns = [], data = [], onAddRow, onRowDelete, variant = "elevation" }) => {
    return (
        <TableContainer component={Paper} elevation={1} variant={variant}>
            <Table>
                <TableHead>
                    <TableRow sx={{ display: "flex" }}>
                        {columns.map((col, index) => (
                            <TableCell
                                align={"left"}
                                sx={{
                                    textTransform: "capitalize",
                                    fontSize: 16,
                                    width: "100%",
                                    paddingY: 1,
                                    fontWeight: 600,
                                }}
                            >
                                {t(col.headerName)}
                            </TableCell>
                        ))}
                        {onRowDelete && <TableCell></TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row, rowIndex) => {
                        return (
                            <TableRow sx={{ display: "flex" }}>
                                {columns.map((column, columnIndex) => (
                                    <TableCell align={"left"} sx={{ width: "100%", paddingY: 1, border: "none" }}>
                                        {column.cell && column.cell({ row, rowIndex, column, columnIndex })}
                                    </TableCell>
                                ))}
                                {onRowDelete && (
                                    <TableCell sx={{ border: "none" }}>
                                        <IconButton onClick={() => onRowDelete(rowIndex)}>
                                            <DeleteIcon color="primary" />
                                        </IconButton>
                                    </TableCell>
                                )}
                            </TableRow>
                        );
                    })}
                    {onAddRow && (
                        <TableRow sx={{ display: "flex" }}>
                            <Button fullWidth onClick={() => onAddRow && onAddRow()}>
                                {t("add_row")}
                            </Button>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default EditableTable;

import { Divider, Grid, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { InputControl, RadioGroupControl } from "../../components/form";
import { Modal } from "../../components/modal";
import { DateControl } from "../../components/dates";
import { useApi } from "../../components/hooks";
import { Anamnese } from "./components";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

const rules = {
    firstname: "string|required",
    lastname: "string|required",
    phone: "string|required",
};

const NewPatientForm = () => {
    const { t } = useTranslation();
    const genderOptions = [
        { value: "male", label: t("male") },
        { value: "female", label: t("female") },
    ];
    const bloodTypeOptions = [
        { value: "0_positive", label: t("0_rh_positive") },
        { value: "0_negative", label: t("0_rh_negative") },
        { value: "A_positive", label: t("a_rh_positive") },
        { value: "A_negative", label: t("a_rh_negative") },
        { value: "B_positive", label: t("b_rh_positive") },
        { value: "B_negative", label: t("b_rh_negative") },
        { value: "AB_positive", label: t("ab_rh_positive") },
        { value: "AB_negative", label: t("ab_rh_negative") },
        { value: "unknown", label: t("unknown") },
    ];
    const [view, setView] = useState(0);
    const [anamnesesList, setAnamnesesList] = useState([]);
    const [patientData, setPatientData] = useState({});

    const { loading, fetch } = useApi();
    const params = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { addPatient } = useOutletContext() || {};

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        const endpoints = [
            {
                endpoint: "anamneses",
                data: { activeOnly: true },
                responseData: `_id name subquestion 
            note is_active has_subquestion is_yes_or_no has_options options`,
            },
        ];
        if (params.id !== "create") {
            endpoints.push({
                endpoint: "patient",
                data: { _id: "patients_" + params.id },
                responseData: `_id firstname lastname birthday gender address city phone email number_id 
                profession blood_group allergy note anamneses{question answer}`,
            });
        }
        const response = await fetch({
            operation: "query",
            multipleEndpoints: endpoints,
        });
        if (response?.anamneses) setAnamnesesList(response.anamneses?.reverse());
        if (response?.patient) setPatientData(response.patient);
    };

    const saveHandler = async () => {
        const data = {
            firstname: patientData.firstname,
            lastname: patientData.lastname,
            birthday: patientData.birthday,
            gender: patientData.gender,
            address: patientData.address,
            city: patientData.city,
            phone: patientData.phone,
            email: patientData.email,
            number_id: patientData.number_id,
            profession: patientData.profession,
            blood_group: patientData.blood_group,
            allergy: patientData.allergy,
            note: patientData.note,
            anamneses: patientData?.anamneses?.map((question) => {
                if (typeof question.answer === "string") return question;
                if (Array.isArray(question.answer)) {
                    question.answer = question.answer.join(" , ");
                    return question;
                }
                return { question, answer: "" };
            }),
        };
        if (params?.id !== "create") data._id = "patients_" + params.id;

        const response = await fetch({
            operation: "mutation",
            endpoint: params?.id === "create" ? "createPatient" : "updatePatient",
            data,
            responseData: "_id firstname lastname blood_group phone email gender",
        });
        if (response?.updatePatient?._id || response?.createPatient?._id) {
            enqueueSnackbar(t("patient_saved"), { variant: "default" });

            if (addPatient) addPatient(params?.id === "create" ? response.createPatient : response.updatePatient);

            navigate(-1);
        }
    };

    const anamnesesQuestionHandler = (_id, value) => {
        if (!patientData?.anamneses) {
            setPatientData({ ...patientData, anamneses: [{ question: _id, answer: value }] });
            return;
        }
        if (patientData?.anamneses?.some((a) => a.question === _id)) {
            setPatientData({
                ...patientData,
                anamneses: patientData.anamneses.map((a) => {
                    if (a.question === _id) {
                        a.answer = value;
                    }
                    return a;
                }),
            });
            return;
        }
        setPatientData({ ...patientData, anamneses: [...patientData.anamneses, { question: _id, answer: value }] });
    };
    return (
        <Modal
            open
            titlelabel={t("patient")}
            maxWidth="md"
            FormProps={{ values: patientData, onValuesChange: setPatientData, onSubmit: saveHandler, rules }}
            onClose={() => navigate(-1)}
            loading={loading}
            permissions={{ patients: patientData?._id ? "update" : "create" }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Tabs value={view} onChange={(e, newView) => setView(newView)}>
                        <Tab label={t("patient")} />
                        <Tab label={t("anamneses")} />
                    </Tabs>
                    <Divider />
                </Grid>
                {view === 0 ? (
                    <>
                        <Grid item xs={6} sm={4}>
                            <InputControl name="firstname" label={t("name")} />
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <InputControl name="lastname" label={t("lastname")} />
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <DateControl name="birthday" label={t("birthday")} />
                        </Grid>
                        <Grid item xs={6}>
                            <InputControl name="number_id" label={t("id_number")} />
                        </Grid>
                        <Grid item xs={6}>
                            <InputControl name="profession" label={t("profession")} />
                        </Grid>
                        <Grid item xs={12}>
                            <RadioGroupControl name="gender" options={genderOptions} label={t("gender")} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6">{t("address_and_contact_information")}</Typography>
                            <Divider />
                        </Grid>
                        <Grid item xs={6}>
                            <InputControl name="address" label={t("address")} />
                        </Grid>
                        <Grid item xs={6}>
                            <InputControl name="city" label={t("city")} />
                        </Grid>
                        <Grid item xs={6}>
                            <InputControl name="phone" label={t("phone")} />
                        </Grid>
                        <Grid item xs={6}>
                            <InputControl name="email" label={t("email")} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6">{t("medical_information")}</Typography>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <RadioGroupControl options={bloodTypeOptions} name="blood_group" label={t("blood_group")} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputControl multiline minRows={2} name="allergy" label={t("allergy")} />
                        </Grid>

                        <Grid item xs={12}>
                            <InputControl name="note" label={t("note")} multiline minRows={4} />
                        </Grid>
                    </>
                ) : (
                    anamnesesList.map((a) => (
                        <Anamnese
                            value={patientData?.anamneses?.find((q) => q.question === a._id)?.answer}
                            onChange={(value) => anamnesesQuestionHandler(a._id, value)}
                            anamneseData={a}
                        />
                    ))
                )}
            </Grid>
        </Modal>
    );
};

export default NewPatientForm;

import React, { useState } from "react";
import validate from "./validate";

const recursiveMap = (children, callback) => {
    return React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) {
            return child;
        }

        if (child.props.children) {
            child = React.cloneElement(child, {
                children: recursiveMap(child.props.children, callback),
            });
        }

        return callback(child);
    });
};

const Form = ({ values, onValuesChange, rules, children, onSubmit, ...otherProps }) => {
    const [formErrors, setFormErrors] = useState({});

    const handleValidation = () => {
        const { errors } = validate(values, rules);
        if (errors) {
            setFormErrors(errors);
            return errors;
        }
        setFormErrors({});
        return errors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!rules) {
            onSubmit && onSubmit();
            return;
        }
        const errors = handleValidation();
        if (!errors) {
            onSubmit && onSubmit();
            return;
        }
    };

    const clonedChild = (child) =>
        React.cloneElement(child, {
            value: values[child.props.name] || "",
            error: Boolean(formErrors[child.props.name]),
            helperText: formErrors[child.props.name] && formErrors[child.props.name][0],
            onChange: (event) => {
                onValuesChange &&
                    onValuesChange({
                        ...values,
                        [child.props.name]:
                            event?.target?.type === "checkbox" ? event.target.checked : event.target.value,
                    });
                child.props.onChange && child.props.onChange(event);
            },
            // onBlur: handleValidation,
        });

    return (
        <form onSubmit={handleSubmit} {...otherProps}>
            {values && onValuesChange
                ? recursiveMap(children, (child) => (child.props.name ? clonedChild(child) : child))
                : children}
        </form>
    );
};

export default Form;

import { Button, Stack, useMediaQuery } from "@mui/material";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";
import { useApi } from "../../components/hooks";
import { Table } from "../../components/table";
import { DateRangeControl } from "../../components/dates";

const PaymentList = () => {
    const { t } = useTranslation();
    const isLargeScreen = useMediaQuery("(min-width:800px)");
    const columns = [
        {
            field: "created_at",
            headerName: t("created_at"),
            valueGetter: ({ value }) => DateTime.fromISO(value).toFormat("LLL dd HH:mm"),
            flex: isLargeScreen && 1,
        },
        {
            field: "username",
            headerName: t("doctor"),
            flex: isLargeScreen && 1,
        },
        {
            field: "fullName",
            headerName: t("patient"),
            valueGetter: (params) => `${params.row.patient_name || ""} ${params.row.patient_lastname || ""}`,
            flex: isLargeScreen && 1,
        },
        {
            field: "paymentmethod_name",
            headerName: t("payment_method"),
            flex: isLargeScreen && 1,
        },
        {
            field: "amount",
            headerName: t("amount"),
            flex: isLargeScreen && 1,
        },
    ];
    const [payments, setPayments] = useState([]);
    const [dates, setDates] = useState({
        startDate: null,
        endDate: null,
    });
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "lastPayments",
            data: { number: 100 },
            responseData: "_id username paymentmethod_name amount created_at patient_name patient_lastname",
        });
        if (response?.lastPayments) setPayments(response.lastPayments);
    };

    const loadDataByDates = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "payments",
            data: dates,
            responseData: "_id username paymentmethod_name amount created_at patient_name patient_lastname",
        });
        if (response?.payments) setPayments(response.payments);
    };

    const onDeleteHandler = async (rows = []) => {
        if (rows.length === 0) return;
        const data = {};
        if (rows.length > 1) data._ids = rows;
        else data._id = rows[0];
        const response = await fetch({
            operation: "mutation",
            endpoint: rows.length > 1 ? "deletePayments" : "deletePayment",
            data,
            responseData: "_id",
        });
        if (response?.deletePayments || response?.deletePayment?._id) {
            setPayments(payments.filter((payment) => !rows.some((row) => row === payment._id)));
            enqueueSnackbar(t("deleted_with_success"), { variant: "info" });
        }
    };

    const addPayment = (paymentData) => {
        if (payments?.some((p) => p._id === paymentData._id)) {
            setPayments(
                payments?.map((p) => {
                    if (p._id === paymentData?._id) return paymentData;
                    return p;
                })
            );
            return;
        }
        setPayments([...payments, paymentData]);
    };

    return (
        <>
            <Stack direction="row" gap={2} flexWrap="wrap" alignItems={"center"} marginBottom={2}>
                <DateRangeControl
                    startLabel={t("start_date")}
                    endLabel={t("end_date")}
                    values={dates}
                    onChange={setDates}
                />
                <Button disabled={!dates.startDate || !dates.endDate} variant="contained" onClick={loadDataByDates}>
                    {t("load")}
                </Button>
                <Button variant="outlined" onClick={loadData}>
                    {t("last_100")}
                </Button>
            </Stack>
            <Table
                rows={payments}
                columns={columns}
                onRowClick={(row) => navigate(`${row?._id?.split("payments_")[1]}`)}
                onCreate={() => navigate("create")}
                onRowsDelete={onDeleteHandler}
                titleLabel={t("payments")}
                loading={loading}
            />
            <Outlet context={{ addPayment }} />
        </>
    );
};

export default PaymentList;

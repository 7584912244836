import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { AutoCompleteControl, InputControl } from "../form";
import { EditableTable } from "../table";

const CurrenciesTotalTable = ({ currencies = [], data = [], onChange, total = 0 }) => {
    const { t } = useTranslation();
    const columns = [
        {
            headerName: "currency",
            cell: ({ row, rowIndex }) => (
                <AutoCompleteControl
                    value={row?.currency || ""}
                    onChange={(e) => {
                        onChange(
                            data.map((el, i) => {
                                if (i === rowIndex) {
                                    const { rate } = currencies?.find((c) => c.value === e.target.value) || {};
                                    el.currency = e.target.value;
                                    el.rate = rate;
                                }
                                return el;
                            })
                        );
                    }}
                    options={currencies}
                    label={t("currency")}
                />
            ),
        },
        {
            headerName: "rate",
            cell: ({ row, rowIndex }) => (
                <InputControl
                    value={row?.rate || ""}
                    onChange={(e) => {
                        onChange(
                            data.map((el, i) => {
                                if (i === rowIndex) el.rate = e.target.value;
                                return el;
                            })
                        );
                    }}
                    label={t("rate")}
                />
            ),
        },
        {
            headerName: "total",
            cell: ({ row, rowIndex }) => {
                const convertedTotal = parseFloat(total) * parseFloat(row?.rate);
                if (isNaN(convertedTotal)) return "";
                return (
                    <Typography marginTop="15px">
                        {convertedTotal.toFixed(2)} {row?.currency}
                    </Typography>
                );
            },
        },
    ];

    return (
        <EditableTable
            columns={columns}
            data={data}
            onAddRow={() => onChange([...data, {}])}
            onRowDelete={(index) => onChange(data.filter((e, i) => i !== index))}
            variant="outlined"
        />
    );
};

export default CurrenciesTotalTable;

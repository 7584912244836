import { Button, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useState } from "react";
import { Modal } from "../modal";
import { useTranslation } from "react-i18next";

const DeleteButtonWithConfirm = ({ onClick, color = "primaryLight" }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const { t } = useTranslation();
    return (
        <>
            <Button onClick={() => setOpenDialog(true)} sx={{ marginLeft: "auto" }} variant="contained" color={color}>
                <DeleteIcon />
            </Button>
            <Modal
                open={openDialog}
                yesText={t("confirm")}
                onClose={() => setOpenDialog(false)}
                onSave={() => {
                    setOpenDialog(false);
                    onClick && onClick();
                }}
            >
                <Typography variant="h6" textAlign="center" color="error">
                    {t("are_you_sure_you_want_to_delete")}?
                </Typography>
            </Modal>
        </>
    );
};

export default DeleteButtonWithConfirm;

import { Paper, Grid, ListItem, ListItemText, ListItemIcon } from "@mui/material";
import TodayIcon from "@mui/icons-material/Today";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import BusinessIcon from "@mui/icons-material/Business";
import BloodtypeIcon from "@mui/icons-material/Bloodtype";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import React, { useContext } from "react";
import { PatientDataContext } from "./PatientDataContext";

const PatientDataCard = () => {
    const { patientData } = useContext(PatientDataContext);
    return (
        <Paper sx={{ backgroundColor: "transparent" }}>
            <Grid container>
                <Grid item xs={6} sm={4} md={3}>
                    <DetailRow icon={<PeopleAltIcon />} header="Gender" value={patientData.gender} />
                </Grid>
                <Grid item xs={6} sm={4} md={3}>
                    <DetailRow icon={<TodayIcon />} header="Birthday" value={patientData.birthday} />
                </Grid>
                <Grid item xs={6} sm={4} md={3}>
                    <DetailRow icon={<PhoneIcon />} header="Phone" value={patientData.phone} />
                </Grid>
                <Grid item xs={6} sm={4} md={3}>
                    <DetailRow icon={<EmailIcon />} header="Email" value={patientData.email} />
                </Grid>
                <Grid item xs={6} sm={4} md={3}>
                    <DetailRow icon={<LocationCityIcon />} header="City" value={patientData.city} />
                </Grid>
                <Grid item xs={6} sm={4} md={3}>
                    <DetailRow icon={<BusinessIcon />} header="Adress" value={patientData.address} />
                </Grid>
                <Grid item xs={6} sm={4} md={3}>
                    <DetailRow icon={<BloodtypeIcon />} header="Blood Group" value={patientData.blood_group} />
                </Grid>
            </Grid>
        </Paper>
    );
};

export default PatientDataCard;

const DetailRow = ({ header, value, icon }) => {
    return (
        <ListItem>
            {icon && <ListItemIcon>{icon}</ListItemIcon>}
            <ListItemText
                primaryTypographyProps={{ variant: "body2" }}
                primary={header}
                secondary={value || "--"}
                secondaryTypographyProps={{ variant: "body1", color: "primary" }}
            />
        </ListItem>
    );
};

import { Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { AutoCompleteControl, InputControl, SwitchControl } from "../../components/form";
import { useApi } from "../../components/hooks";
import { Modal } from "../../components/modal";

const rules = {
    name: "string|required",
};
const AnamneseForm = () => {
    const [values, setValues] = useState({
        is_active: true,
    });
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const params = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { addAnamnese } = useOutletContext() || {};

    useEffect(() => {
        if (params?.id !== "create") loadData();
    }, []);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "anamnese",
            data: {
                _id: "anamneses_" + params?.id,
            },
            responseData: "_id name subquestion options note is_active has_subquestion is_yes_or_no has_options",
        });
        if (response?.anamnese) setValues(response.anamnese);
    };
    const saveHandler = async () => {
        const { name, subquestion, note, is_active, has_subquestion, is_yes_or_no, _id, options, has_options } = values;
        const data = {
            name,
            subquestion,
            note,
            is_active,
            has_subquestion,
            is_yes_or_no,
            options,
            has_options,
        };
        if (_id) data._id = _id;
        const response = await fetch({
            operation: "mutation",
            endpoint: _id ? "updateAnamnese" : "createAnamnese",
            data,
            responseData: "_id name is_active has_subquestion is_yes_or_no",
        });
        if (response?.createAnamnese?._id || response?.updateAnamnese?._id) {
            enqueueSnackbar(t("anamnese_saved"), { variant: "default" });
            addAnamnese && addAnamnese(_id ? response.updateAnamnese : response.createAnamnese);

            navigate(-1);
        }
    };
    return (
        <Modal
            titlelabel={t("anamnese_form")}
            maxWidth="xs"
            open={true}
            onClose={() => navigate(-1)}
            FormProps={{
                values,
                onValuesChange: setValues,
                onSubmit: saveHandler,
                rules,
            }}
            loading={loading}
            permissions={{ anamneses: values?._id ? "update" : "create" }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <InputControl name="name" label={t("name")} />
                </Grid>
                <Grid item xs={6}>
                    <SwitchControl name="is_active" label={t("active")} />
                </Grid>
                <Grid item xs={6}>
                    <SwitchControl name="is_yes_or_no" label={t("yes_or_no")} />
                </Grid>
                <Grid item xs={12}>
                    <SwitchControl name="has_subquestion" label={t("has_subquestion")} />
                </Grid>
                {values.has_subquestion && (
                    <Grid item xs={12}>
                        <InputControl name="subquestion" label={t("subquestion")} multiline minRows={2} />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <SwitchControl name="has_options" label={t("has_options")} />
                </Grid>
                {values.has_options && (
                    <Grid item xs={12}>
                        <AutoCompleteControl name="options" freeSolo multiple label={t("options")} />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <InputControl name="note" label={t("note")} multiline minRows={2} />
                </Grid>
            </Grid>
        </Modal>
    );
};

export default AnamneseForm;

import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Chip,
    Divider,
    Grid,
    Skeleton,
    Typography,
    useMediaQuery,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AutoCompleteControl } from "../../../components/form";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import { useNavigate } from "react-router-dom";

const TreatmentsCard = ({ treatments = [], doctorOptions = [], loading }) => {
    const isLargeScreen = useMediaQuery("(min-width:600px)");
    const [selectedDoctor, setSelectedDoctor] = useState(null);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const filteredTreatments = useMemo(() => {
        if (!!selectedDoctor) return treatments?.filter((treatment) => treatment.user_id === selectedDoctor);
        return treatments;
    }, [treatments, selectedDoctor]);

    if (loading) return <CardTemplate t={t} />;

    return (
        <Card sx={{ height: "350px", overflowY: "scroll" }}>
            <CardHeader
                avatar={<MedicalServicesIcon />}
                title={t("treatments")}
                titleTypographyProps={{ variant: "h6", fontWeight: 600, textTransform: "capitalize" }}
                action={
                    <Box width={isLargeScreen ? 200 : 130}>
                        <AutoCompleteControl
                            value={selectedDoctor}
                            onChange={(e) => setSelectedDoctor(e.target.value)}
                            options={doctorOptions}
                            label={t("filter_by_doctor")}
                        />
                    </Box>
                }
            />
            {filteredTreatments?.length ? (
                <CardContent>
                    {filteredTreatments?.map((treatment) => (
                        <>
                            <TreatmentsRow
                                doctor={treatment.username}
                                doctorColor={treatment.user_color}
                                name={treatment.service_name}
                                onClick={() =>
                                    navigate(`/patients/view/${treatment?.patient_id?.split("patients_")?.[1]}`)
                                }
                            />
                            <Divider sx={{ marginY: 2 }} />
                        </>
                    ))}
                </CardContent>
            ) : (
                <Typography marginTop={"100px"} textAlign="center">
                    {t("no_treatments_for_today")}
                </Typography>
            )}
        </Card>
    );
};

const TreatmentsRow = ({ doctor, doctorColor, name, onClick }) => {
    return (
        <Grid sx={{ cursor: "pointer" }} container onClick={onClick}>
            <Grid item xs={6}>
                <Chip sx={{ borderColor: doctorColor, color: doctorColor }} variant="outlined" label={doctor} />
            </Grid>
            <Grid item xs={6} textAlign="end">
                {name}
            </Grid>
        </Grid>
    );
};

const CardTemplate = ({ t }) => {
    return (
        <Card sx={{ height: "350px", overflowY: "scroll" }} variant="elevation">
            <CardHeader
                avatar={<MedicalServicesIcon />}
                title={t("treatments")}
                titleTypographyProps={{ variant: "h6", fontWeight: 600, textTransform: "capitalize" }}
            />
            <CardContent>
                <Skeleton height={70} />
                <Skeleton height={70} />
                <Skeleton height={70} />
            </CardContent>
        </Card>
    );
};

export default TreatmentsCard;

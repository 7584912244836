import { Box, Button, Divider, Stack, useMediaQuery } from "@mui/material";
import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import ListIcon from "@mui/icons-material/List";
import MedicalInformationIcon from "@mui/icons-material/MedicalInformation";
import VaccinesIcon from "@mui/icons-material/Vaccines";
import SettingsIcon from "@mui/icons-material/Settings";
import { useTranslation } from "react-i18next";

const settingsRoutes = [
    {
        icon: <SettingsIcon />,
        label: "general_settings",
        href: "/settings",
    },
    {
        icon: <CurrencyExchangeIcon />,
        label: "currencies",
        href: "/settings/currencies",
    },
    {
        icon: <MedicalServicesIcon />,
        label: "services",
        href: "/settings/services",
    },
    {
        icon: <ListIcon />,
        label: "service_categories",
        href: "/settings/servicecategories",
    },
    {
        icon: <MedicalInformationIcon />,
        label: "diagnoses",
        href: "/settings/diagnoses",
    },
    {
        icon: <QuestionMarkIcon />,
        label: "anamneses",
        href: "/settings/anamneses",
    },
    {
        icon: <VaccinesIcon />,
        label: "drugs",
        href: "/settings/drugs",
    },
    {
        icon: <PriceChangeIcon />,
        label: "paymentmethods",
        href: "/settings/paymentmethods",
    },
];

const SettingsView = () => {
    const isLargeScreen = useMediaQuery("(min-width:1000px)");
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { t } = useTranslation();
    return (
        <Box flexDirection={isLargeScreen ? "row" : "column"} display="flex" gap={4}>
            <Box width={isLargeScreen ? 200 : "100%"} minWidth={200}>
                <Stack gap={2} direction={isLargeScreen ? "column" : "row"} flexWrap="wrap">
                    {settingsRoutes.map((route) => (
                        <Button
                            startIcon={route.icon}
                            variant={pathname === route.href ? "contained" : "text"}
                            color="primary"
                            onClick={() => navigate(route.href)}
                            sx={{ justifyContent: "start" }}
                        >
                            {t(route.label)}
                        </Button>
                    ))}
                </Stack>
            </Box>
            {isLargeScreen && (
                <Box>
                    <Divider sx={{ height: "100vh", position: "fixed", top: 0 }} orientation="vertical" />
                </Box>
            )}
            <Box flexGrow={1}>
                <Outlet />
            </Box>
        </Box>
    );
};

export default SettingsView;

import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AutoCompleteControl } from "../form";
import { useApi } from "../hooks";

const ClientSearch = ({ extraOptions = [], patientId, onPatientSelect, ...inputProps }) => {
    const { t } = useTranslation();
    const [patients, setPatients] = useState([]);
    const { loading, fetch } = useApi();

    useEffect(() => {
        if (patientId) loadCurrentPatient();
    }, [patientId]);

    const loadCurrentPatient = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "patient",
            data: {
                _id: patientId,
            },
            responseData: `_id firstname lastname`,
        });
        if (response?.patient?._id)
            setPatients([
                { value: response.patient._id, label: response.patient.firstname + " " + response.patient.lastname },
            ]);
    };

    const loadData = async (text) => {
        const response = await fetch({
            operation: "query",
            endpoint: "searchPatients",
            data: {
                text,
            },
            responseData: `_id firstname lastname`,
        });
        if (response?.searchPatients) {
            setPatients(
                response.searchPatients.map(({ _id, firstname, lastname, ...data }) => {
                    return { value: _id, label: firstname + " " + lastname, data: data };
                })
            );
        }
    };
    return (
        <AutoCompleteControl
            value={patientId}
            options={patients.concat(extraOptions)}
            loading={loading}
            onInputChange={(e, text) => {
                if (!!text && text.length === 3) loadData(text.toLowerCase());
            }}
            onChange={(e) => {
                const patientData = patients.concat(extraOptions).find((c) => c.value === e.target.value);
                if (!patientData) return;
                onPatientSelect && onPatientSelect(patientData);
            }}
            inputProps={{
                endAdornment: <>{loading ? <CircularProgress color="inherit" size={20} /> : null}</>,
            }}
            {...inputProps}
            label={t("search_patient")}
            TextFieldProps={{
                InputProps: {
                    sx: {
                        backgroundColor: "#ebebeb",
                        borderRadius: "10px",
                    },
                    disableUnderline: true,
                },
                variant: "filled",
            }}
        />
    );
};

export default ClientSearch;

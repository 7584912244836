import { Avatar, ButtonBase, Divider, ListItemIcon, Menu, MenuItem } from "@mui/material";
import Logout from "@mui/icons-material/Logout";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const getLoggedUserData = () => {
    const loggedUserAsString = localStorage.getItem("loggedUser");
    if (!loggedUserAsString) return {};
    const data = JSON.parse(loggedUserAsString);
    return data;
};

const ProfileMenu = () => {
    const { t } = useTranslation();
    const [anchorMenu, setAnchorMenu] = useState(null);
    const navigate = useNavigate();
    const handleProfileClick = (event) => {
        setAnchorMenu(event.currentTarget);
    };
    const handleProfileMenuClose = () => {
        setAnchorMenu(null);
    };
    const { username, _id, color } = getLoggedUserData() || {};
    return (
        <>
            <ButtonBase
                sx={{ marginLeft: "auto" }}
                onClick={handleProfileClick}
                aria-owns={anchorMenu ? "profile-menu" : null}
            >
                <Avatar sx={{ bgcolor: color, textTransform: "capitalize", borderRadius: 2 }} variant="square">
                    {username?.[0] || ""}
                </Avatar>
            </ButtonBase>
            <Menu
                sx={{ marginTop: 2 }}
                id="profile-menu"
                anchorEl={anchorMenu}
                open={Boolean(anchorMenu)}
                onClose={handleProfileMenuClose}
                PaperProps={{ variant: "elevation", elevation: 2 }}
            >
                <MenuItem disableRipple>{username}</MenuItem>
                <MenuItem onClick={() => navigate(`/accounts/${_id?.split("users_")?.[1]}`)}>{t("profili")}</MenuItem>
                <Divider />
                <MenuItem onClick={() => navigate("/login")}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    {t("logout")}
                </MenuItem>
            </Menu>
        </>
    );
};

export default ProfileMenu;

import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator,
} from "@mui/lab";
import {
    Box,
    Button,
    Card,
    CardContent,
    Divider,
    ListItemText,
    Paper,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
    Toolbar,
    Tooltip,
    useMediaQuery,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import EventIcon from "@mui/icons-material/Event";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import { useTranslation } from "react-i18next";
import { PatientDataContext } from "./PatientDataContext";
import { DateTime } from "luxon";
import { Outlet, useNavigate } from "react-router-dom";
import { useApi } from "../../../components/hooks";

const PatientAppointmentsPanel = () => {
    const [filter, setFilter] = useState("upcoming");
    const isLargeScreen = useMediaQuery("(min-width:800px)");
    const { t } = useTranslation();
    const { patientData, setPatientData } = useContext(PatientDataContext);
    const navigate = useNavigate();
    const { fetch } = useApi();

    useEffect(() => {
        if (!patientData?.appointments) {
            loadPatientAppointments();
        }
    }, []);

    const loadPatientAppointments = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "patientAppointments",
            data: {
                patient_id: patientData._id,
            },
            responseData: "_id date start_time end_time user_id username name note",
        });
        setPatientData({ ...patientData, appointments: response?.patientAppointments || [] });
    };

    const addAppointment = (appointment) => {
        if (!patientData?.appointments || patientData.appointments.length === 0) {
            setPatientData({ ...patientData, appointments: [appointment] });
            return;
        }
        setPatientData({ ...patientData, appointments: [...patientData.appointments, appointment] });
        setFilter("all");
    };

    return (
        <Paper sx={{ paddingTop: 2, borderRadius: 3 }}>
            {!isLargeScreen && (
                <Button
                    sx={{ marginLeft: 3, marginBottom: 1 }}
                    variant="contained"
                    onClick={() => navigate("createAppointment/create")}
                >
                    {t("add_appointment")}
                </Button>
            )}
            <Toolbar>
                <ToggleButtonGroup
                    value={filter}
                    onChange={(e, newFilter) => setFilter(newFilter)}
                    color="primary"
                    exclusive
                    aria-label="Platform"
                >
                    <ToggleButton value="upcoming">{t("upcoming_appointments")}</ToggleButton>
                    <ToggleButton value="past">{t("past_appointments")}</ToggleButton>
                    <ToggleButton value="all">{t("show_all")}</ToggleButton>
                </ToggleButtonGroup>
                {isLargeScreen && (
                    <Button
                        sx={{ marginLeft: "auto" }}
                        variant="contained"
                        onClick={() => navigate("createAppointment/create")}
                    >
                        {t("add_appointment")}
                    </Button>
                )}
            </Toolbar>
            <Timeline sx={{ marginX: 2, backgroundColor: "#e6e6e6", borderRadius: 3 }}>
                {patientData?.appointments
                    ?.filter((appointment) => {
                        if (filter === "all") return true;
                        const appointmentTime = DateTime.fromISO(appointment.date + "T" + appointment.end_time + ":00");
                        const currentTime = DateTime.now();
                        if (filter === "upcoming") return appointmentTime > currentTime;
                        return currentTime >= appointmentTime;
                    })
                    ?.sort((appointment, nextAppointment) => {
                        const firstDate = DateTime.fromISO(appointment.date + "T" + appointment.start_time + ":00");
                        const secondDate = DateTime.fromISO(
                            nextAppointment.date + "T" + nextAppointment.start_time + ":00"
                        );

                        if (firstDate <= secondDate) return -1;
                        return 1;
                    })
                    ?.map((appointment) => {
                        return <AppointmentItem appointment={appointment} />;
                    })}
            </Timeline>
            <Outlet context={{ patientId: patientData?._id, onSaveEffect: addAppointment }} />
        </Paper>
    );
};

const AppointmentItem = ({ appointment = {} }) => {
    const { t } = useTranslation();
    const isLargeScreen = useMediaQuery("(min-width:800px)");
    return (
        <TimelineItem>
            <TimelineOppositeContent
                sx={{ m: "auto 0", flexGrow: 0.2 }}
                align="right"
                variant="body2"
                color="text.secondary"
            >
                <ListItemText
                    primary={!!appointment?.date ? DateTime.fromISO(appointment.date).toFormat("LLL dd") : ""}
                    secondary={`${appointment?.start_time || ""} - ${appointment?.end_time}`}
                    primaryTypographyProps={{ color: "black" }}
                    secondaryTypographyProps={{ color: "black" }}
                />
            </TimelineOppositeContent>
            <TimelineSeparator>
                <TimelineConnector sx={{ backgroundColor: "primary.main" }} />
                <TimelineDot color="primary" variant="outlined">
                    <EventIcon />
                </TimelineDot>
                <TimelineConnector sx={{ backgroundColor: "primary.main" }} />
            </TimelineSeparator>
            <TimelineContent>
                <Card sx={{ borderRadius: 3 }}>
                    <CardContent>
                        <Stack
                            justifyContent="space-between"
                            direction={isLargeScreen ? "row" : "column"}
                            divider={<Divider orientation="vertical" flexItem />}
                            alignItems="center"
                        >
                            <ListItemText
                                primary={t("description")}
                                secondary={appointment?.name}
                                primaryTypographyProps={{ variant: "body", color: "grey" }}
                                secondaryTypographyProps={{ variant: "body", color: "black" }}
                                sx={{ textAlign: "center", width: "40%" }}
                            />
                            <ListItemText
                                sx={{ textAlign: "center", width: "40%" }}
                                primary={t("doctor")}
                                secondary={appointment?.username}
                                primaryTypographyProps={{ variant: "body", color: "grey" }}
                                secondaryTypographyProps={{ variant: "body", color: "black" }}
                            />
                            <Box sx={{ paddingX: 2, width: isLargeScreen ? "20%" : "100%", textAlign: "center" }}>
                                <Tooltip slotProps={{ tooltip: { sx: { fontSize: 16 } } }} title={appointment?.note}>
                                    <ContentPasteIcon color="primary" />
                                </Tooltip>
                            </Box>
                        </Stack>
                    </CardContent>
                </Card>
            </TimelineContent>
        </TimelineItem>
    );
};

export default PatientAppointmentsPanel;

import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    IconButton,
    InputAdornment,
    LinearProgress,
    useMediaQuery,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Form, InputControl } from "../../components/form";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useApi } from "../../components/hooks";
import { useSnackbar } from "notistack";

const rules = {
    username: "required",
    password: "required",
};

const LoginPage = () => {
    const isLargeScreen = useMediaQuery("(min-width:700px)");
    const [values, setValues] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        localStorage.removeItem("token");
        localStorage.removeItem("loggedUser");
    });

    const loginHandler = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "login",
            data: { username: values.username, password: values.password },
            responseData:
                "currency token user{_id name username role_id role_name color role_permissions{module rights}}",
        });
        if (response?.login?.token) {
            enqueueSnackbar(t("logged_in"), { variant: "success" });
            localStorage.setItem("token", response.login.token);
            localStorage.setItem("currency", response.login.currency);
            localStorage.setItem("loggedUser", JSON.stringify(response.login.user));
            navigate("/");
        }
    };
    return (
        <div
            style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundImage: "url(http://cdn.shopify.com/s/files/1/0551/2613/0875/articles/ai.jpg?v=1615716725)",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
            }}
        >
            <Form values={values} onValuesChange={setValues} onSubmit={loginHandler} rules={rules}>
                <Card
                    sx={{
                        width: isLargeScreen ? 500 : "95vw",
                        paddingBottom: 2,
                    }}
                    elevation={0}
                    variant="elevation"
                >
                    <CardHeader
                        title={t("login")}
                        titleTypographyProps={{
                            color: "secondary",
                            textAlign: "center",
                            variant: "h5",
                            textTransform: "capitalize",
                        }}
                    />
                    {loading && <LinearProgress color="secondary" />}
                    <CardContent>
                        <InputControl name="username" size="big" label={t("username")} />
                        <InputControl
                            name="password"
                            size="big"
                            type={showPassword ? "text" : "password"}
                            label={t("password")}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword(!showPassword)}
                                        >
                                            {showPassword ? (
                                                <VisibilityOff color="secondary" />
                                            ) : (
                                                <Visibility color="secondary" />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </CardContent>
                    <CardActions sx={{ paddingX: 2 }}>
                        <Button type="submit" size="large" variant="contained" color="secondary" fullWidth>
                            {t("login")}
                        </Button>
                    </CardActions>
                </Card>
            </Form>
        </div>
    );
};

export default LoginPage;

import { Box, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Appbar from "./Appbar";
import Sidebar from "./Sidebar";

const Layout = () => {
    const [open, setOpen] = useState(false);
    const isLargeScreen = useMediaQuery("(min-width:600px)");
    return (
        <>
            <Appbar toggleOpen={() => setOpen(!open)} />
            <Sidebar open={open} toggleOpen={() => setOpen(!open)} />
            <Box sx={{ marginLeft: isLargeScreen ? "60px" : "0px", marginTop: "60px", padding: "20px" }}>
                <Outlet />
            </Box>
        </>
    );
};

export default Layout;

import { AppBar, Badge, Button, IconButton, Toolbar, useMediaQuery } from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import React from "react";
import { ProfileMenu } from "../common";
import { ClientSearch } from "../common";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Appbar = ({ toggleOpen }) => {
    const navigate = useNavigate();
    const isLargeScreen = useMediaQuery("(min-width:600px)");
    const { t } = useTranslation();
    return (
        <AppBar
            // position="fixed"
            elevation={0}
            variant="elevation"
            sx={{
                backgroundColor: "background.default",
                // backdropFilter: "blur(6px)",
                // WebkitBackdropFilter: "blur(6px)",
            }}
        >
            <Toolbar style={{ paddingLeft: isLargeScreen ? "80px" : "20px", paddingRight: "20px" }}>
                {!isLargeScreen && (
                    <IconButton onClick={() => toggleOpen && toggleOpen()}>
                        <MenuOpenIcon color="secondary" />
                    </IconButton>
                )}
                <ClientSearch
                    onPatientSelect={({ value }) => {
                        if (value) navigate(`/patients/view/${value?.split("patients_")[1]}`);
                    }}
                    sx={{ minWidth: "300px" }}
                    fullWidth={false}
                />
                {isLargeScreen && (
                    <>
                        <Button
                            onClick={() => navigate("/patients/create")}
                            sx={{ marginLeft: 2, marginTop: "3px", height: "46px" }}
                            color="terciary"
                            variant="contained"
                        >
                            <PersonAddIcon />
                        </Button>
                        {/* <Badge sx={{ marginTop: "4px", marginLeft: "auto", marginRight: 2 }} badgeContent={4} color="error">
                    <Button color="primaryLight" variant="contained">
                        <NotificationsIcon />
                    </Button>
                </Badge> */}
                        <ProfileMenu />
                    </>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default Appbar;

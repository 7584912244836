import axios from "axios";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { getQueryBody } from "./queryGraphQL";

// const API_URL = "http://localhost:4000";
const API_URL = "http://3.123.129.252:4000/";

/**
 *
 * @param {Object} options
 * @param {string} options.operation - operation name: query | mutation
 * @param {string} options.endpoint - endpoint: Ex createUser
 * @param {Object} options.data - endpoint arguments
 * @param {string} options.responseData - response fields: Ex "_id name description ..."
 * @param {Array=} options.multipleEndpoints - case for multiple endpoint queries or mutations
 */

const useApi = () => {
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { t } = useTranslation();
    const handleErrorResponse = (error) => {
        const {
            code, // to check for no internet connection
            response, // error thrown by server with status different than 200
            message, // error message when server responds with status 200 with error inside response
        } = error;
        if (response?.status === 401) {
            navigate("/login");
            return undefined;
        }
        if (code === "ERR_NETWORK") {
            enqueueSnackbar(t("server_connection_lost"), { variant: "warning" });
            return undefined;
        }
        if (response && response.data.errors) {
            const errorMessage = response.data.errors[0].message;
            enqueueSnackbar(t(errorMessage), { variant: "error" });
            return undefined;
        }
        enqueueSnackbar(t(message), { variant: "error" });
        return undefined;
    };

    const fetch = async (options = {}) => {
        setLoading(true);
        try {
            const token = localStorage.getItem("token");
            if (!token && pathname !== "/login") {
                navigate("/login");
                return;
            }
            const apiResponse = await axios.post(API_URL, getQueryBody(options), {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            });
            setLoading(false);
            const { data = {}, errors } = apiResponse.data;
            if (errors && errors.length > 0) throw new Error(errors[0].message);
            return data;
        } catch (error) {
            setLoading(false);
            return handleErrorResponse(error);
        }
    };

    return { loading, fetch };
};

export default useApi;

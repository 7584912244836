import { Breadcrumbs, Button, Chip, Toolbar, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import KeyIcon from "@mui/icons-material/Key";
import { Link } from "../../components/common";
import { Outlet, useNavigate } from "react-router-dom";
import Table from "../../components/table/Table";
import { useApi } from "../../components/hooks";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

const AccountLists = () => {
    const { t } = useTranslation();
    const isLargeScreen = useMediaQuery("(min-width:800px)");
    const columns = [
        {
            field: "name",
            headerName: t("name"),
            flex: isLargeScreen && 1,
        },
        {
            field: "title",
            headerName: t("title"),
            flex: isLargeScreen && 1,
        },
        {
            field: "email",
            headerName: t("email"),
            flex: isLargeScreen && 1,
        },
        {
            field: "color",
            headerName: t("color"),
            renderCell: ({ value }) => <Chip sx={{ backgroundColor: value }} />,
            flex: isLargeScreen && 1,
        },
        {
            field: "username",
            headerName: t("username"),
            flex: isLargeScreen && 1,
        },
        {
            field: "role_name",
            headerName: t("role"),
            flex: isLargeScreen && 1,
        },
    ];
    const [users, setUsers] = useState([]);
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "users",
            responseData: "_id name title username color email role_name",
        });
        if (response?.users) setUsers(response.users?.filter((user) => user.username !== "sysadmin"));
    };

    const onDeleteHandler = async (rows = []) => {
        if (rows.length === 0) return;
        const data = {};
        if (rows.length > 1) data._ids = rows;
        else data._id = rows[0];
        const response = await fetch({
            operation: "mutation",
            endpoint: rows.length > 1 ? "deleteUsers" : "deleteUser",
            data,
            responseData: "_id",
        });
        if (response?.deleteUsers || response?.deleteUser?._id) {
            setUsers(users.filter((user) => !rows.some((row) => row === user._id)));
            enqueueSnackbar(t("deleted_with_success"), { variant: "info" });
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    const addUser = (userData) => {
        if (users?.some((u) => u._id === userData._id)) {
            setUsers(
                users?.map((u) => {
                    if (u._id === userData?._id) return userData;
                    return u;
                })
            );
            return;
        }
        setUsers([...users, userData]);
    };

    return (
        <div>
            <Toolbar>
                <Button onClick={() => navigate("roles")} startIcon={<KeyIcon />} variant="contained" color="primary">
                    {t("roles")}
                </Button>
                <Breadcrumbs sx={{ marginLeft: "auto" }}>
                    <Link to="/">{t("home")}</Link>
                    <Link to="/accounts">{t("accounts")}</Link>
                </Breadcrumbs>
            </Toolbar>
            <Table
                rows={users}
                columns={columns}
                onRowClick={(row) => navigate(`${row?._id?.split("users_")[1]}`)}
                onCreate={() => navigate("create")}
                onRowsDelete={onDeleteHandler}
                titleLabel={t("users")}
                loading={loading}
            />
            <Outlet context={{ addUser }} />
        </div>
    );
};

export default AccountLists;

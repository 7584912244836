import { CardContent, CardHeader, Card, Typography, Skeleton } from "@mui/material";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AutoCompleteControl } from "../../../components/form";
import EventIcon from "@mui/icons-material/Event";

const TotalAppointmentsCard = ({ appointments = [], doctorOptions = [], loading }) => {
    const [selectedDoctor, setSelectedDoctor] = useState(null);
    const { t } = useTranslation();
    const total = useMemo(() => {
        if (!!selectedDoctor)
            return appointments?.filter((appointment) => appointment.user_id === selectedDoctor)?.length;
        return appointments?.length;
    }, [appointments, selectedDoctor]);

    if (loading) return <CardTemplate t={t} />;

    return (
        <Card sx={{ height: "100%" }}>
            <CardHeader
                avatar={<EventIcon />}
                title={t("appointments_today")}
                titleTypographyProps={{ variant: "h6", fontWeight: 600, textTransform: "capitalize" }}
            />
            <CardContent>
                <AutoCompleteControl
                    value={selectedDoctor}
                    onChange={(e) => setSelectedDoctor(e.target.value)}
                    options={doctorOptions}
                    label={t("filter_by_doctor")}
                />
                <Typography color="primary" marginTop={1}>
                    {t("appointments")}: {total}
                </Typography>
            </CardContent>
        </Card>
    );
};

const CardTemplate = ({ t }) => {
    return (
        <Card sx={{ height: "100%" }} variant="elevation">
            <CardHeader
                avatar={<EventIcon />}
                title={t("appointments_today")}
                titleTypographyProps={{ variant: "h6", fontWeight: 600, textTransform: "capitalize" }}
            />
            <CardContent>
                <Skeleton height={60} />
                <Skeleton height={30} />
            </CardContent>
        </Card>
    );
};

export default TotalAppointmentsCard;

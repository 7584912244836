import { Box, Button, Divider, Stack, useMediaQuery } from "@mui/material";
import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import PaymentsIcon from "@mui/icons-material/Payments";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import EuroIcon from "@mui/icons-material/Euro";
import { useTranslation } from "react-i18next";

const FinanceView = () => {
    const { t } = useTranslation();
    const isLargeScreen = useMediaQuery("(min-width:1000px)");
    const financeRoutes = [
        {
            icon: <EuroIcon />,
            label: "invoices",
            href: "/invoices",
        },
        {
            icon: <PaymentsIcon />,
            label: "payments",
            href: "/invoices/payments",
        },
        {
            icon: <PendingActionsIcon />,
            label: "estimates",
            href: "/invoices/estimates",
        },
    ];

    const navigate = useNavigate();
    const { pathname } = useLocation();
    return (
        <Box flexDirection={isLargeScreen ? "row" : "column"} display="flex" gap={4}>
            <Box width={isLargeScreen ? 200 : "100%"} minWidth={200}>
                <Stack spacing={2} direction={isLargeScreen ? "column" : "row"}>
                    {financeRoutes.map((route) => (
                        <Button
                            startIcon={route.icon}
                            variant={pathname === route.href ? "contained" : "text"}
                            color="primary"
                            onClick={() => navigate(route.href)}
                            sx={{ justifyContent: "start" }}
                        >
                            {t(route.label)}
                        </Button>
                    ))}
                </Stack>
            </Box>
            {isLargeScreen && (
                <Box>
                    <Divider sx={{ height: "100vh", position: "fixed", top: 0 }} orientation="vertical" />
                </Box>
            )}
            <Box minWidth={isLargeScreen && 600} flexGrow={1}>
                <Outlet />
            </Box>
        </Box>
    );
};

export default FinanceView;

import React from "react";
import { useParams } from "react-router-dom";
import NoAccessBackdrop from "./NoAccessBackdrop";

const ProtectedRoute = ({ children, permissions = {}, allowMe = false }) => {
    const params = useParams();
    const verifyPermissions = () => {
        const loggedUserData = localStorage.getItem("loggedUser");
        const { role_permissions = [], _id } = !!loggedUserData ? JSON.parse(loggedUserData) : {};
        let isValid = true;
        if (allowMe) {
            if ("users_" + params.id === _id) return true;
        }
        Object.entries(permissions).forEach(([module, rights]) => {
            const userModulePermisssions = role_permissions.find((m) => m.module === module);
            if (!userModulePermisssions) isValid = false;
            else {
                if (typeof rights === "string") {
                    if (!userModulePermisssions?.rights?.includes(rights)) isValid = false;
                } else if (Array.isArray(rights)) {
                    if (!rights.every((right) => userModulePermisssions?.rights?.includes(right))) isValid = false;
                } else {
                    throw new Error("invalid_required_permissions");
                }
            }
        });
        return isValid;
    };

    if (verifyPermissions()) return children;

    return (
        <>
            <NoAccessBackdrop />
            {children}
        </>
    );
};

export default ProtectedRoute;

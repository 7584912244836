import { Button, ButtonGroup, Card, CardContent, CardHeader } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import EventIcon from "@mui/icons-material/Event";
import ReceiptIcon from "@mui/icons-material/Receipt";
import AddCardIcon from "@mui/icons-material/AddCard";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useNavigate } from "react-router-dom";

const QuickActionsCard = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const loggedUser = localStorage.getItem("loggedUser");
    const userData = !!loggedUser ? JSON.parse(loggedUser) : {};
    return (
        <Card sx={{ height: "100%" }} variant="elevation">
            <CardHeader
                titleTypographyProps={{ textTransform: "capitalize", variant: "h6", fontWeight: 600 }}
                title={`${t("welcome")} ${userData?.name || ""}`}
            />
            <CardContent>
                <ButtonGroup fullWidth>
                    <Button onClick={() => navigate("appointments-calendar")} startIcon={<EventIcon />}>
                        {t("create_appointment")}
                    </Button>
                    <Button onClick={() => navigate("invoices/estimates/create")} startIcon={<ReceiptIcon />}>
                        {t("create_estimate")}
                    </Button>
                </ButtonGroup>
                <ButtonGroup sx={{ marginTop: 2 }} fullWidth>
                    <Button onClick={() => navigate("invoices/payments/create")} startIcon={<AddCardIcon />}>
                        {t("add_payment")}
                    </Button>
                    <Button onClick={() => navigate("patients/create")} startIcon={<PersonAddIcon />}>
                        {t("register_patient")}
                    </Button>
                </ButtonGroup>
            </CardContent>
        </Card>
    );
};

export default QuickActionsCard;

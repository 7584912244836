import React, { useMemo } from "react";
import { SimpleTable } from "../../../components/table";
import { toCurrency } from "../../../utils/toCurrency";

const DoctorTreatmentsTable = ({ data = [] }) => {
    const tableData = useMemo(() => {
        return data?.map((doctor) => {
            return {
                doctor: doctor.name,
                treatments_number: doctor.number,
                treatments_total: toCurrency(doctor.amount?.toFixed(2)),
                treatments_payments: toCurrency(doctor.paid?.toFixed(2)),
            };
        });
    }, [data]);
    return (
        <SimpleTable
            variant="outlined"
            color="primary"
            columns={[
                { header: "doctor" },
                { header: "treatments_number" },
                { header: "treatments_total" },
                { header: "treatments_payments" },
            ]}
            data={tableData || []}
        />
    );
};

export default DoctorTreatmentsTable;

export const teethOptions = [
    { value: "t11", label: "t11" },
    { value: "t12", label: "t12" },
    { value: "t13", label: "t13" },
    { value: "t14", label: "t14" },
    { value: "t15", label: "t15" },
    { value: "t16", label: "t16" },
    { value: "t17", label: "t17" },
    { value: "t18", label: "t18" },
    { value: "t21", label: "t21" },
    { value: "t22", label: "t22" },
    { value: "t23", label: "t23" },
    { value: "t24", label: "t24" },
    { value: "t25", label: "t25" },
    { value: "t26", label: "t26" },
    { value: "t27", label: "t27" },
    { value: "t28", label: "t28" },
    { value: "t31", label: "t31" },
    { value: "t32", label: "t32" },
    { value: "t33", label: "t33" },
    { value: "t34", label: "t34" },
    { value: "t35", label: "t35" },
    { value: "t36", label: "t36" },
    { value: "t37", label: "t37" },
    { value: "t38", label: "t38" },
    { value: "t41", label: "t41" },
    { value: "t42", label: "t42" },
    { value: "t43", label: "t43" },
    { value: "t44", label: "t44" },
    { value: "t45", label: "t45" },
    { value: "t46", label: "t46" },
    { value: "t47", label: "t47" },
    { value: "t48", label: "t48" },
];

import { Button, Chip } from "@mui/material";
import React, { useContext, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { SimpleTable } from "../../../components/table";
import { toCurrency } from "../../../utils/toCurrency";
import { PatientDataContext } from "./PatientDataContext";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import EmailIcon from "@mui/icons-material/Email";
import { DeleteButtonWithConfirm } from "../../../components/common";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { useApi } from "../../../components/hooks";

const EstimatesPanel = () => {
    const { patientData, setPatientData } = useContext(PatientDataContext);
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const { fetch } = useApi();

    useEffect(() => {
        if (!patientData?.estimates) {
            console.log(1);
            loadPatientEstimates();
        }
    }, []);

    const loadPatientEstimates = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "patientEstimates",
            data: {
                patient_id: patientData._id,
            },
            responseData: "_id code created_at total username",
        });
        setPatientData({ ...patientData, estimates: response?.patientEstimates || [] });
    };

    const deleteEstimate = async (_id) => {
        const response = await fetch({
            operation: "mutation",
            endpoint: "deleteEstimate",
            data: { _id },
            responseData: "_id",
        });
        if (response?.deleteEstimate?._id) {
            setPatientData({
                ...patientData,
                estimates: patientData.estimates?.filter((e) => e._id !== _id),
            });
            enqueueSnackbar(t("deleted_with_success"), { variant: "info" });
        }
    };

    const estimatesData = useMemo(() => {
        return patientData?.estimates?.map((estimate) => {
            return {
                created_at: DateTime.fromISO(estimate.created_at).toFormat("yyyy LLL dd HH:mm"),
                code: estimate.code,
                username: estimate.username,
                amount: (
                    <Chip
                        color="primaryLight"
                        size="small"
                        label={toCurrency(!!estimate.total ? estimate.total.toFixed(2) : "")}
                    />
                ),
                extra: (
                    <>
                        <Button
                            variant="contained"
                            onClick={() => navigate(`/invoices/estimates/${estimate._id.split("estimates_")?.[1]}`)}
                            sx={{ marginX: 1 }}
                            color="secondaryLight"
                        >
                            <BorderColorIcon />
                        </Button>
                        <DeleteButtonWithConfirm onClick={() => deleteEstimate(estimate._id)} color="secondaryLight" />
                    </>
                ),
            };
        });
    }, [patientData?.estimates]);
    return (
        <div>
            <SimpleTable
                color="secondary"
                columns={[
                    { header: t("created_at") },
                    { header: t("code") },
                    { header: t("doctor") },
                    { header: t("total") },
                    { header: "" },
                ]}
                data={estimatesData || []}
            />
        </div>
    );
};

export default EstimatesPanel;

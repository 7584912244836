import { FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup } from "@mui/material";
import React from "react";

const RadioGroupControl = ({
    options = [],
    label,
    margin = "dense",
    size = "medium",
    row = true,
    labelPlacement = "end",
    color,
    error,
    helperText,
    ...rest
}) => {
    return (
        <FormControl margin={margin} error={error}>
            <FormLabel>{label}</FormLabel>
            <RadioGroup {...rest} row={row}>
                {options.map((option) => (
                    <FormControlLabel
                        control={<Radio size={size} color={color} />}
                        value={option.value}
                        label={option.label}
                        labelPlacement={labelPlacement}
                    />
                ))}
            </RadioGroup>
            <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
    );
};

export default RadioGroupControl;

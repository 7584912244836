import { Card, CardContent, CardHeader } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { BarChart, ResponsiveContainer, CartesianGrid, XAxis, YAxis, Tooltip, Bar } from "recharts";

const DoctorTreatmentsChart = ({ data = [] }) => {
    const { t } = useTranslation();
    return (
        <Card sx={{ borderRadius: 3 }}>
            <CardHeader title={t("treatments_by_doctor")} titleTypographyProps={{ variant: "h6" }} />
            <CardContent>
                <ResponsiveContainer width={"100%"} aspect={3}>
                    <BarChart data={data} barCategoryGap={"40%"}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis unit=" ALL" />
                        <Tooltip />
                        <Bar maxBarSize={30} dataKey={"amount"} unit=" ALL" fill="#00A4E8" />
                        <Bar maxBarSize={30} dataKey={"paid"} unit=" ALL" fill="#00A4E8" />
                    </BarChart>
                </ResponsiveContainer>
            </CardContent>
        </Card>
    );
};

export default DoctorTreatmentsChart;

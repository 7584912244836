import { Chip } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";
import { useApi } from "../../components/hooks";
import Table from "../../components/table/Table";

const DrugList = () => {
    const { t } = useTranslation();
    const columns = [
        {
            field: "name",
            headerName: t("name"),
            flex: 1,
        },
        {
            field: "amount_variants",
            headerName: t("variants"),
            flex: 1,
            renderCell: ({ value }) =>
                value?.map((variant) => <Chip sx={{ marginRight: 1 }} label={`${variant.amount}${variant.unit}`} />),
        },
    ];
    const [drugs, setDrugs] = useState([]);
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "drugs",
            responseData: "_id name amount_variants{amount unit}",
        });
        if (response?.drugs) {
            setDrugs(response.drugs);
        }
    };

    const onDeleteHandler = async (rows = []) => {
        if (rows.length === 0) return;
        const data = {};
        if (rows.length > 1) data._ids = rows;
        else data._id = rows[0];
        const response = await fetch({
            operation: "mutation",
            endpoint: rows.length > 1 ? "deleteDrugs" : "deleteDrug",
            data,
            responseData: "_id",
        });
        if (response?.deleteDrugs || response?.deleteDrug?._id) {
            setDrugs(drugs.filter((drug) => !rows.some((row) => row === drug._id)));
            enqueueSnackbar(t("deleted_with_success"), { variant: "info" });
        }
    };

    useEffect(() => {
        loadData();
    }, []);
    const addDrug = (drugData) => {
        if (drugs?.some((d) => d._id === drugData._id)) {
            setDrugs(
                drugs?.map((d) => {
                    if (d._id === drugData?._id) return drugData;
                    return d;
                })
            );
            return;
        }
        setDrugs([...drugs, drugData]);
    };
    return (
        <>
            <Table
                loading={loading}
                rows={drugs}
                columns={columns}
                onRowClick={(row) => navigate(`${row?._id?.split("drugs_")[1]}`)}
                onCreate={() => navigate("create")}
                onRowsDelete={onDeleteHandler}
                titleLabel={t("drugs")}
            />
            <Outlet context={{ addDrug }} />
        </>
    );
};

export default DrugList;

import React from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "./components/layout";
import { Home } from "./views/dashboard";
import { AccountLists, AccountForm } from "./views/accounts";
import { AnamneseForm, AnamneseList } from "./views/anamneses";
import { CurrencyForm, CurrencyList } from "./views/currencies";
import { DiagnoseForm, DiagnoseList } from "./views/diagnoses";
import { PatientList, PatientView, NewPatientForm } from "./views/patients";
import LoginPage from "./views/login/LoginPage";
import { RoleForm, RoleLists } from "./views/roles";
import { ServiceForm, ServiceList } from "./views/services";
import { SettingsForm, SettingsView } from "./views/settings";
import { FinanceView, InvoiceForm, InvoiceList } from "./views/invoices";
import { PaymentForm, PaymentList } from "./views/payments";
import { PaymentMethodForm, PaymentMethodList } from "./views/paymentmethods";
import AppointmentForm from "./views/appointments/AppointmentForm";
import { AppointmentCalendar, AppointmentList } from "./views/appointments";
import DrugForm from "./views/drugs/DrugForm";
import DrugList from "./views/drugs/DrugList";
import { EstimateForm, EstimateList } from "./views/estimates";
import { ServiceCategoryForm, ServiceCategoryList } from "./views/servicecategories";
import { ProtectedRoute } from "./components/common";
import RaportView from "./views/raports/RaportView";

const AppRoutes = () => {
    return (
        <Routes>
            <Route element={<Layout />}>
                <Route
                    path=""
                    element={
                        <ProtectedRoute permissions={{ dashboard: "view" }}>
                            <Home />
                        </ProtectedRoute>
                    }
                >
                    <Route
                        path="appointment/:appointmentId"
                        element={
                            <ProtectedRoute permissions={{ appointments: "view" }}>
                                <AppointmentForm />
                            </ProtectedRoute>
                        }
                    />
                </Route>
                <Route path="accounts">
                    <Route
                        path=""
                        element={
                            <ProtectedRoute permissions={{ users: "view" }}>
                                <AccountLists />
                            </ProtectedRoute>
                        }
                    ></Route>
                    <Route
                        path=":id"
                        element={
                            <ProtectedRoute permissions={{ users: "view" }} allowMe>
                                <AccountForm />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="roles"
                        element={
                            <ProtectedRoute permissions={{ roles: "view" }}>
                                <RoleLists />
                            </ProtectedRoute>
                        }
                    >
                        <Route
                            path=":id"
                            element={
                                <ProtectedRoute permissions={{ roles: "view" }}>
                                    <RoleForm />
                                </ProtectedRoute>
                            }
                        />
                    </Route>
                </Route>
                <Route path="appointments">
                    <Route
                        path=""
                        element={
                            <ProtectedRoute permissions={{ appointments: "view" }}>
                                <AppointmentCalendar />
                            </ProtectedRoute>
                        }
                    >
                        <Route
                            path=":appointmentId"
                            element={
                                <ProtectedRoute permissions={{ appointments: "view" }}>
                                    <AppointmentForm />
                                </ProtectedRoute>
                            }
                        />
                    </Route>
                </Route>
                <Route
                    path="appointments-calendar"
                    element={
                        <ProtectedRoute permissions={{ appointments: "view" }}>
                            <AppointmentCalendar />
                        </ProtectedRoute>
                    }
                >
                    <Route
                        path=":appointmentId"
                        element={
                            <ProtectedRoute permissions={{ appointments: "view" }}>
                                <AppointmentForm />
                            </ProtectedRoute>
                        }
                    />
                </Route>
                <Route path="settings" element={<SettingsView />}>
                    <Route
                        path=""
                        element={
                            <ProtectedRoute permissions={{ settings: "view" }}>
                                <SettingsForm />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="currencies"
                        element={
                            <ProtectedRoute permissions={{ currencies: "view" }}>
                                <CurrencyList />
                            </ProtectedRoute>
                        }
                    >
                        <Route
                            path=":id"
                            element={
                                <ProtectedRoute permissions={{ currencies: "view" }}>
                                    <CurrencyForm />
                                </ProtectedRoute>
                            }
                        />
                    </Route>
                    <Route
                        path="anamneses"
                        element={
                            <ProtectedRoute permissions={{ anamneses: "view" }}>
                                <AnamneseList />
                            </ProtectedRoute>
                        }
                    >
                        <Route
                            path=":id"
                            element={
                                <ProtectedRoute permissions={{ anamneses: "view" }}>
                                    <AnamneseForm />
                                </ProtectedRoute>
                            }
                        />
                    </Route>
                    <Route
                        path="diagnoses"
                        element={
                            <ProtectedRoute permissions={{ diagnoses: "view" }}>
                                <DiagnoseList />
                            </ProtectedRoute>
                        }
                    >
                        <Route
                            path=":id"
                            element={
                                <ProtectedRoute permissions={{ diagnoses: "view" }}>
                                    <DiagnoseForm />
                                </ProtectedRoute>
                            }
                        />
                    </Route>
                    <Route
                        path="services"
                        element={
                            <ProtectedRoute permissions={{ services: "view" }}>
                                <ServiceList />
                            </ProtectedRoute>
                        }
                    >
                        <Route
                            path=":id"
                            element={
                                <ProtectedRoute permissions={{ services: "view" }}>
                                    <ServiceForm />
                                </ProtectedRoute>
                            }
                        />
                    </Route>
                    <Route
                        path="drugs"
                        element={
                            <ProtectedRoute permissions={{ drugs: "view" }}>
                                <DrugList />
                            </ProtectedRoute>
                        }
                    >
                        <Route
                            path=":id"
                            element={
                                <ProtectedRoute permissions={{ drugs: "view" }}>
                                    <DrugForm />
                                </ProtectedRoute>
                            }
                        />
                    </Route>
                    <Route
                        path="paymentmethods"
                        element={
                            <ProtectedRoute permissions={{ paymentmethods: "view" }}>
                                <PaymentMethodList />
                            </ProtectedRoute>
                        }
                    >
                        <Route
                            path=":id"
                            element={
                                <ProtectedRoute permissions={{ paymentmethods: "view" }}>
                                    <PaymentMethodForm />
                                </ProtectedRoute>
                            }
                        />
                    </Route>
                    <Route
                        path="servicecategories"
                        element={
                            <ProtectedRoute permissions={{ servicecategories: "view" }}>
                                <ServiceCategoryList />
                            </ProtectedRoute>
                        }
                    >
                        <Route
                            path=":id"
                            element={
                                <ProtectedRoute permissions={{ servicecategories: "view" }}>
                                    <ServiceCategoryForm />
                                </ProtectedRoute>
                            }
                        />
                    </Route>
                </Route>
                <Route path="patients">
                    <Route
                        path=""
                        element={
                            <ProtectedRoute permissions={{ patients: "view" }}>
                                <PatientList />
                            </ProtectedRoute>
                        }
                    >
                        <Route
                            path=":id"
                            element={
                                <ProtectedRoute permissions={{ patients: "view" }}>
                                    <NewPatientForm />
                                </ProtectedRoute>
                            }
                        />
                    </Route>
                    <Route path="view/:id" element={<PatientView />}>
                        <Route path="createAppointment/:appointmentId" element={<AppointmentForm />} />
                        <Route path="createPayment/:paymentId" element={<PaymentForm />} />
                        <Route
                            path="createInvoices/:invoiceId"
                            element={
                                <ProtectedRoute permissions={{ invoices: "view" }}>
                                    <InvoiceForm />
                                </ProtectedRoute>
                            }
                        />
                    </Route>
                </Route>
                <Route path="invoices" element={<FinanceView />}>
                    <Route
                        path=""
                        element={
                            <ProtectedRoute permissions={{ invoices: "view" }}>
                                <InvoiceList />
                            </ProtectedRoute>
                        }
                    >
                        <Route
                            path=":invoiceId"
                            element={
                                <ProtectedRoute permissions={{ invoices: "view" }}>
                                    <InvoiceForm />
                                </ProtectedRoute>
                            }
                        />
                    </Route>
                    <Route
                        path="payments"
                        element={
                            <ProtectedRoute permissions={{ payments: "view" }}>
                                <PaymentList />
                            </ProtectedRoute>
                        }
                    >
                        <Route
                            path=":paymentId"
                            element={
                                <ProtectedRoute permissions={{ payments: "view" }}>
                                    <PaymentForm />
                                </ProtectedRoute>
                            }
                        />
                    </Route>

                    <Route
                        path="estimates"
                        element={
                            <ProtectedRoute permissions={{ estimates: "view" }}>
                                <EstimateList />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="estimates/:id"
                        element={
                            <ProtectedRoute permissions={{ estimates: "view" }}>
                                <EstimateForm />
                            </ProtectedRoute>
                        }
                    />
                </Route>
                <Route
                    path="raports"
                    element={
                        <ProtectedRoute permissions={{ raport: "view" }}>
                            <RaportView />
                        </ProtectedRoute>
                    }
                />
            </Route>
            <Route path="/login" element={<LoginPage />} />
        </Routes>
    );
};

export default AppRoutes;

import React from "react";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Button } from "@mui/material";
import FileControl from "./FileControl";
import { useTranslation } from "react-i18next";

const UploadComponent = ({ onFileChange, multiple = false }) => {
    const { t } = useTranslation();
    return (
        <>
            <Button
                variant="contained"
                onClick={() => {
                    const component = document.querySelector("#uploadId");
                    component.click();
                }}
                startIcon={<UploadFileIcon />}
                color="primaryLight"
            >
                {t("upload")}
            </Button>
            <input value={""} onChange={onFileChange} hidden id="uploadId" type="file" multiple />
        </>
    );
};

export default UploadComponent;

import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";
import { useApi } from "../../components/hooks";
import Table from "../../components/table/Table";

const CurrencyList = () => {
    const { t } = useTranslation();
    const columns = [
        {
            field: "name",
            headerName: t("name"),
            flex: 1,
        },
        {
            field: "code",
            headerName: t("code"),
            flex: 1,
        },
        {
            field: "rate",
            headerName: t("rate"),
            flex: 1,
        },
        {
            field: "is_active",
            headerName: t("active"),
            flex: 1,
        },
    ];
    const [currencies, setCurrencies] = useState([]);
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "currencies",
            responseData: "_id name rate code is_default is_active",
        });
        if (response?.currencies) setCurrencies(response.currencies);
    };

    const onDeleteHandler = async (rows = []) => {
        if (rows.length === 0) return;
        const data = {};
        if (rows.length > 1) data._ids = rows;
        else data._id = rows[0];
        const response = await fetch({
            operation: "mutation",
            endpoint: rows.length > 1 ? "deleteCurrencies" : "deleteCurrency",
            data,
            responseData: "_id",
        });
        if (response?.deleteCurrencies || response?.deleteCurrency?._id) {
            setCurrencies(currencies.filter((currency) => !rows.some((row) => row === currency._id)));
            enqueueSnackbar("deleted_with_success", { variant: "info" });
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    const addCurrency = (currencyData) => {
        if (currencies?.some((c) => c._id === currencyData._id)) {
            setCurrencies(
                currencies?.map((c) => {
                    if (c._id === currencyData?._id) return currencyData;
                    return c;
                })
            );
            return;
        }
        setCurrencies([...currencies, currencyData]);
    };

    return (
        <>
            <Table
                rows={currencies}
                columns={columns}
                onRowClick={(row) => navigate(`${row?._id?.split("currencies_")[1]}`)}
                onCreate={() => navigate("create")}
                onRowsDelete={onDeleteHandler}
                titleLabel={t("currencies")}
                loading={loading}
            />
            <Outlet context={{ addCurrency }} />
        </>
    );
};

export default CurrencyList;

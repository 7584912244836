import { Button, Stack, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Table } from "../../components/table";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useApi } from "../../components/hooks";
import { DateTime } from "luxon";
import { DateRangeControl } from "../../components/dates";

const InvoiceList = () => {
    const { t } = useTranslation();
    const isLargeScreen = useMediaQuery("(min-width:700px)");
    const [invoices, setInvoices] = useState([]);
    const [dates, setDates] = useState({
        startDate: null,
        endDate: null,
    });
    const columns = [
        {
            field: "created_at",
            headerName: t("created_at"),
            valueGetter: ({ value }) => DateTime.fromISO(value).toFormat("LLL dd HH:mm"),
            flex: isLargeScreen && 1,
        },
        {
            field: "username",
            headerName: t("doctor"),
            flex: isLargeScreen && 1,
        },
        {
            field: "fullName",
            headerName: t("patient"),
            valueGetter: (params) => `${params.row.patient_name || ""} ${params.row.patient_lastname || ""}`,
            flex: isLargeScreen && 1,
        },
        {
            field: "total",
            headerName: t("total"),
            flex: isLargeScreen && 1,
        },
    ];
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { loading, fetch } = useApi();

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "lastInvoices",
            data: { number: 100 },
            responseData: "_id username patient_name patient_lastname total created_at",
        });
        console.log(response);
        if (response?.lastInvoices) setInvoices(response.lastInvoices);
    };

    const loadDataByDates = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "invoices",
            data: dates,
            responseData: "_id username patient_name patient_lastname total created_at",
        });
        if (response?.invoices) setInvoices(response.invoices);
    };

    const onDeleteHandler = async (rows = []) => {
        if (rows.length === 0) return;
        const data = {};
        if (rows.length > 1) data._ids = rows;
        else data._id = rows[0];
        const response = await fetch({
            operation: "mutation",
            endpoint: rows.length > 1 ? "deleteInvoices" : "deleteInvoice",
            data,
            responseData: "_id",
        });
        if (response?.deleteInvoices || response?.deleteInvoice?._id) {
            setInvoices(invoices.filter((invoice) => !rows.some((row) => row === invoice._id)));
            enqueueSnackbar(t("deleted_with_success"), { variant: "info" });
        }
    };

    const addInvoice = (invoiceData) => {
        if (invoices?.some((i) => i._id === invoiceData._id)) {
            setInvoices(
                invoices?.map((i) => {
                    if (i._id === invoiceData?._id) return invoiceData;
                    return i;
                })
            );
            return;
        }
        setInvoices([...invoices, invoiceData]);
    };

    return (
        <div>
            <Stack direction="row" gap={2} flexWrap="wrap" alignItems={"center"} marginBottom={2}>
                <DateRangeControl
                    startLabel={t("start_date")}
                    endLabel={t("end_date")}
                    values={dates}
                    onChange={setDates}
                />
                <Button disabled={!dates.startDate || !dates.endDate} variant="contained" onClick={loadDataByDates}>
                    {t("load")}
                </Button>
                <Button variant="outlined" onClick={loadData}>
                    {t("last_100")}
                </Button>
            </Stack>
            <Table
                rows={invoices}
                columns={columns}
                onRowClick={(row) => navigate(`${row?._id?.split("invoices_")[1]}`)}
                onCreate={() => navigate("create")}
                onRowsDelete={onDeleteHandler}
                titleLabel={t("invoices")}
                loading={loading}
            />
            <Outlet context={{ addInvoice }} />
        </div>
    );
};

export default InvoiceList;

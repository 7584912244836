import { Grid, LinearProgress, useMediaQuery } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";
import { useApi } from "../../components/hooks";
import Table from "../../components/table/Table";

const PatientList = () => {
    const { t } = useTranslation();
    const isLargeScreen = useMediaQuery("(min-width:800px)");
    const columns = [
        {
            field: "firstname",
            headerName: t("name"),
            flex: isLargeScreen && 1,
        },
        {
            field: "lastname",
            headerName: t("lastname"),
            flex: isLargeScreen && 1,
        },
        {
            field: "blood_group",
            headerName: t("blood_group"),
            valueGetter: (params) => `${t(params.row.blood_group) || ""}`,
            flex: isLargeScreen && 1,
        },
        {
            field: "phone",
            headerName: t("phone"),
            flex: isLargeScreen && 1,
        },
        {
            field: "email",
            headerName: t("email"),
            flex: isLargeScreen && 1,
        },
        {
            field: "gender",
            headerName: t("gender"),
            flex: isLargeScreen && 1,
        },
    ];
    const [patients, setPatients] = useState([]);
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "lastPatients",
            data: { number: 100 },
            responseData: "_id firstname lastname blood_group phone email gender",
        });
        if (response?.lastPatients) setPatients(response.lastPatients);
    };
    const onDeleteHandler = async (rows = []) => {
        if (rows.length === 0) return;
        const data = {};
        if (rows.length > 1) data._ids = rows;
        else data._id = rows[0];
        const response = await fetch({
            operation: "mutation",
            endpoint: rows.length > 1 ? "deletePatients" : "deletePatient",
            data,
            responseData: "_id",
        });
        if (response?.deletePatients || response?.deletePatient?._id) {
            setPatients(patients.filter((patient) => !rows.some((row) => row === patient._id)));
            enqueueSnackbar(t("deleted_with_success"), { variant: "info" });
        }
    };
    useEffect(() => {
        loadData();
    }, []);

    const addPatient = (patientData) => {
        if (patients?.some((p) => p._id === patientData._id)) {
            setPatients(
                patients?.map((p) => {
                    if (p._id === patientData?._id) return patientData;
                    return p;
                })
            );
            return;
        }
        setPatients([...patients, patientData]);
    };

    return (
        <Grid container alignItems="center">
            {loading && <LinearProgress color="primary" />}
            <Table
                rows={patients}
                columns={columns}
                onRowClick={(row) => navigate("/patients/view/" + row._id.split("patients_")[1])}
                onCreate={() => navigate("create")}
                onRowsDelete={onDeleteHandler}
                titleLabel={t("patients")}
            />
            <Outlet context={{ addPatient }} />
        </Grid>
    );
};

export default PatientList;

import { createTheme } from "@mui/material";

export const theme = createTheme({
    palette: {
        primary: {
            main: "#2196F3",
        },
        primaryLight: {
            main: "#E3F2FD",
            contrastText: "#1565C0",
        },
        secondary: {
            main: "#673AB7",
        },
        secondaryLight: {
            main: "#EDE7F6",
            contrastText: "#4527A0",
        },
        terciary: {
            main: "#1A1A1A",
            contrastText: "#F0F0F0",
        },
        success: {
            main: "#248A3D",
            contrastText: "#fff",
        },
        error: {
            main: "#D70015",
            contrastText: "#fff",
        },
        background: {
            default: "#f5f5f5",
        },
    },
    components: {
        MuiButton: {
            defaultProps: {
                disableElevation: true,
            },
            styleOverrides: {
                root: {
                    borderRadius: 6,
                    textTransform: "capitalize",
                },
            },
        },
        MuiToggleButtonGroup: {
            defaultProps: {
                color: "primary",
            },
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    borderRadius: 8,
                    textTransform: "capitalize",
                },
            },
        },
        MuiButtonGroup: {
            defaultProps: {
                disableElevation: true,
            },
            styleOverrides: {
                root: {
                    backgroundColor: "transparent",
                },
            },
        },
        MuiPaper: {
            defaultProps: {
                variant: "outlined",
            },
        },
        MuiTextField: {
            defaultProps: {
                InputLabelProps: {
                    sx: {
                        textTransform: "capitalize",
                    },
                },
                SelectProps: {
                    MenuProps: {
                        sx: {
                            marginTop: 1,
                        },
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: 6,
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    textTransform: "capitalize",
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    textTransform: "capitalize",
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    textTransform: "capitalize",
                },
            },
        },
        MuiSwitch: {
            defaultProps: {
                color: "primary",
            },
            styleOverrides: {
                root: {
                    padding: 8,
                },
                thumb: {
                    boxShadow: "none",
                    width: 16,
                    height: 16,
                    margin: 2,
                    color: "white",
                },
                track: {
                    borderRadius: 22 / 2,
                    backgroundColor: "grey",
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: "capitalize",
                },
            },
        },
        MuiBreadcrumbs: {
            styleOverrides: {
                root: {
                    textTransform: "capitalize",
                },
            },
        },
        MuiDialog: {
            defaultProps: {
                PaperProps: {
                    variant: "outlined",
                },
            },
            styleOverrides: {
                paper: {
                    borderRadius: 8,
                },
                paperFullScreen: {
                    borderRadius: 0,
                    borderTopRightRadius: 20,
                    borderTopLeftRadius: 20,
                    marginTop: "30px",
                    paddingBottom: "20px",
                    boxSizing: "border-box",
                },
            },
        },
        MuiCard: {
            defaultProps: {
                elevation: 0,
                variant: "elevation",
            },
            styleOverrides: {
                root: {
                    borderRadius: "15px",
                    boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.1)",
                },
            },
        },
        MuiCardActions: {
            defaultProps: {
                sx: { paddingX: 2, paddingBottom: 2 },
            },
        },
    },
});

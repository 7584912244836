import { Button, Card, CardActions, CardContent, CardHeader, Chip, Stack } from "@mui/material";
import React, { useContext, useMemo, useState } from "react";
import { AutoCompleteControl, InputControl } from "../../../components/form";
import { DateControl } from "../../../components/dates";
import SaveIcon from "@mui/icons-material/Save";
import { PatientDataContext } from "./PatientDataContext";
import { useTranslation } from "react-i18next";
import { ProtectedAccessAction } from "../../../components/common";
import { Modal } from "../../../components/modal";

const AddNewTreatmentCard = ({ onChange, teethNumber, disableTeethSelection, disableServiceSelection }) => {
    const { extraData } = useContext(PatientDataContext);
    const { t } = useTranslation();
    const teethOptions = useMemo(() => {
        return [
            { value: "full_mouth", label: t("full_mouth") },
            { value: "t11", label: "t11" },
            { value: "t12", label: "t12" },
            { value: "t13", label: "t13" },
            { value: "t14", label: "t14" },
            { value: "t15", label: "t15" },
            { value: "t16", label: "t16" },
            { value: "t17", label: "t17" },
            { value: "t18", label: "t18" },
            { value: "t21", label: "t21" },
            { value: "t22", label: "t22" },
            { value: "t23", label: "t23" },
            { value: "t24", label: "t24" },
            { value: "t25", label: "t25" },
            { value: "t26", label: "t26" },
            { value: "t27", label: "t27" },
            { value: "t28", label: "t28" },
            { value: "t31", label: "t31" },
            { value: "t32", label: "t32" },
            { value: "t33", label: "t33" },
            { value: "t34", label: "t34" },
            { value: "t35", label: "t35" },
            { value: "t36", label: "t36" },
            { value: "t37", label: "t37" },
            { value: "t38", label: "t38" },
            { value: "t41", label: "t41" },
            { value: "t42", label: "t42" },
            { value: "t43", label: "t43" },
            { value: "t44", label: "t44" },
            { value: "t45", label: "t45" },
            { value: "t46", label: "t46" },
            { value: "t47", label: "t47" },
            { value: "t48", label: "t48" },
        ];
    }, [t]);
    return (
        <Card variant="outlined" sx={{ boxShadow: "none" }}>
            <CardHeader title={t("treatment")} />
            <CardContent>
                <AutoCompleteControl
                    disabled={disableTeethSelection}
                    name="teeth"
                    options={teethOptions}
                    multiple
                    label={t("teeth")}
                />
                <DateControl name="date" label={"date"} />
                <AutoCompleteControl options={extraData?.users || []} name="user_id" label={t("doctor")} />
                <AutoCompleteControl options={extraData?.diagnoses || []} name="diagnose_id" label={t("diagnose")} />
                <AutoCompleteControl
                    disabled={disableServiceSelection}
                    options={
                        extraData?.services?.sort(
                            (a, b) => -b.service_category_name.localeCompare(a.service_category_name)
                        ) || []
                    }
                    label={t("treatment")}
                    name={"service_id"}
                    groupBy={(option) => option.service_category_name}
                    onChange={(e) => {
                        const { price, is_tooth_priced } =
                            extraData?.services?.find((s) => s.value === e.target.value) || {};
                        onChange({
                            price: Boolean(is_tooth_priced) ? price * teethNumber : price,
                            service_id: e.target.value,
                        });
                    }}
                />
                {
                    <ServiceModal
                        disableServiceSelection={disableServiceSelection}
                        services={extraData?.services}
                        teethNumber={teethNumber}
                        onChange={onChange}
                    />
                }
                <InputControl
                    disabled={disableServiceSelection}
                    isCurrencyInput
                    type="number"
                    name="price"
                    label={t("price")}
                />
                <InputControl name="note" label={t("note")} multiline minRows={3} />
            </CardContent>
            <CardActions>
                <ProtectedAccessAction permissions={{ treatments: "create" }}>
                    <Button type="submit" fullWidth color="secondary" variant="contained" startIcon={<SaveIcon />}>
                        {t("save_treatment")}
                    </Button>
                </ProtectedAccessAction>
            </CardActions>
        </Card>
    );
};

export default AddNewTreatmentCard;

const ServiceModal = ({ services = [], onChange, teethNumber, disableServiceSelection }) => {
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();
    return (
        <>
            <Button disabled={disableServiceSelection} onClick={() => setOpen(true)}>
                {t("all_treatments")}
            </Button>
            <Modal
                maxWidth="md"
                titlelabel={t("treatments")}
                open={open}
                onClose={() => setOpen(false)}
                yesText="none"
                noText="none"
            >
                <Stack
                    flexDirection="row"
                    flexWrap="wrap"
                    gap={1}
                    marginY={2}
                    sx={{ display: disableServiceSelection ? "none" : "flex" }}
                >
                    {services?.map((service) => {
                        return (
                            <Chip
                                label={service.label}
                                sx={{
                                    borderRadius: 1,
                                    backgroundColor: !!service.color ? service.color + "33" : "grey",
                                    borderLeft: "5px solid",
                                    borderLeftColor: !!service.color ? service.color : "grey",
                                    ":hover": { backgroundColor: !!service.color ? service.color + "80" : "grey" },
                                }}
                                onClick={() => {
                                    const { price, is_tooth_priced, value } = service;
                                    onChange({
                                        price: Boolean(is_tooth_priced) ? price * teethNumber : price,
                                        service_id: value,
                                    });
                                    setOpen(false);
                                }}
                            />
                        );
                    })}
                </Stack>
            </Modal>
        </>
    );
};

import { CssBaseline, ThemeProvider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { theme } from "./utils/muiTheme";
import AppRoutes from "./AppRoutes";
import { SnackbarProvider } from "notistack";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import i18n from "./i18n";

const App = () => {
    useEffect(() => {
        setLanguage();
    }, []);

    const setLanguage = () => {
        i18n.changeLanguage("en");
    };
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <LocalizationProvider dateAdapter={AdapterLuxon}>
                <BrowserRouter>
                    <SnackbarProvider maxSnack={5} autoHideDuration={3000}>
                        <AppRoutes />
                    </SnackbarProvider>
                </BrowserRouter>
            </LocalizationProvider>
        </ThemeProvider>
    );
};

export default App;

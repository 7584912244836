import { Card, CardContent, CardHeader } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { CheckboxControl } from "../../../components/form";

const ModulePermissionsCard = ({ module, rights = [], rightsValue = [], onModulePermissionsChange }) => {
    const { t } = useTranslation();
    return (
        <Card>
            <CardHeader
                title={t(module)}
                titleTypographyProps={{ variant: "h6" }}
                action={
                    <CheckboxControl
                        value={rights.every((el) => rightsValue.includes(el))}
                        onChange={(e) => {
                            if (e.target.checked) {
                                onModulePermissionsChange([...rights]);
                                return;
                            }
                            onModulePermissionsChange([]);
                        }}
                    />
                }
            />
            <CardContent>
                {rights.map((action) => (
                    <CheckboxControl
                        value={rightsValue.includes(action)}
                        onChange={(e) => {
                            if (e.target.checked) {
                                onModulePermissionsChange([...rightsValue, action]);
                                return;
                            }
                            onModulePermissionsChange(rightsValue.filter((a) => a !== action));
                        }}
                        label={t(action)}
                    />
                ))}
            </CardContent>
        </Card>
    );
};

export default ModulePermissionsCard;

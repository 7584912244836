import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";
import { useApi } from "../../components/hooks";
import Table from "../../components/table/Table";

const PaymentMethodList = () => {
    const { t } = useTranslation();
    const columns = [
        {
            field: "name",
            headerName: t("name"),
            flex: 1,
        },
    ];
    const [paymentmethods, setPaymentmethods] = useState([]);
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "paymentmethods",
            responseData: "_id name",
        });
        if (response?.paymentmethods) setPaymentmethods(response.paymentmethods);
    };

    const onDeleteHandler = async (rows = []) => {
        if (rows.length === 0) return;
        const data = {};
        if (rows.length > 1) data._ids = rows;
        else data._id = rows[0];
        const response = await fetch({
            operation: "mutation",
            endpoint: rows.length > 1 ? "deletePaymentMethods" : "deletePaymentMethod",
            data,
            responseData: "_id",
        });
        if (response?.deletePaymentMethods || response?.deletePaymentMethod?._id) {
            setPaymentmethods(paymentmethods.filter((paymentmethod) => !rows.some((row) => row === paymentmethod._id)));
            enqueueSnackbar(t("deleted_with_success"), { variant: "info" });
        }
    };

    useEffect(() => {
        loadData();
    }, []);
    const addPaymentMethod = (paymentMethodData) => {
        if (paymentmethods?.some((p) => p._id === paymentMethodData._id)) {
            setPaymentmethods(
                paymentmethods?.map((p) => {
                    if (p._id === paymentMethodData?._id) return paymentMethodData;
                    return p;
                })
            );
            return;
        }
        setPaymentmethods([...paymentmethods, paymentMethodData]);
    };

    return (
        <>
            <Table
                rows={paymentmethods}
                columns={columns}
                onRowClick={(row) => navigate(`${row?._id?.split("paymentmethods_")[1]}`)}
                onCreate={() => navigate("create")}
                onRowsDelete={onDeleteHandler}
                titleLabel={t("paymentmethods")}
                loading={loading}
            />
            <Outlet context={{ addPaymentMethod }} />
        </>
    );
};

export default PaymentMethodList;

import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { InputControl } from "../../components/form";
import { useApi } from "../../components/hooks";
import { Modal } from "../../components/modal";

const rules = {
    name: "string|required",
};
const PaymentMethodForm = () => {
    const [values, setValues] = useState({});
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const params = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { addPaymentMethod } = useOutletContext() || {};

    useEffect(() => {
        if (params?.id !== "create") loadData();
    }, []);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "paymentmethod",
            data: {
                _id: "paymentmethods_" + params?.id,
            },
            responseData: "_id name",
        });
        if (response?.paymentmethod) setValues(response.paymentmethod);
    };
    const saveHandler = async () => {
        const { name, _id } = values;
        const data = {
            name,
        };
        if (_id) data._id = _id;
        const response = await fetch({
            operation: "mutation",
            endpoint: _id ? "updatePaymentMethod" : "createPaymentMethod",
            data,
            responseData: "_id name",
        });
        if (response?.createPaymentMethod?._id || response?.updatePaymentMethod?._id) {
            enqueueSnackbar(t("paymentmethod_saved"), { variant: "default" });
            addPaymentMethod && addPaymentMethod(_id ? response.updatePaymentMethod : response.createPaymentMethod);

            navigate(-1);
        }
    };
    return (
        <Modal
            titlelabel={t("paymentmethod_form")}
            maxWidth="xs"
            open={true}
            onClose={() => navigate(-1)}
            FormProps={{
                values,
                onValuesChange: setValues,
                onSubmit: saveHandler,
                rules,
            }}
            loading={loading}
            permissions={{ paymentmethods: values?._id ? "update" : "create" }}
        >
            <InputControl name="name" label={t("name")} />
        </Modal>
    );
};

export default PaymentMethodForm;

import { Divider, Stack } from "@mui/material";
import React from "react";
import {
    Tooth18,
    Tooth17,
    Tooth16,
    Tooth15,
    Tooth14,
    Tooth13,
    Tooth12,
    Tooth11,
    Tooth21,
    Tooth22,
    Tooth23,
    Tooth24,
    Tooth25,
    Tooth26,
    Tooth27,
    Tooth28,
    Tooth48,
    Tooth47,
    Tooth46,
    Tooth45,
    Tooth44,
    Tooth43,
    Tooth42,
    Tooth41,
    Tooth31,
    Tooth32,
    Tooth33,
    Tooth34,
    Tooth35,
    Tooth36,
    Tooth37,
    Tooth38,
} from "../../assets/tooths/components";

const ToothsChart = ({
    t18 = {},
    t17 = {},
    t16 = {},
    t15 = {},
    t14 = {},
    t13 = {},
    t12 = {},
    t11 = {},
    t21 = {},
    t22 = {},
    t23 = {},
    t24 = {},
    t25 = {},
    t26 = {},
    t27 = {},
    t28 = {},
    t48 = {},
    t47 = {},
    t46 = {},
    t45 = {},
    t44 = {},
    t43 = {},
    t42 = {},
    t41 = {},
    t31 = {},
    t32 = {},
    t33 = {},
    t34 = {},
    t35 = {},
    t36 = {},
    t37 = {},
    t38 = {},
    selected = [],
    onSelect,
}) => {
    return (
        <Stack
            display="inline-block"
            spacing={2}
            sx={{ backgroundColor: "#fafafc", padding: "20px", borderRadius: "20px", border: "1px solid #d7d7d7" }}
        >
            <Stack direction="row" spacing={1.5}>
                <Tooth18 {...t18} isSelected={selected.includes("t18")} onClick={onSelect} />
                <Tooth17 {...t17} isSelected={selected.includes("t17")} onClick={onSelect} />
                <Tooth16 {...t16} isSelected={selected.includes("t16")} onClick={onSelect} />
                <Tooth15 {...t15} isSelected={selected.includes("t15")} onClick={onSelect} />
                <Tooth14 {...t14} isSelected={selected.includes("t14")} onClick={onSelect} />
                <Tooth13 {...t13} isSelected={selected.includes("t13")} onClick={onSelect} />
                <Tooth12 {...t12} isSelected={selected.includes("t12")} onClick={onSelect} />
                <Tooth11 {...t11} isSelected={selected.includes("t11")} onClick={onSelect} />
                <div>
                    <Divider orientation="vertical" />
                </div>
                <Tooth21 {...t21} isSelected={selected.includes("t21")} onClick={onSelect} />
                <Tooth22 {...t22} isSelected={selected.includes("t22")} onClick={onSelect} />
                <Tooth23 {...t23} isSelected={selected.includes("t23")} onClick={onSelect} />
                <Tooth24 {...t24} isSelected={selected.includes("t24")} onClick={onSelect} />
                <Tooth25 {...t25} isSelected={selected.includes("t25")} onClick={onSelect} />
                <Tooth26 {...t26} isSelected={selected.includes("t26")} onClick={onSelect} />
                <Tooth27 {...t27} isSelected={selected.includes("t27")} onClick={onSelect} />
                <Tooth28 {...t28} isSelected={selected.includes("t28")} onClick={onSelect} />
            </Stack>
            <Divider />
            <Stack direction="row" spacing={1.5}>
                <Tooth48 {...t48} isSelected={selected.includes("t48")} onClick={onSelect} />
                <Tooth47 {...t47} isSelected={selected.includes("t47")} onClick={onSelect} />
                <Tooth46 {...t46} isSelected={selected.includes("t46")} onClick={onSelect} />
                <Tooth45 {...t45} isSelected={selected.includes("t45")} onClick={onSelect} />
                <Tooth44 {...t44} isSelected={selected.includes("t44")} onClick={onSelect} />
                <Tooth43 {...t43} isSelected={selected.includes("t43")} onClick={onSelect} />
                <Tooth42 {...t42} isSelected={selected.includes("t42")} onClick={onSelect} />
                <Tooth41 {...t41} isSelected={selected.includes("t41")} onClick={onSelect} />
                <div>
                    <Divider orientation="vertical" />
                </div>
                <Tooth31 {...t31} isSelected={selected.includes("t31")} onClick={onSelect} />
                <Tooth32 {...t32} isSelected={selected.includes("t32")} onClick={onSelect} />
                <Tooth33 {...t33} isSelected={selected.includes("t33")} onClick={onSelect} />
                <Tooth34 {...t34} isSelected={selected.includes("t34")} onClick={onSelect} />
                <Tooth35 {...t35} isSelected={selected.includes("t35")} onClick={onSelect} />
                <Tooth36 {...t36} isSelected={selected.includes("t36")} onClick={onSelect} />
                <Tooth37 {...t37} isSelected={selected.includes("t37")} onClick={onSelect} />
                <Tooth38 {...t38} isSelected={selected.includes("t38")} onClick={onSelect} />
            </Stack>
        </Stack>
    );
};

export default ToothsChart;

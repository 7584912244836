export const uploadToAws = async (files, api) => {
    if (!files) return undefined;
    if (!Array.isArray(files)) {
        const urlResponse = await api({
            operation: "mutation",
            endpoint: "generateAWSPresignedUrl",
            data: {
                key: files.name,
                contentType: files.type,
            },
        });
        console.log(urlResponse, files);
        const aws_response = await fetch(urlResponse?.generateAWSPresignedUrl, {
            method: "PUT",
            body: files,
        });
        if (aws_response.status === 200) {
            return "https://orbisdentalcare-files.s3.eu-central-1.amazonaws.com/" + files.name;
        } else return undefined;
    }
    const fileUrls = [];
    for (const file of files) {
        const urlResponse = await api({
            operation: "mutation",
            endpoint: "generateAWSPresignedUrl",
            data: {
                key: file.name,
                contentType: file.type,
            },
        });
        const aws_response = await fetch(urlResponse?.generateAWSPresignedUrl, {
            method: "PUT",
            body: file,
        });
        if (aws_response.status === 200) {
            fileUrls.push("https://orbisdentalcare-files.s3.eu-central-1.amazonaws.com/" + file.name);
        }
    }
    return fileUrls;
};

import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";
import { useApi } from "../../components/hooks";
import Table from "../../components/table/Table";

const ServiceCategoryList = () => {
    const { t } = useTranslation();

    const columns = [
        {
            field: "name",
            headerName: t("name"),
            flex: 1,
        },
    ];
    const [servicecategories, setServiceCategories] = useState([]);
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "servicecategories",
            responseData: "_id name",
        });
        if (response?.servicecategories) setServiceCategories(response.servicecategories);
    };

    const onDeleteHandler = async (rows = []) => {
        if (rows.length === 0) return;
        const data = {};
        if (rows.length > 1) data._ids = rows;
        else data._id = rows[0];
        const response = await fetch({
            operation: "mutation",
            endpoint: rows.length > 1 ? "deleteServiceCategories" : "deleteServiceCategory",
            data,
            responseData: "_id",
        });
        if (response?.deleteServiceCategories || response?.deleteServiceCategory?._id) {
            setServiceCategories(
                servicecategories.filter((servicecategory) => !rows.some((row) => row === servicecategory._id))
            );
            enqueueSnackbar(t("deleted_with_success"), { variant: "info" });
        }
    };

    useEffect(() => {
        loadData();
    }, []);
    const addServiceCategory = (serviceData) => {
        if (servicecategories?.some((s) => s._id === serviceData._id)) {
            setServiceCategories(
                servicecategories?.map((s) => {
                    if (s._id === serviceData?._id) return serviceData;
                    return s;
                })
            );
            return;
        }
        setServiceCategories([...servicecategories, serviceData]);
    };

    return (
        <>
            <Table
                rows={servicecategories}
                columns={columns}
                onRowClick={(row) => navigate(`${row?._id?.split("servicecategories_")[1]}`)}
                onCreate={() => navigate("create")}
                onRowsDelete={onDeleteHandler}
                titleLabel={t("service_categories")}
                loading={loading}
            />
            <Outlet context={{ addServiceCategory }} />
        </>
    );
};

export default ServiceCategoryList;

import { Button, Chip, Grid } from "@mui/material";
import React, { useContext, useEffect, useMemo } from "react";
import { SimpleTable } from "../../../components/table";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { PatientDataContext } from "./PatientDataContext";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import { toCurrency } from "../../../utils/toCurrency";
import { DeleteButtonWithConfirm } from "../../../components/common";
import { useApi } from "../../../components/hooks";
import { useSnackbar } from "notistack";

const PatientInvoicePanel = () => {
    const { t } = useTranslation();
    const { patientData, setPatientData } = useContext(PatientDataContext);
    const navigate = useNavigate();
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();

    const invoicesData = useMemo(() => {
        return patientData?.invoices?.map((invoice) => {
            return {
                created_at: DateTime.fromISO(invoice.created_at).toFormat("yyyy LLL dd HH:mm"),
                username: invoice.username,
                amount: (
                    <Chip
                        color="primaryLight"
                        size="small"
                        label={toCurrency(!!invoice.total ? invoice.total.toFixed(2) : "")}
                    />
                ),
                note: invoice.note,
                extra: (
                    <>
                        <Button
                            onClick={() => navigate(`createInvoices/${invoice._id.split("invoices_")?.[1]}`)}
                            sx={{ marginLeft: 1 }}
                            color="secondary"
                        >
                            <BorderColorIcon />
                        </Button>
                        <DeleteButtonWithConfirm onClick={() => deleteInvoice(invoice._id)} color="secondaryLight" />
                    </>
                ),
            };
        });
    }, [patientData?.invoices]);

    useEffect(() => {
        if (!patientData?.invoices) {
            loadPatientInvoices();
        }
    }, []);

    const loadPatientInvoices = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "patientInvoices",
            data: {
                patient_id: patientData._id,
            },
            responseData: "_id created_at username total table_data{treatment} note",
        });
        setPatientData({ ...patientData, invoices: response?.patientInvoices || [] });
    };

    const updateInvoice = async (invoice) => {
        setPatientData({
            ...patientData,
            invoices: patientData.invoices?.map((i) => {
                if (i._id === invoice._id) return invoice;
                return i;
            }),
        });
    };

    const deleteInvoice = async (_id) => {
        const response = await fetch({
            operation: "mutation",
            endpoint: "deleteInvoice",
            data: { _id },
            responseData: "_id",
        });
        if (response?.deleteInvoice?._id) {
            setPatientData({
                ...patientData,
                invoices: patientData.invoices?.filter((i) => i._id !== _id),
            });
            enqueueSnackbar(t("deleted_with_success"), { variant: "info" });
        }
    };

    return (
        <>
            <SimpleTable
                color="secondary"
                columns={[
                    { header: t("created_at") },
                    { header: t("doctor") },
                    { header: t("total") },
                    { header: t("note") },
                    { header: "" },
                ]}
                data={invoicesData || []}
            />

            <Outlet context={{ onSaveEffect: updateInvoice }} />
        </>
    );
};

export default PatientInvoicePanel;

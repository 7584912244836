export const rights = [
    { module: "users", rights: ["create", "update", "view", "delete", "me"] },
    { module: "roles", rights: ["create", "update", "view", "delete"] },
    {
        module: "anamneses",
        rights: ["create", "update", "view", "delete"],
    },
    {
        module: "appointments",
        rights: ["create", "update", "view", "delete", "me"],
    },
    {
        module: "currencies",
        rights: ["create", "update", "view", "delete"],
    },
    {
        module: "diagnoses",
        rights: ["create", "update", "view", "delete"],
    },
    {
        module: "drugs",
        rights: ["create", "update", "view", "delete"],
    },
    {
        module: "estimates",
        rights: ["create", "update", "view", "delete", "me"],
    },
    {
        module: "patients",
        rights: ["create", "update", "view", "delete"],
    },
    {
        module: "paymentmethods",
        rights: ["create", "update", "view", "delete"],
    },
    {
        module: "payments",
        rights: ["create", "update", "view", "delete", "me"],
    },
    {
        module: "prescriptions",
        rights: ["create", "update", "view", "delete", "me"],
    },
    {
        module: "treatments",
        rights: ["create", "update", "view", "delete", "me"],
    },
    {
        module: "invoices",
        rights: ["create", "update", "view", "delete", "me"],
    },
    {
        module: "services",
        rights: ["create", "update", "view", "delete"],
    },
    {
        module: "servicecategories",
        rights: ["create", "update", "view", "delete"],
    },
    {
        module: "documents",
        rights: ["create", "view", "delete"],
    },
    {
        module: "settings",
        rights: ["create", "update", "view"],
    },
    {
        module: "dashboard",
        rights: ["view"],
    },
    {
        module: "raport",
        rights: ["view"],
    },
];

import { Box, Divider, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Modal } from "../../components/modal";
import { InputControl, SwitchControl } from "../../components/form";
import { rights } from "../../utils/userRights";
import { useApi } from "../../components/hooks";
import { useSnackbar } from "notistack";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ModulePermissionsCard } from "./components";

const rules = {
    name: "string|required",
};
const RoleForm = () => {
    const [values, setValues] = useState({});
    const { loading, fetch } = useApi();

    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const params = useParams();
    const { t } = useTranslation();

    const { addRole } = useOutletContext() || {};

    useEffect(() => {
        if (params?.id !== "create") loadData();
    }, []);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "role",
            data: {
                _id: "roles_" + params?.id,
            },
            responseData: "_id name is_active permissions{module rights}",
        });
        if (response?.role) {
            if (!!response?.role?.permissions) {
                response.role.permissions = response.role.permissions.reduce((acc, { module, rights }) => {
                    acc[module] = rights;
                    return acc;
                }, {});
            } else response.role.permissions = {};
            setValues(response.role);
        }
    };
    const saveHandler = async () => {
        const { _id, name, is_active, permissions } = values;
        const permissionsAsArray = Object.entries(permissions).map(([module, rights]) => ({ module, rights }));
        const data = { name, is_active, permissions: permissionsAsArray };
        if (_id) data._id = _id;
        const response = await fetch({
            operation: "mutation",
            endpoint: _id ? "updateRole" : "createRole",
            data,
            responseData: "_id name is_active",
        });
        if (response?.createRole?._id || response?.updateRole?._id) {
            enqueueSnackbar(t("role_saved"), { variant: "default" });

            addRole && addRole(_id ? response.updateRole : response.createRole);

            navigate(-1);
        }
    };
    return (
        <Modal
            open
            fullScreen
            titlelabel={t("role")}
            FormProps={{ values, onValuesChange: setValues, onSubmit: saveHandler, rules }}
            onClose={() => navigate(-1)}
            loading={loading}
            permissions={{ roles: values?._id ? "update" : "create" }}
        >
            <Grid container item xs={6} spacing={2}>
                <Grid item xs={12}>
                    <InputControl name={"name"} label={t("name")} />
                </Grid>
                <Grid item xs={12}>
                    <SwitchControl name="is_active" label={t("active")} />
                </Grid>
            </Grid>
            <Box my={2}>
                <Typography variant="h6">{t("permissions")}</Typography>
                <Divider />
            </Box>
            <Grid container spacing={2}>
                {rights.map(({ module, rights }) => {
                    return (
                        <Grid item xs={12} sm={4} md={3}>
                            <ModulePermissionsCard
                                module={module}
                                rights={rights}
                                rightsValue={values.permissions?.[module]}
                                onModulePermissionsChange={(newPermissions) => {
                                    setValues({
                                        ...values,
                                        permissions: { ...values.permissions, [module]: newPermissions },
                                    });
                                }}
                            />
                        </Grid>
                    );
                })}
            </Grid>
        </Modal>
    );
};

export default RoleForm;

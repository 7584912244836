import { TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import React from "react";

const DateControl = ({
    label,
    value = null,
    onChange,
    size = "small",
    margin = "dense",
    fullWidth = true,
    variant = "outlined",
    inputFormat = "yyyy-LL-dd",
    error,
    helperText,
}) => {
    const onChangeHandler = (newValue) => {
        onChange && onChange({ target: { value: !!newValue ? newValue.toFormat(inputFormat) : null } });
    };
    return (
        <DatePicker
            inputFormat={inputFormat}
            label={label}
            value={!!value ? value : null}
            onChange={onChangeHandler}
            renderInput={(params) => (
                <TextField
                    error={error}
                    helperText={helperText}
                    variant={variant}
                    fullWidth={fullWidth}
                    margin={margin}
                    size={size}
                    {...params}
                />
            )}
            PaperProps={{ variant: "outlined", sx: { marginTop: "10px" } }}
            PopperProps={{
                sx: {
                    ".MuiPickersDay-root": { backgroundColor: "transparent", borderRadius: 0 },
                    ".MuiPickersDay-root:hover": { backgroundColor: "gray", color: "white" },
                },
            }}
            DialogProps={{
                sx: {
                    ".MuiPickersDay-root": { backgroundColor: "transparent" },
                    ".MuiPickersDay-root:hover": { backgroundColor: "gray", color: "white" },
                },
            }}
            OpenPickerButtonProps={{ color: "primary" }}
            componentsProps={{
                leftArrowButton: { color: "primary" },
                rightArrowButton: { color: "primary" },
                switchViewButton: { color: "primary" },
            }}
        />
    );
};

export default DateControl;

import { Button, Divider, Grid, Stack, ToggleButton, ToggleButtonGroup, Toolbar } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DateRangeControl } from "../../components/dates";
import { useApi } from "../../components/hooks";
import { DoctorTreatmentsChart, DoctorTreatmentsTable, TotalPaymentsCard, TotalTreatmentsCard } from "./components";

const RaportView = () => {
    const [filters, setFilters] = useState({
        user: null,
        startDate: null,
        endDate: null,
    });
    const [userOptions, setUserOptions] = useState([]);
    const [data, setData] = useState({
        treatments: [],
        payments: [],
    });
    const [activeView, setActive] = useState("chart");
    const { t } = useTranslation();
    const { loading, fetch } = useApi();

    useEffect(() => {
        loadUsers();
    }, []);

    const loadUsers = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "users",
            data: { activeOnly: true },
            responseData: "_id name username color",
        });
        if (response?.users)
            setUserOptions(
                response.users
                    ?.filter((user) => user.username !== "sysadmin")
                    ?.map(({ _id, name, color }) => ({ value: _id, label: name, color }))
            );
    };

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                {
                    endpoint: "generateTreatmentsRaport",
                    data: { startDate: filters.startDate, endDate: filters.endDate },
                    responseData:
                        "_id date service_id service_name status service_color is_clinic_service price user_id username user_color payments{amount created_at}",
                },
                {
                    endpoint: "payments",
                    data: { startDate: filters.startDate, endDate: filters.endDate },
                    responseData: "_id user_id amount",
                },
            ],
        });
        if (!!response)
            setData({ ...data, treatments: response?.generateTreatmentsRaport, payments: response?.payments });
    };

    const usersChartData = useMemo(() => {
        if (!data?.treatments || data.treatments.length === 0) return [];
        return userOptions?.map((user) => {
            let amount = 0.0;
            let paid = 0.0;
            let number = 0;
            data?.treatments
                ?.filter(
                    (treatment) =>
                        treatment.user_id === user.value && !treatment.is_clinic_service && treatment.status !== 0
                )
                ?.forEach((treatment) => {
                    amount += treatment.price;
                    treatment?.payments?.forEach((payment) => {
                        paid += payment.amount;
                    });
                    number += 1;
                });
            return {
                name: user.label,
                number,
                amount,
                paid,
                fill: user.color,
            };
        });
    }, [data.treatments]);

    const treatmentsAmountData = useMemo(() => {
        if (!data?.treatments || data.treatments.length === 0) return { number: 0, total: 0.0 };
        const activeTreatments = data?.treatments?.filter((treatment) => treatment.status !== 0);
        return {
            number: activeTreatments?.length,
            total: activeTreatments?.reduce((acc, treatment) => acc + treatment.price, 0.0),
        };
    }, [data.treatments]);

    const paymentsAmountData = useMemo(() => {
        if (!data?.payments || data.payments.length === 0) return { number: 0, total: 0.0 };
        return {
            number: data?.payments?.length,
            total: data?.payments?.reduce((acc, payment) => acc + payment.amount, 0.0),
        };
    }, [data.payments]);

    return (
        <div>
            <Toolbar>
                <Stack gap={4} direction="row" alignItems="center">
                    <DateRangeControl
                        startLabel={t("start")}
                        endLabel={t("end")}
                        values={{ startDate: filters.startDate, endDate: filters.endDate }}
                        onChange={({ startDate, endDate }) => setFilters({ ...filters, startDate, endDate })}
                    />
                    <Button
                        variant="contained"
                        onClick={() => {
                            if (!filters.startDate || !filters.endDate) return;
                            loadData();
                        }}
                    >
                        {t("load_data")}
                    </Button>
                </Stack>
            </Toolbar>
            <Grid container spacing={4} marginTop={2}>
                <Grid item xs={12} sm={6} md={4}>
                    <TotalTreatmentsCard {...treatmentsAmountData} loading={loading} />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TotalPaymentsCard {...paymentsAmountData} loading={loading} />
                </Grid>
                <Grid item xs={12}>
                    <Divider>
                        <ToggleButtonGroup
                            value={activeView}
                            onChange={(e, newView) => setActive(newView)}
                            size="small"
                            color="primary"
                            exclusive
                            aria-label="Platform"
                        >
                            <ToggleButton value="chart">{t("chart_view")}</ToggleButton>
                            <ToggleButton value="table">{t("table_view")}</ToggleButton>
                        </ToggleButtonGroup>
                    </Divider>
                </Grid>
                <Grid item xs={12}>
                    {activeView === "chart" ? (
                        <DoctorTreatmentsChart data={usersChartData} />
                    ) : (
                        <DoctorTreatmentsTable data={usersChartData} />
                    )}
                </Grid>
                {/* <Grid item xs={12}>
                    <TreatmentsChart data={treatmentsChartData} />
                </Grid> */}
            </Grid>
        </div>
    );
};

export default RaportView;

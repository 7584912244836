import React from "react";
import { SimpleTable } from "../../../components/table";

const TreatmentsTable = ({ data }) => {
    return (
        <div>
            <SimpleTable
                variant="outlined"
                color="secondary"
                columns={[
                    { header: "date" },
                    { header: "tooth" },
                    { header: "treatment" },
                    { header: "doctor" },
                    { header: "price" },
                    { header: "paid" },
                    { header: "unpaid" },
                    { header: "status" },
                    { header: "" },
                ]}
                data={data}
            />
        </div>
    );
};

export default TreatmentsTable;

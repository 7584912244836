import React from "react";

const ProtectedAccessAction = ({ children, permissions = {}, allowMe = false, userId }) => {
    const verifyPermissions = () => {
        const loggedUserData = localStorage.getItem("loggedUser");
        const { role_permissions = [], _id } = !!loggedUserData ? JSON.parse(loggedUserData) : {};
        let isValid = true;
        if (allowMe) {
            if (userId === _id) return true;
        }
        Object.entries(permissions).forEach(([module, rights]) => {
            const userModulePermisssions = role_permissions.find((m) => m.module === module);
            if (!userModulePermisssions) isValid = false;
            else {
                if (typeof rights === "string") {
                    if (!userModulePermisssions?.rights?.includes(rights)) isValid = false;
                } else if (Array.isArray(rights)) {
                    if (!rights.every((right) => userModulePermisssions?.rights?.includes(right))) isValid = false;
                }
            }
        });
        return isValid;
    };
    if (!verifyPermissions()) return null;
    return <>{children}</>;
};

export default ProtectedAccessAction;

import {
    Box,
    ButtonBase,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Chip,
    IconButton,
    Typography,
} from "@mui/material";
import VaccinesIcon from "@mui/icons-material/Vaccines";
import PrintIcon from "@mui/icons-material/Print";
import React from "react";
import { printPdf } from "../pdf/prescriptionPdf";
import { useApi } from "../hooks";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

const PrescriptionCard = ({ onClick, drugs = [], dateTime, userName, id }) => {
    const { fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const generatePdf = () => {
        if (!id) return;
        try {
            printPdf({ prescriptionId: id, fetch, t });
        } catch (err) {
            enqueueSnackbar(t("print_failed"), { variant: "warning" });
        }
    };

    return (
        <Card sx={{ borderRadius: 5, width: 300 }}>
            <ButtonBase onClick={onClick}>
                <Box sx={{ width: 300 }} textAlign="start">
                    <CardHeader
                        avatar={<VaccinesIcon color="primary" />}
                        action={
                            <IconButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    generatePdf();
                                }}
                                color="primary"
                            >
                                <PrintIcon />
                            </IconButton>
                        }
                    />
                    <CardContent>
                        {drugs?.map((drug) => (
                            <Chip sx={{ mr: 1, mb: 1 }} label={drug.name} color="primaryLight" />
                        ))}
                    </CardContent>
                    <CardContent>
                        <Typography variant="subtitle2">
                            {dateTime} by {userName}
                        </Typography>
                    </CardContent>
                </Box>
            </ButtonBase>
        </Card>
    );
};

export default PrescriptionCard;

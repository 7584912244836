import { Button, CardHeader, Divider, Grid, ListItemText, Paper, Typography, useMediaQuery } from "@mui/material";
import React, { useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { PatientDataContext } from "./PatientDataContext";
import { DateTime } from "luxon";
import { Outlet, useNavigate } from "react-router-dom";
import { toCurrency } from "../../../utils/toCurrency";
import { useApi } from "../../../components/hooks";

const PatientPaymentsPanel = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { fetch } = useApi();
    const { patientData, setPatientData } = useContext(PatientDataContext);
    const isLargeScreen = useMediaQuery("(min-width:800px)");

    useEffect(() => {
        if (!patientData?.payments) {
            loadPatientPayments();
        }
    }, []);

    const loadPatientPayments = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "patientPayments",
            data: {
                patient_id: patientData._id,
            },
            responseData: "_id paymentmethod_name amount created_at treatment_id",
        });
        setPatientData({ ...patientData, payments: response?.patientPayments || [] });
    };

    const paymentsData = useMemo(() => {
        return patientData.payments?.reduce((acc, payment) => {
            const sameTreatmentPayment = acc.find((p) => p.treatment_id === payment.treatment_id);
            if (sameTreatmentPayment) {
                sameTreatmentPayment.payments.push({
                    amount: payment.amount,
                    date: DateTime.fromISO(payment.created_at).toFormat("yyyy LLL dd"),
                });
                return acc;
            }
            const {
                service_name = "",
                date = "",
                price,
            } = patientData?.treatments?.find((treatment) => treatment._id === payment.treatment_id) || {};
            acc.push({
                treatment_id: payment.treatment_id,
                treatmentName: service_name,
                treatmentDate: date,
                treatmentAmount: price,
                payments: [
                    { amount: payment.amount, date: DateTime.fromISO(payment.created_at).toFormat("yyyy LLL dd") },
                ],
            });
            return acc;
        }, []);
    }, [patientData.payments]);

    const totalPaidAmount = useMemo(() => {
        return patientData.payments?.reduce((acc, payment) => acc + payment.amount, 0.0);
    }, [patientData.payments]);

    const addPayment = (payment) => {
        if (!patientData?.payments || patientData.payments.length === 0) {
            setPatientData({ ...patientData, payments: [payment] });
            return;
        }
        setPatientData({ ...patientData, payments: [...patientData.payments, payment] });
    };

    return (
        <Paper sx={{ paddingY: 2, paddingX: 3, borderRadius: 5 }}>
            <CardHeader
                sx={{ paddingX: 0 }}
                title={t("payments")}
                titleTypographyProps={{ fontSize: 20, fontWeight: 600, textTransform: "capitalize" }}
                action={
                    <Button variant="contained" onClick={() => navigate("createPayment/create")}>
                        {t("add_payment")}
                    </Button>
                }
            />
            {!paymentsData?.length ? (
                <Typography textAlign="center">{t("no_payments")}</Typography>
            ) : (
                <>
                    <Grid container marginTop={2} color="grey" textTransform="capitalize">
                        <Grid item xs={6} md={3}>
                            {t("treatment")}
                        </Grid>
                        <Grid item xs={6} md={3} textAlign="end">
                            {t("treatment_date")}
                        </Grid>
                        <Grid item xs={5} md={2} textAlign={isLargeScreen ? "end" : "start"}>
                            {t("treatment_cost")}
                        </Grid>
                        <Grid item xs={2} md={1}></Grid>
                        <Grid item xs={5} md={3} textAlign="end">
                            {t("amount")}
                        </Grid>
                    </Grid>
                    {paymentsData?.map(({ treatmentName, treatmentDate, treatmentAmount, payments = [] }) => {
                        const isPaid = treatmentAmount <= payments.reduce((acc, payment) => acc + payment.amount, 0);
                        return (
                            <>
                                <Grid container marginTop={2} marginBottom={1} alignItems="center">
                                    <Grid item xs={6} md={3} color={isPaid ? "success.main" : "orange"}>
                                        • {treatmentName}
                                    </Grid>
                                    <Grid item xs={6} md={3} textAlign="end">
                                        {treatmentDate}
                                    </Grid>
                                    <Grid item xs={5} md={2} textAlign={isLargeScreen ? "end" : "start"}>
                                        {toCurrency(treatmentAmount)}
                                    </Grid>
                                    <Grid item xs={2} md={1} textAlign="center">
                                        <ArrowForwardIcon color="primary" />
                                    </Grid>
                                    <Grid container item xs={5} md={3} textAlign="end">
                                        {payments.map((payment) => {
                                            return (
                                                <ListItemText
                                                    secondary={payment.date}
                                                    secondaryTypographyProps={{ fontSize: 14 }}
                                                    primaryTypographyProps={{ fontSize: 18 }}
                                                >
                                                    {toCurrency(payment.amount)}
                                                </ListItemText>
                                            );
                                        })}
                                    </Grid>
                                </Grid>
                                <Divider />
                            </>
                        );
                    })}
                    <Grid container marginTop={2} textTransform="capitalize">
                        <Grid item xs={9} color="grey">
                            {t("total")}
                        </Grid>
                        <Grid item xs={3}>
                            {toCurrency(totalPaidAmount)}
                        </Grid>
                    </Grid>
                </>
            )}
            <Outlet context={{ patientId: patientData?._id, onSaveEffect: addPayment }} />
        </Paper>
    );
};

export default PatientPaymentsPanel;
